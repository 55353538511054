import { Trip_Type_Enum } from '../custom-data-models/travel-management/generated';

export class GetFacilityTypes {
  static readonly type = '[Add Facility] GetFacilityTypes';
}

export class AddFacilityDetails {
  static readonly type = '[Add Facility] AddFacilityDetails';
  constructor(
    public payload: {
      name: string;
      is_mobile: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes: any;
    },
  ) {}
}

export class UpdateFacilityDetails {
  static readonly type = '[Add Facility] UpdateFacilityDetails';
  constructor(
    public payload: {
      id: string;
      name: string;
      is_mobile: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes: any;
    },
  ) {}
}

export class AddUpdateFacilityDetailsSuccess {
  static readonly type = '[Add Facility] AddUpdateFacilityDetailsSuccess';
  constructor(public payload: { facilityId: string }) {}
}

export class AddUpdateEventDetailsSuccess {
  static readonly type = '[Add Facility] AddUpdateEventDetailsSuccess';
  constructor(public payload: { eventId: string }) {}
}

export class AddEntityFile {
  static readonly type = '[Add Facility] AddEntityFile';
  constructor(
    public payload: {
      entity_id: string;
      entity_type: string;
      file_id: string;
      file_type: string;
    },
  ) {}
}

export class AddUpdateFacilityDetailsFailed {
  static readonly type = '[Add Facility] AddUpdateFacilityDetailsFailed';
}

export class AddUpdateEventDetailsFailed {
  static readonly type = '[Add Facility] AddUpdateEventDetailsFailed';
}

export class AddFacilityDetailsMockData {
  static readonly type = '[Add Facility] AddFacilityDetailsMockData';
  constructor(
    public payload: {
      id: string;
      name: string;
      isUpdating: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes: any;
    },
  ) {}
}

export class AddEventDetailsMockData {
  static readonly type = '[Add Facility] AddEventDetailsMockData';
  constructor(
    public payload: {
      id: string;
      name: string;
    },
  ) {}
}

export class GetFacilityList {
  static readonly type = '[Add Facility] GetFacilityList';
}

export class GetFacilityDetailById {
  static readonly type = '[Add Facility] GetFacilityDetailById';
  constructor(public payload: { facilityId: string; isUpdate?: boolean }) {}
}

export class UpdateEvent {
  static readonly type = '[Add Facility] UpdateEvent';
  constructor(
    public payload: {
      _id: string;
      name: string;
      embarkLocation: string;
      embarkLocation_2: string;
      disembarkLocation: string;
      disembarkLocation_2: string;
      embarkTime: string;
      disembarkTime: string;
      start: string;
      end: string;
      firstName: string;
      guestNumbers: string;
      lastName: string;
      middleName: string;
      mobile: string;
      emailAddress: string;
      budgetAmount: string;
      trip_type: Trip_Type_Enum;
    },
  ) {}
}

export class GetEventDetailById {
  static readonly type = '[Add Facility] GetEventDetailById';
  constructor(public payload: { eventId: string; isUpdate?: boolean }) {}
}

export class UpdateFacilityDetailsState {
  static readonly type = '[Add Facility] UpdateFacilityDetailsState';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public facilityId: string) {}
}

export class AddEvent {
  static readonly type = '[Add Facility] AddEvent';
  constructor(
    public payload: {
      name: string;
      facility_id: string;
      embarkLocation: string;
      embarkLocation_2: string;
      disembarkLocation: string;
      disembarkLocation_2: string;
      embarkTime: string;
      disembarkTime: string;
      start: string;
      end: string;
      firstName: string;
      guestNumbers: string;
      lastName: string;
      middleName: string;
      mobile: string;
      emailAddress: string;
      budgetAmount: string;
      trip_type: Trip_Type_Enum;
    },
  ) {}
}
