import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[onBlurValidation]',
})
export class OnBlurValidationDirective {
  constructor(private control: NgControl) {}

  @HostListener('click', ['$event'])
  onClick(): void {
    this.control?.control?.markAsUntouched({ onlySelf: true });
  }

  @HostListener('focus', ['$event'])
  onFocus(): void {
    this.control?.control?.markAsUntouched({ onlySelf: true });
    setTimeout(() => {
      // make sure its set to untouched again (at the end of the call stack).
      if (this.control?.control) {
        this.control.control.markAsUntouched({ onlySelf: true });
        this.control.control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }

  @HostListener('blur', ['$event'])
  onBlur(): void {
    if (this.control?.control) {
      this.control.control.markAsTouched({ onlySelf: true });
      this.control.control.updateValueAndValidity({ onlySelf: true });
    }
  }
}
