import { Injectable } from '@angular/core';
import { Locale } from '@finxone-platform/shared/enums';
import {
  FeeManagementServiceType,
  OnboardingConfigFlowTypes,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetProjectSettings, UpdateProjectSettings } from '../actions/project-settings.action';

export interface ProjectSettingsStateModel {
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  enableBeneficiaryFirstAndLastName: boolean;
  locale: string;
  feeManagement: FeeManagementServiceType;
}

@State<ProjectSettingsStateModel>({
  name: 'projectSettings',
  defaults: {
    onboardingFlow: '' as OnboardingConfigFlowTypes,
    defaultCurrency: 'GBP',
    checkPayeeEnabled: true,
    smsAuthenticationEnabled: true,
    locale: Locale.en_GB,
    enableBeneficiaryFirstAndLastName: false,
    feeManagement: {
      enabled: true,
      retryFailedFeeEnabled: true,
      feeReversalEnabled: true,
    },
  },
})
@Injectable()
export class ProjectSettingsState {
  @Selector()
  static getProjectSettings(state: ProjectSettingsStateModel) {
    return state;
  }

  @Action(UpdateProjectSettings)
  updateProjectSettings(ctx: StateContext<ProjectSettingsStateModel>, action: UpdateProjectSettings) {
    ctx.patchState({
      onboardingFlow: action.payload.onboardingFlow,
      defaultCurrency: action.payload.defaultCurrency,
      checkPayeeEnabled: action.payload.checkPayeeEnabled,
      smsAuthenticationEnabled: action.payload.smsAuthenticationEnabled,
      locale: action.payload.locale,
      enableBeneficiaryFirstAndLastName: action.payload.enableBeneficiaryFirstAndLastName,
      feeManagement: {
        enabled: action.payload.feeManagement.enabled,
        retryFailedFeeEnabled: action.payload.feeManagement.retryFailedFeeEnabled,
        feeReversalEnabled: action.payload.feeManagement.feeReversalEnabled,
      },
    });
  }

  @Action(SetProjectSettings)
  setProjectSettings(ctx: StateContext<ProjectSettingsStateModel>, action: SetProjectSettings) {
    ctx.patchState({
      ...ctx.getState(),
      onboardingFlow: action.payload.onboardingFlow,
      defaultCurrency: action.payload.defaultCurrency,
      smsAuthenticationEnabled: action.payload.smsAuthenticationEnabled,
      checkPayeeEnabled: action.payload.checkPayeeEnabled,
      feeManagement: {
        enabled: action.payload.feeManagement.enabled,
        retryFailedFeeEnabled: action.payload.feeManagement.retryFailedFeeEnabled,
        feeReversalEnabled: action.payload.feeManagement.feeReversalEnabled,
      },
    });
  }
}
