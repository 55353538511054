import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap } from 'rxjs';
import { GetChartDetail } from '../actions/chart.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import { DailyBalanceValue } from '../services/account-service/account.type';

export interface ChartStateModel {
  chartDetail: DailyBalanceValue[];
}

@State<ChartStateModel>({
  name: 'chart',
  defaults: {
    chartDetail: [],
  },
})
@Injectable()
export class ChartState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static chartDetail(state: ChartStateModel) {
    return state;
  }

  @Action(GetChartDetail)
  fetchChartDetail(ctx: StateContext<ChartStateModel>, action: GetChartDetail) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetChartDetail' }));

    return this.accountService.getChartData(action.param).pipe(
      tap((chartResult: DailyBalanceValue[]) => {
        ctx.patchState({
          chartDetail: chartResult,
        });

        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetChartDetail' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetChartDetail' }));
        throw _err;
      }),
    );
  }
}
