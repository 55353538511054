import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';

import { ActivatedRoute, Router } from '@angular/router';
import { ListCurrentEntity, RefreshCurrentEntity } from '../actions/travel-mgmt-entity.actions';
import {
  GetActiveEventsGQL,
  GetCustomQuestionAnswerQueryGQL,
  GetFacilitiesGQL,
  GetFacilityByIdQueryGQL,
  GetTotalEmployeesGQL,
  GetTotalEventsGQL,
  GetTotalFacilitiesGQL,
  GetTripByIdQueryGQL,
  GetTripEmployeesQueryGQL,
  GetTripsQueryGQL,
} from '../custom-data-models/travel-management/generated';

import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { ProfileStateModel } from '@finxone-platform/shared/sys-config-types';
import * as Apollo from 'apollo-angular';
import { Observable, Subject, map } from 'rxjs';
import { ProfileState } from './user-profile.state';

export interface QuesionAnswerStateModel {
  entity_fixed_custom_question: QuestionAnswerType[];
}

export interface QuestionAnswerType {
  id: string;
  entity: string;
  entity_id?: string;
  page_name?: string;
  question_header: string;
  question_subheader: string;
  question_type: string;
  answers: ChoiceAnswerType[];
  unique_id: string;
}
export interface ChoiceAnswerType {
  user_id: string;
  id: string;
  value: string;
  suboption: any[];
}
export interface ChoiceAnswerGroupByType {
  count: number;
  user_id: string;
  id: string;
  value: string;
  data: ChoiceAnswerType;
  suboption: unknown[];
}
export interface QuesionAnswerStateModel {
  entity_fixed_custom_question: QuestionAnswerType[];
}

export interface TravelMgmtEntityStateModel {
  currentEntity: unknown;
  listEntity: unknown;
  [key: string]: any;
}

@State<TravelMgmtEntityStateModel>({
  name: 'entity',
  defaults: {
    currentEntity: {},
    listEntity: [],
  },
  children: [],
})
@Injectable()
export class TravelMgmtEntityState {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  private routeEntityId = '';

  @Select(ProfileState.getProfile)
  private profileStateData!: Observable<ProfileStateModel>;
  public profileID: string;
  constructor(
    route: ActivatedRoute,
    private getTripById: GetTripByIdQueryGQL,
    private getTrips: GetTripsQueryGQL,
    private getEmployeeTrips: GetTripEmployeesQueryGQL,
    private getFacilityById: GetFacilityByIdQueryGQL,
    private getQuestionAnsByQuestioType: GetCustomQuestionAnswerQueryGQL,
    private getFacility: GetFacilitiesGQL,
    private getTotalEventsGQL: GetTotalEventsGQL,
    private getTotalFacilitiesGQL: GetTotalFacilitiesGQL,
    private getTotalEmployeesGQL: GetTotalEmployeesGQL,
    private getActiveEventsGQL: GetActiveEventsGQL,
    private router: Router,
  ) {
    route.params.subscribe((params) => {
      this.routeEntityId = params['id'];
    });

    this.profileStateData
      .pipe(
        map((profileData: ProfileStateModel) => {
          if (profileData?.id) {
            this.profileID = profileData.id;
          }
        }),
      )
      .subscribe();
  }

  @Selector()
  static getCurrentEntity(state: TravelMgmtEntityStateModel) {
    return state.currentEntity;
  }

  @Selector()
  static listEntity(state: TravelMgmtEntityStateModel) {
    return state.listEntity;
  }

  @Selector()
  static listEntityData(state: TravelMgmtEntityStateModel) {
    return state;
  }

  @Action(RefreshCurrentEntity)
  fetchCardDetails(ctx: StateContext<TravelMgmtEntityStateModel>, action: RefreshCurrentEntity) {
    const paramData = this.router.url.split('/');
    this.routeEntityId = paramData[4];
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'RefreshCurrentEntity' }));
    if (action.type.includes('count')) {
      let whereCondition = {};
      if (action.type == 'activecount') {
        whereCondition = {
          start: { _lt: JSON.stringify(new Date()) },
          end: { _gte: JSON.stringify(new Date()) },
        };
      } else if (action.type == 'facilityInUsecount') {
        whereCondition = {
          trips_aggregate: { count: { predicate: { _gt: 0 } } },
        };
      } else if (action.type == 'facilityAvailablecount') {
        whereCondition = {
          trips_aggregate: { count: { predicate: { _eq: 0 } } },
        };
      } else {
        whereCondition = {};
      }
      if (action.entity.includes('trip_aggregate')) {
        (whereCondition as any)['user_id'] = { _eq: this.profileID };
      }
      this.getCurrentEntityQuery(action.entity, 'current', action.type == 'activecount' ? action.type : '')
        .fetch({
          where: whereCondition,
        })
        .subscribe((result) => {
          ctx.setState({
            ...ctx.getState(),
            currentEntity: {
              ...(ctx.getState().currentEntity as any),
              [action.entity]: {
                ...(ctx.getState().currentEntity as any)[action.entity],
                [action.type]: result.data[action.entity]['aggregate'].count,
              },
            },
          });
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'RefreshCurrentEntity' }));
        });
    } else {
      this.getCurrentEntityQuery(action.entity, 'current')
        .fetch({
          id: this.routeEntityId,
        })
        .subscribe((result) => {
          ctx.setState({
            ...ctx.getState(),
            currentEntity: {
              [action.entity]: result.data[action.entity + '_by_pk'],
            },
          });
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'RefreshCurrentEntity' }));
        });
    }
  }

  private getCurrentEntityQuery(entity: string, type: string, queryType = ''): Apollo.Query<any, any> {
    switch (entity) {
      case 'trip':
        return type === 'list' ? this.getTrips : this.getTripById;

      case 'facility':
        return this.getFacilityById;

      case 'entity_fixed_custom_question':
        return this.getQuestionAnsByQuestioType;

      case 'trip_employee':
        return this.getEmployeeTrips;

      case 'facilitylist':
        return this.getFacility;

      case 'trip_aggregate':
        return queryType == 'activecount' ? this.getActiveEventsGQL : this.getTotalEventsGQL;

      case 'facility_aggregate':
        return this.getTotalFacilitiesGQL;
      case 'trip_employee_aggregate':
        return this.getTotalEmployeesGQL;

      default:
        return this.getTripById;
    }
  }

  @Action(ListCurrentEntity)
  fetchList(ctx: StateContext<TravelMgmtEntityStateModel>, action: ListCurrentEntity) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'ListCurrentEntity' }));
    if (action.entity.includes('trip')) {
      action.where['user_id'] = { _eq: this.profileID };
    }
    this.getCurrentEntityQuery(action.entity, 'list')
      .fetch({
        offset: action.offset,
        limit: action.limit,
        where: action.where,
      })
      .subscribe((result) => {
        const entityName = action.entity == 'facilitylist' ? 'facility' : action.entity;
        const prevData: any[] = ctx.getState().listEntity as any;
        let questionSummaryResult;
        if (action.listType == 'add') {
          questionSummaryResult = result.data[entityName];
        } else {
          if (prevData) {
            questionSummaryResult = prevData.concat(result.data[entityName]);
          } else {
            questionSummaryResult = result.data[entityName];
          }
        }
        ctx.setState({
          ...ctx.getState(),
          [action.entity]: { listEntity: questionSummaryResult },
          // listEntity: {
          //   [action.entity]: questionSummaryResult,
          // },
        });

        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'ListCurrentEntity' }));
      });
  }
}
