export const exchangeTransactionHistoryItemTemplate = `
<div class="transaction w-100 border-bottom">
    <div class="ml-1 mr-1 mt-2">
        <h3 class="text-left m-0"> {{ description }} </h3>
        <p class="text-left transaction-date m-0"> {{ status }}. {{ datePipe transactionDate  format='d MMM y' }} </p>
    </div>
    <div class="is-right">
        <h3 class="text-right m-0">{{ currencyPipe amount currencyCode=currency display='symbol-narrow' }}</h3>
    </div>
</div>
`;
