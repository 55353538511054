import { Injectable } from '@angular/core';
import { UserInfoType } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { MetadataService } from '../services/metadata-service/metadata-service.service';
import { GetManageUserDetail } from '../actions/manage-user-detail.action';

@State<UserInfoType>({
  name: 'userView',
})
@Injectable()
export class ManageUserDetailState {
  constructor(private metadataService: MetadataService) {}

  @Selector()
  static getUser(state: UserInfoType) {
    return state;
  }
  @Action(GetManageUserDetail)
  fetchUser(ctx: StateContext<UserInfoType>, action: GetManageUserDetail) {
    try {
      return this.metadataService.getUser(action.id).pipe(
        tap((userDetail: UserInfoType) => {
          ctx.setState(userDetail);
        }),
      );
    } catch (err) {
      return throwError(() => err);
    }
  }
}
