import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, ListingTypeEnum } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  GetClientFeeChargeHistoryListing,
  GetFeeChargeHistoryListing,
  ResetSelectedFeeHistoryItem,
} from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function retryFailedFee(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _router: Router,
  feeManagementService: FeeManagementService,
  alertHandlerService: AlertHandlerService,
) {
  const selectedFeeItem = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedFeeHistoryItem;
  const feeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);

  if (selectedFeeItem) {
    feeManagementService
      .retryFailedFee(selectedFeeItem.id)
      .pipe(
        tap(() => {
          store.dispatch(new ResetSelectedFeeHistoryItem());
          if (widgetProperties?.['listingType'] === ListingTypeEnum.CLIENT_FEE_CHARGED_HISTORY) {
            const pageNumber = feeManagementState?.clientFeeChargeHistoryListing?.page;
            store.dispatch(
              new GetClientFeeChargeHistoryListing(
                pageNumber,
                100,
                feeManagementState?.selectedClientDetails?.id ?? '',
              ),
            );
          }
          if (widgetProperties?.['listingType'] === ListingTypeEnum.FEE_CHARGE_HISTORY_LISTING) {
            const pageNumber = feeManagementState?.feeChargeHistoryListing?.page;
            store.dispatch(new GetFeeChargeHistoryListing(pageNumber, 100));
          }
          if (widgetProperties?.['zoneToNavigate']) redirectToPage(_router, widgetProperties);
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected fee details not available', 'Failed to retry');
  }
}
