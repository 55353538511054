import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetBankBeneficiaryNameState } from '../actions/bank-beneficiary-name.action';

export interface BankBeneficiaryNameStateModel {
  name: string;
}
@State<BankBeneficiaryNameStateModel>({
  name: 'bankBeneficiaryNameState',
  defaults: {
    name: 'Suggested Test User',
  },
})
@Injectable()
export class BankBeneficiaryNameState {
  @Selector()
  static getBankBeneficiaryName(state: BankBeneficiaryNameStateModel) {
    return state;
  }

  @Action(SetBankBeneficiaryNameState)
  setBankBeneficiaryNameState(
    ctx: StateContext<BankBeneficiaryNameStateModel>,
    action: SetBankBeneficiaryNameState,
  ) {
    ctx.setState({
      name: action.payload,
    });
  }
}
