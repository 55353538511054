import { Component, Input } from '@angular/core';
import {
  CardInfoType,
  CardType,
  PaymentProviderType,
  ProfileStateModel,
  UiZoneWidgetAttributeConfig,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';

import { CardAdapterParentComponent } from './card-adapter-parent-component';

@Component({
  template: '',
})
export abstract class CardAdapterChildComponent<ProviderType = PaymentProviderType> {
  @Input() public parent: CardAdapterParentComponent<ProviderType>;

  public get text() {
    return this.parent.text;
  }

  public get cardStyle(): { [key: string]: string } {
    return this.parent.cardStyle;
  }

  public get cardClass(): { [key: string]: boolean } {
    return this.parent.cardClass;
  }

  /**
   * @description: virtual card data properties
   */

  public get virtualCardData(): CardInfoType {
    return this.parent.virtualCardData;
  }

  public get cardConfigData(): { [key: string]: boolean } {
    return this.parent.cardConfigData;
  }

  public get isFreezeCard(): boolean {
    return this.parent.isFreezeCard;
  }

  public get isCardAvailable(): boolean {
    return this.parent.isCardAvailable;
  }

  public get isCardActivation(): boolean {
    return this.parent.isCardActivation;
  }

  public get borderStyle(): baseCssConfigStyle {
    return this.parent.borderStyle;
  }

  public set borderStyle(borderStyle: baseCssConfigStyle) {
    this.parent.borderStyle = borderStyle;
  }

  public get borderStyleBlackStripe(): baseCssConfigStyle {
    return this.parent.borderStyleBlackStripe;
  }

  public get attri(): UiZoneWidgetAttributeConfig {
    return this.parent.attri;
  }

  public get pageType(): string {
    return this.parent.pageType;
  }

  public get isCardFlipped(): boolean {
    return this.parent.isCardFlipped;
  }

  public get cardBase64(): string {
    return this.parent.cardBase64;
  }

  public get cardIFrameUrl(): string {
    return this.parent.cardIFrameUrl;
  }

  public get userProfileDetail(): ProfileStateModel {
    return this.parent.userProfileDetail;
  }

  public get isVirtualCard() {
    return this.virtualCardData.type === CardType.VIRTUAL;
  }

  public clicked() {
    this.parent.clicked.emit();
  }

  public onClick() {
    this.clicked();
  }
}
