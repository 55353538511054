import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';

import { AlertHandlerService } from '@finxone-platform/shared/services';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { approvePaymentRequest } from './submit-payment-request-action';

export function paymentRequestApprove(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const formState = store.selectSnapshot(FormActionState.getFormActionState);
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;

  if (formState?.response?.formData) {
    if (isSmsAuthEnabled) {
      router.navigateByUrl(`/zones/payments/payment-authentication-approver`, {
        replaceUrl: true,
      });
    } else {
      approvePaymentRequest(store, action, router, alertHandlerService);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'FormState data not found.');
    throw Error('FormState data not found.');
  }
}
