<ng-container [ngSwitch]="paymentGatewayConfig.payment">
  <ng-container *ngSwitchCase="'modulr'">
    <finxone-web-frontend-virtual-card>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </finxone-web-frontend-virtual-card>
  </ng-container>

  <ng-container *ngSwitchCase="'integratedFinance'">
    <finxone-web-frontend-IF-card>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </finxone-web-frontend-IF-card>
  </ng-container>

  <ng-container *ngSwitchCase="'meawallet'">
    <finxone-web-frontend-meawallet-card>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </finxone-web-frontend-meawallet-card>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <finxone-web-frontend-virtual-card>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </finxone-web-frontend-virtual-card>
  </ng-container>
</ng-container>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
