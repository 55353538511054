import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ACCOUNTTYPE, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { BeneficiaryState } from '../../../state/beneficiary.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function setBeneficiaryDataAndRedirectToEditBeneficiaryPage(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const existingFormData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const beneficiariesList = store.selectSnapshot(BeneficiaryState.getBeneficiary).items;
  if (existingFormData?.bid && beneficiariesList) {
    const selected = beneficiariesList.find((v) => v.id === existingFormData.bid);
    if (selected) {
      const addressInfo = { ...selected?.address };
      let destinationIdentifierInfo = { ...selected.destinationIdentifier };
      destinationIdentifierInfo = {
        ...destinationIdentifierInfo,
        bankId: destinationIdentifierInfo['id'],
      };
      delete destinationIdentifierInfo['id'];
      const formActionData: Record<string, any> = {
        ...selected,
        ...addressInfo,
        ...destinationIdentifierInfo,
        destinationIdentifier: {
          ...destinationIdentifierInfo,
          id: destinationIdentifierInfo['bankId'],
        },
        countryIso2: existingFormData?.beneficiaryCountry,
      };
      delete formActionData['address'];
      delete formActionData['customerId'];
      store.dispatch(
        new UpdateFormDataActionWithId(
          formActionData as Record<string, string | null | undefined>,
          FormActionTypeEnum.INTL_BENEFICIARY_ADD,
        ),
      );

      const newWidgetProps = {
        ...widgetProperties,
      };
      if (
        selected.accountType === ACCOUNTTYPE.PERSONAL &&
        !widgetProperties['urlToNavigate'].includes(ACCOUNTTYPE.PERSONAL)
      ) {
        newWidgetProps['urlToNavigate'] =
          widgetProperties['urlToNavigate'] + `-${formatNameAsUrl(ACCOUNTTYPE.PERSONAL)}`;
      }
      if (
        selected.accountType === ACCOUNTTYPE.BUSINESS &&
        !widgetProperties['urlToNavigate'].includes(ACCOUNTTYPE.BUSINESS)
      ) {
        newWidgetProps['urlToNavigate'] =
          widgetProperties['urlToNavigate'] + `-${formatNameAsUrl(ACCOUNTTYPE.BUSINESS)}`;
      }
      redirectToPage(router, newWidgetProps);
    }
  } else {
    alertHandlerService.showAlertFn('error', 'Beneficiary data not found, please try again');
    widgetProperties['urlToNavigate'] = 'beneficiary-list';
    redirectToPage(router, widgetProperties);
  }
}
