import { Injectable } from '@angular/core';
import { SupportedDevice } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetRole, UpdateDevice, UpdateRole } from '../actions/role.action';

export interface RoleStateModel {
  role: string;
  subscription: string;
  device: SupportedDevice;
}

@State<RoleStateModel>({
  name: 'role',
  defaults: {
    role: '',
    subscription: 'default',
    device: 'mobile',
  },
})
@Injectable()
export class RoleState {
  @Selector()
  static getRole(state: RoleStateModel) {
    return state;
  }

  @Action(UpdateRole)
  updateRole(ctx: StateContext<RoleStateModel>, action: UpdateRole) {
    ctx.patchState({
      role: action.roleId,
    });
  }

  @Action(SetRole)
  fetchRoles(ctx: StateContext<RoleStateModel>, action: SetRole) {
    if (action.deviceType) {
      ctx.patchState({
        role: action.roleId,
        subscription: action.subscription,
        device: action.deviceType,
      });
    } else {
      ctx.patchState({
        role: action.roleId,
        subscription: action.subscription,
      });
    }
  }

  @Action(UpdateDevice)
  updateDevice(ctx: StateContext<RoleStateModel>, action: UpdateDevice) {
    ctx.patchState({
      device: action.device,
    });
  }
}
