import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UploadRevenirTransactionReceipt } from '../../../actions/revenir.action';
import { RevenirState } from '../../../state/revenir.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export const uploadReviewedTransactionDocument = (
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
) => {
  const transactionDetails = store.selectSnapshot(RevenirState.getSelectedRevenirTransactionDetails);

  store.dispatch(new UploadRevenirTransactionReceipt(transactionDetails?.transaction_id));

  router.navigateByUrl(
    `/zones/${formatNameAsUrl(widgetProperties['zoneToNavigate'])}/${widgetProperties['urlToNavigate']}`,
    { replaceUrl: true },
  );
};
