export class Config {
  'api_endpoints'!: any;
  auth!: AuthConfig;
  hotjarSiteId?: number;
  novuApplicationIdentifier?: string;
  idleTimeout?: number;
  multiplayerAppEditorEnabled?: boolean;
}

export class AuthConfig {
  realm!: string;
  client_id!: string;
}
