import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EmailVerificationComponent } from './Modules/shared/widgets/email-verification/email-verification.component';
import { PasswordForgotComponent } from './Modules/shared/widgets/password-forgot/password-forgot.component';
import { FeatureRedirectComponent } from './feature-redirector/feature-redirect.component';
import { LoginComponent } from './lib/components/login/login.component';
import { PostAuthComponent } from './lib/components/post-auth/post-auth.component';

const routes: Routes = [
  {
    path: 'account-homepage',
    loadChildren: () =>
      import('./Modules/account-homepage/account-homepage.module').then((mod) => mod.AccountHomepageModule),
  },
  {
    path: 'zones',
    loadChildren: () => import('./Modules/zones/zones.module').then((mod) => mod.ZonesModule),
  },
  {
    path: 'verify-email/:token',
    component: EmailVerificationComponent,
  },
  {
    path: 'forgot-password/:token',
    component: PasswordForgotComponent,
  },
  {
    path: 'feature-redirect/:zone/:role/:subscription',
    component: FeatureRedirectComponent,
  },
  {
    path: 'feature-redirect/:zone/:role/:subscription/:onboardingOrgId',
    component: FeatureRedirectComponent,
  },
  {
    path: 'feature-redirect',
    component: FeatureRedirectComponent,
  },
  {
    path: 'organisation-onboarding-completion/:onboardingOrgId',
    loadChildren: () =>
      import('./Modules/shared/organisation-module/organisation.module').then(
        (module) => module.OrganisationPageModule,
      ),
  },
  {
    path: 'associate-user-organisation/:onboardingOrgId',
    loadComponent: () =>
      import(
        './Modules/shared/organisation-module/associate-user-organisation/associate-user-organisation.component'
      ).then((component) => component.AssociateUserOrganisationComponent),
  },
  {
    path: 'post-auth',
    component: PostAuthComponent,
  },
  {
    path: 'post-auth/:redirectionFlow',
    component: PostAuthComponent,
  },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
