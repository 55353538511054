<div
  class="card-activation-wrapper pointer-events-none"
  [ngStyle]="contentStyle"
  (click)="goToActivateCardPage()"
>
  <span>
    <mat-icon class="active-card" svgIcon="active-card"></mat-icon>
    <span>{{ placeholderText }}</span>
  </span>
</div>
