import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { redirectToPage } from '../cta-button-actions.utils';
import { submitDomesticPaymentRequest } from './submit-payment-request';

export function checkAuthSmsStatusAndRedirect(
  router: Router,
  widgetProperties: BaseWidgetProperties,
  store: Store,
  actions: Actions,
) {
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;

  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties);
  } else {
    widgetProperties['urlToNavigate'] = 'payment-processing';
    submitDomesticPaymentRequest(store, actions, router);
    redirectToPage(router, widgetProperties);
  }
}
