import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { Alert, AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ALERT_STYLE_CONFIGURATION_CONSTANTS,
  ActiveDevice,
  AlertBehaviourSettings,
  AlertItemConfigurationType,
  AlertPositions,
  AlertStyleConfigurationType,
  AlertTypes,
  BaseCssProperties,
  DismissalTypeMobile,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'finxone-web-frontend-alert-handler',
  templateUrl: './alert-handler.component.html',
  styleUrls: ['./alert-handler.component.scss'],
})
export class AlertHandlerComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  alertType: AlertTypes;
  alertMsg: string | undefined;
  headerMsg: string | undefined = 'Error!';
  isShowCloseIcon = true;
  public displayAlert: boolean;
  public activeDevice: ActiveDevice;
  alerts: Alert[];
  alertConfigDataContainer: AlertStyleConfigurationType = ALERT_STYLE_CONFIGURATION_CONSTANTS;
  public contentStyle: baseCssConfigStyle;
  public alertTitleStyle: { 'font-size': string; 'font-family': string };
  public alertDescriptionStyle: { 'font-size': string; 'font-family': string };
  private alertConfigurationData: AlertStyleConfigurationType;
  constructor(
    private alertHandlerService: AlertHandlerService,
    private deviceDetectorService: DeviceDetectorService,
    private configService: ConfigService,
  ) {
    this.getAlertConfigurationData();
  }
  ngOnInit() {
    this.activeDevice = this.deviceDetectorService.deviceType as ActiveDevice;
  }

  getAlertConfigurationData() {
    this.configService
      .getAlertSettingsAndStyleConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((alertConfigData) => {
        this.alertConfigDataContainer = alertConfigData ?? ALERT_STYLE_CONFIGURATION_CONSTANTS;
      });
    this.mapAlertDataBasedOnType();
  }

  mapAlertDataBasedOnType() {
    this.alertHandlerService.showAlert$.pipe(takeUntil(this.destroy$)).subscribe((alerts) => {
      this.alerts = alerts.map((alert, index) => {
        const alertTypeConfig = this.alertConfigDataContainer?.[this.activeDevice]?.[
          alert.type as AlertTypes
        ] as AlertItemConfigurationType;
        return {
          type: alert.type as AlertTypes,
          msg: alert.msg,
          headerMsg: alert.headerMsg,
          isShowCloseIcon: alert.isShowCloseIcon ?? true,
          displayAlert: alert.displayAlert ?? false,
          position: this.getAlertPositionStyleAndSetTimeout(alert, index),
          dismissalType: this.getAlertBehaviourConfigByDeviceAndAlertType(alert.type as AlertTypes)
            ?.dismissalType,
          contentStyle: this.alertHandlerService.getAlertStyleConfig(alertTypeConfig, [
            BaseCssProperties.MARGIN,
            BaseCssProperties.PADDING,
            BaseCssProperties.MIN_WIDTH,
            BaseCssProperties.TEXTALIGN,
            BaseCssProperties.BACKGROUND_COLOR,
            BaseCssProperties.BOX_SHADOW,
            BaseCssProperties.ALERT_BORDER,
          ]),
          alertTitleStyle: this.alertHandlerService.getAlertStyleConfig(alertTypeConfig, [
            BaseCssProperties.TITLE_FONT,
          ]) as { 'font-size': string; 'font-family': string },
          alertDescriptionStyle: this.alertHandlerService.getAlertStyleConfig(alertTypeConfig, [
            BaseCssProperties.DESCRIPTION_FONT,
          ]) as { 'font-size': string; 'font-family': string },
          leftIcon: this.alertHandlerService.getAlertStyleConfig(alertTypeConfig, [
            BaseCssProperties.ALERT_LEFT_ICON,
          ]) as { name: string; size: string; color: string; unit: string },
          rightIcon: this.alertHandlerService.getAlertStyleConfig(alertTypeConfig, [
            BaseCssProperties.ALERT_RIGHT_ICON,
          ]) as { name: string; size: string; color: string; unit: string },
        };
      });
    });
  }

  private getAlertPositionStyleAndSetTimeout(alert: Alert, alertIndex: number): baseCssConfigStyle {
    const activeDevice = this.deviceDetectorService.deviceType as ActiveDevice;
    const alertConfigByDevice = this.getAlertBehaviourConfigByDeviceAndAlertType(alert.type as AlertTypes);
    const positionStyles = this.alertHandlerService.getAlertPositionStyles(
      alertConfigByDevice?.position,
      activeDevice,
    );
    const alertPositionNotCenter = !!(
      alertConfigByDevice?.position !== AlertPositions.CENTER &&
      alertConfigByDevice?.position !== AlertPositions.CENTER_LEFT &&
      alertConfigByDevice?.position !== AlertPositions.CENTER_RIGHT
    );

    // Set top and bottom values based on device type and existing positionStyles
    alertIndex++;
    if (positionStyles.top !== 'unset' && alertPositionNotCenter) {
      positionStyles.bottom = 'unset';
      positionStyles.top = `${alertIndex * 10}%`;
    } else if (positionStyles.bottom !== 'unset' && alertPositionNotCenter) {
      positionStyles.top = 'unset';
      positionStyles.bottom = `${alertIndex * 10}%`;
    }
    this.setTimeOutWithConfigIfAvailable(alert, alertConfigByDevice);
    return positionStyles;
  }

  private getAlertBehaviourConfigByDeviceAndAlertType(
    alertType: AlertTypes,
  ): AlertBehaviourSettings | undefined {
    const activeDevice = this.deviceDetectorService.deviceType as ActiveDevice;
    return this.alertConfigDataContainer[activeDevice]?.[alertType]?.behaviourSettings;
  }

  private setTimeOutWithConfigIfAvailable(alert: Alert, alertConfigByDevice?: AlertBehaviourSettings) {
    if (alertConfigByDevice?.dismissalType === DismissalTypeMobile.CLOSE_AFTER) {
      const dismissAfter = alertConfigByDevice?.dismissAfter ? Number(alertConfigByDevice.dismissAfter) : 4;
      const timerInMiliSec = dismissAfter * 1000;
      const alertIndex = this.alerts.findIndex((v) => v === alert);
      setTimeout(() => {
        this.alertHandlerService.hideAlert(alertIndex);
      }, timerInMiliSec);
    }
  }
}
