import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { formatNameAsUrl } from './zone-url.utils';

export const unauthenticatedRoutes = [
  `/zones/${formatNameAsUrl(APP_ZONES.ONBOARDING)}`,
  `/zones/${formatNameAsUrl(APP_ZONES.ORGANISATION_ONBOARDING)}`,
  `/zones/${formatNameAsUrl(APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION)}`,
  `/zones/${formatNameAsUrl(APP_ZONES.SIGNED_OUT_INTRODUCTION)}`,
  `/zones/${formatNameAsUrl(APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD)}`,
  `/feature-redirect`,
  '/organisation-onboarding-completion/',
];

export function isAuthenticatedRoute(route: string) {
  if (unauthenticatedRoutes.some((unauthenticatedRoute) => route.includes(unauthenticatedRoute))) {
    return false;
  } else {
    return true;
  }
}
