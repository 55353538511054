import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateRole } from '../actions/role.action';

export function setActiveRoleIfNeeded(zone: string | undefined, store: Store) {
  const zonesRequiringRoleChange: string[] = [
    APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION,
    APP_ZONES.SIGNED_OUT_INTRODUCTION,
    APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD,
    APP_ZONES.ORGANISATION_ONBOARDING,
    APP_ZONES.ORGANISATION_VERIFICATION,
  ];

  const zonesToRoleMap = new Map<string, string>([
    [APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION, 'signed-out'],
    [APP_ZONES.SIGNED_OUT_INTRODUCTION, 'signed-out'],
    [APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD, 'signed-out'],
    [APP_ZONES.ORGANISATION_ONBOARDING, 'organisation-anonymous'],
    [APP_ZONES.ORGANISATION_VERIFICATION, 'owner'],
  ]);

  if (zone && zonesRequiringRoleChange.includes(zone)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    store.dispatch(new UpdateRole(zonesToRoleMap.get(zone)!));
  }
}
