export class GetUsersList {
  static readonly type = '[Users] GetUsersList';
  constructor(public id: string = '1', public page: number = 1, public limit: number = 5) {}
}

export class GetUsersListWithPersona {
  static readonly type = '[Users] GetUsersListWithPersona';
  constructor(
    public id: string = '1',
    public page: number = 1,
    public limit: number = 5,
    public searchText: string = '',
  ) {}
}

export class GetTravelUsersList {
  static readonly type = '[TravelUsers] GetTravelUsersList';
  constructor(public userType: string = 'guest', public page: number = 1, public limit: number = 5) {}
}
