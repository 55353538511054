import { Injectable } from '@angular/core';
import { UsersList } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { GetUsers } from '../actions/users.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

@State<UsersList[]>({
  name: 'users',
  defaults: [],
})
@Injectable()
export class UsersState {
  constructor(private metadataService: MetadataService) {}

  @Selector()
  static getUsers(state: UsersList) {
    return state;
  }
  @Action(GetUsers)
  fetchUsers(ctx: StateContext<UsersList>, action: GetUsers) {
    try {
      return this.metadataService
        .getUsers(action.page, action.limit, action.sortKey, action.sortOrder, action?.query)
        .pipe(
          tap((usersList: UsersList) => {
            ctx.setState(usersList);
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }
}
