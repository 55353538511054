<div
  class="card-more-icon"
  (click)="isCardMenuOpen = !isCardMenuOpen; $event.stopPropagation()"
  (clickOutside)="isCardMenuOpen = false"
>
  <mat-icon svgIcon="three-dots"> </mat-icon>
</div>

<div class="card-menu-content" *ngIf="isCardMenuOpen">
  <div class="list">
    <div *ngIf="isVirtualCard" class="item" (click)="onDeleteVirtualCard(); $event.stopPropagation()">
      <p class="title remove">Remove</p>
    </div>
    <div
      *ngIf="paymentProvider !== 'integratedFinance'"
      class="item"
      (click)="flipCard(); $event.stopPropagation()"
    >
      <p class="title">Show Details</p>
    </div>
  </div>
</div>
