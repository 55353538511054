import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ExitPortListType,
  RevenirTransactionItemType,
  RevenirTransactionType,
  TripDetailsType,
  TripPayload,
  TripsList,
  UploadBoardingPassResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Observable, firstValueFrom } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class RevenirService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public createRevenirSubjectToken() {
    return this.http.post(`${this.baseUrl}/revenir/subject/create`, {});
  }

  public getTripTransactions(): Observable<RevenirTransactionType[]> {
    return this.http.get<RevenirTransactionType[]>(`${this.baseUrl}/revenir/transactions`);
  }

  public getTransactionDetails(transactionId: string): Observable<{ transaction: RevenirTransactionType }> {
    return this.http.get<{ transaction: RevenirTransactionType }>(
      `${this.baseUrl}/revenir/transactions/${transactionId}`,
    );
  }

  public getTransactionItems(transactionId: string): Observable<{ items: RevenirTransactionItemType[] }> {
    return this.http.get<{ items: RevenirTransactionItemType[] }>(
      `${this.baseUrl}/revenir/transactions/${transactionId}/items`,
    );
  }

  public getTransactionReceipt(transactionId: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<Blob>(`${this.baseUrl}/revenir/transactions/${transactionId}/receipt`, httpOptions);
  }

  public getTripDetails(tripId: string): Observable<TripDetailsType> {
    return this.http.get<TripDetailsType>(`${this.baseUrl}/revenir/trips/${tripId}`);
  }

  public getRevenirTrips(archived?: string): Observable<TripsList> {
    const url = `${this.baseUrl}/revenir/trips${archived ? `?archived=${archived}` : ''}`;
    return this.http.get<TripsList>(url);
  }

  public createRevenirTrip(payload: TripPayload) {
    return this.http.post(`${this.baseUrl}/revenir/trips`, payload);
  }

  makeBinaryData(file: File) {
    return new Promise<{ binary: ArrayBuffer; error?: Error }>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const binaryData = reader.result as ArrayBuffer;
        resolve({ binary: binaryData });
      };
      reader.onerror = () => {
        reject({ error: new Error('Something went wrong') });
      };
    });
  }

  async uploadTransactionReceipt(transactionId: string, localBlobUrl: string) {
    const blob = await this.getBlobData(localBlobUrl);
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      Accept: '*/*',
    });
    return this.http.post(`${this.baseUrl}/revenir/transactions/${transactionId}/receipts`, blob, {
      headers,
    });
  }

  public archiveTripByTripId(tripId: string): Observable<{ message: string }> {
    const payload = {
      tripId: tripId,
    };
    return this.http.patch<{ message: 'string' }>(`${this.baseUrl}/revenir/trips/${tripId}/archive`, payload);
  }

  public getBarcodeImageByTripId(tripId: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/revenir/trips/${tripId}/barcode`, { responseType: 'blob' });
  }

  public getPortList(countryCode: string, type: string): Observable<ExitPortListType> {
    return this.http.get<ExitPortListType>(`${this.baseUrl}/revenir/ports/${countryCode}/${type}`);
  }

  public uploadBoardingPass(payload: FormData) {
    return this.http.post<UploadBoardingPassResponse>(this.baseUrl + '/revenir/trips/document', payload);
  }
  public uploadBoardingPassWithTripId(payload: FormData, tripId: string) {
    return this.http.post(this.baseUrl + `/revenir/trips/${tripId}/ticket`, payload);
  }

  public getBoardingPass(tridId: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/revenir/trips/${tridId}/document`, {
      responseType: 'blob',
    });
  }

  public getBlobData(localBlobUrl: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return firstValueFrom(this.http.get<Blob>(`${localBlobUrl}`, httpOptions));
  }

  public async uploadPassport(localBlobUrl: string, userId: string) {
    const blob = await this.getBlobData(localBlobUrl);
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      Accept: '*/*',
    });
    return this.http.post(this.baseUrl + `/revenir/subjects/${userId}/passports`, blob, { headers });
  }
}
