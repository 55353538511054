import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { UserProfileUpdated } from '../../../actions/user-profile.action';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { mapFormData } from '../../utils';
import { formatNameAsUrl } from '../../zone-url.utils';

export function submitVerificationProfileChanges(
  store: Store,
  actions: Actions,
  router: Router,
  formSubmissionService: FormSubmissionService,
) {
  const formStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.KYC_DETAILS),
  );
  const keyMapping: { [key: string]: string } = {
    address1: 'line_1',
    address2: 'line_2',
    address3: 'line_3',
    address4: 'line_4',
    addressCity: 'city',
    addressPostCode: 'post_code',
    addressState: 'county',
    addressCountry: 'country',
  };
  const mappedFormData = mapFormData(formStateData?.formData, keyMapping);
  formSubmissionService.submitProfileUpdateRequest(mappedFormData);
  actions
    .pipe(
      ofActionDispatched(UserProfileUpdated),
      take(1),
      map(() => {
        router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.VERIFICATION)}/information-confirmation`);
        store.dispatch(new ResetFormDataAction());
      }),
    )
    .subscribe();
}
