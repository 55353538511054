import { Component, Input } from '@angular/core';
import { Alert, AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ActiveDevice,
  DismissalTypeDesktop,
  DismissalTypeMobile,
  IconConfigType,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';

@Component({
  selector: 'finxone-platform-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent {
  @Input() public msg: string | undefined = 'Your Sample text here.';
  @Input() public contentStyle: baseCssConfigStyle;
  @Input() public alertData: Alert;
  @Input() public alertIndex: number;
  @Input() public leftIcon: IconConfigType;
  @Input() public rightIcon: IconConfigType;
  @Input() public activeDevice: ActiveDevice;
  @Input() public alertTitleStyle: { 'font-size': string; 'font-family': string };
  @Input() public alertDescriptionStyle: { 'font-size': string; 'font-family': string };
  @Input() public headerMsg: undefined | string = 'Warning';
  @Input() public isShowCloseIcon = true;

  public dismissalTypes = { ...DismissalTypeDesktop, ...DismissalTypeMobile };
  public translateX = 0; // To track horizontal movement
  public translateY = 0; // To track vertical movement
  public opacity = 1; // To track opacity for fade-out effect

  private touchStartX = 0;
  private touchStartY = 0;
  private swipeThreshold = 150; // Minimum distance for swipe dismiss

  constructor(private alertHandlerService: AlertHandlerService) {}

  public close() {
    this.alertHandlerService.hideAlert(this.alertIndex);
  }

  public checkDismissalTypeAndClose(alert: Alert, elementId: string) {
    if (alert?.dismissalType === this.dismissalTypes.CLOSE_ON_HOVER) {
      const element = document.getElementById(elementId);
      if (element?.classList.contains('close-on-hover')) {
        this.close();
      }
    }
  }

  public getAlertStyles() {
    return this.alertData.dismissalType === this.dismissalTypes.SWIPE_TO_DISMISS
      ? {
          ...this.contentStyle,
          transform: 'translate(' + this.translateX + 'px, ' + this.translateY + 'px)',
          opacity: this.opacity,
        }
      : this.contentStyle;
  }
  public handleSwipe(event: TouchEvent, eventType: string) {
    if (
      this.alertData.dismissalType === this.dismissalTypes.SWIPE_TO_DISMISS &&
      this.activeDevice === ActiveDevice.MOBILE
    ) {
      if (eventType === 'touchstart') {
        this.onTouchStart(event);
      }
      if (eventType === 'touchmove') {
        this.onTouchMove(event);
      }
      if (eventType === 'touchend') {
        this.onTouchEnd();
      }
    }
  }

  private onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
    this.touchStartY = event.changedTouches[0].screenY;
    this.translateX = 0;
    this.translateY = 0;
  }

  private onTouchMove(event: TouchEvent) {
    const touchCurrentX = event.changedTouches[0].screenX;
    const touchCurrentY = event.changedTouches[0].screenY;

    // Calculate how far the finger has moved in X and Y directions
    this.translateX = touchCurrentX - this.touchStartX;
    this.translateY = touchCurrentY - this.touchStartY;

    // Gradually decrease opacity as the element is swiped away
    const swipeDistance = Math.max(Math.abs(this.translateX), Math.abs(this.translateY));
    this.opacity = Math.max(1 - swipeDistance / this.swipeThreshold, 0);
  }

  private onTouchEnd() {
    if (
      this.activeDevice === ActiveDevice.MOBILE &&
      this.alertData?.dismissalType === this.dismissalTypes.SWIPE_TO_DISMISS
    ) {
      const swipeDistanceX = this.translateX;
      const swipeDistanceY = this.translateY;
      // If the swipe exceeds the threshold in any direction, dismiss the toast
      if (Math.abs(swipeDistanceX) > this.swipeThreshold || Math.abs(swipeDistanceY) > this.swipeThreshold) {
        this.close();
      } else {
        // Reset the toast position if swipe wasn't strong enough
        this.resetToastPosition();
      }
    }
  }

  private resetToastPosition() {
    // Reset the toast position and opacity back to the original state
    this.translateX = 0;
    this.translateY = 0;
    this.opacity = 1;
  }
}
