import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string, removeTag = true): SafeHtml {
    // Remove <style> and <script> tags from the HTML content
    const sanitizedValue = removeTag ? removeStyleAndScriptTags(value) : value;

    // Use bypassSecurityTrustHtml on the modified HTML
    return this.sanitized.bypassSecurityTrustHtml(sanitizedValue);
  }
}

export function removeStyleAndScriptTags(html: string): string {
  // Create a temporary div element to hold the HTML
  const tempDiv = document.createElement('div');

  const isHtmlString = /<[a-z][\s\S]*>/i.test(html);

  // Check if the input string contains HTML tags
  if (isHtmlString) {
    tempDiv.innerHTML = html; // Use innerHTML for HTML strings
  } else {
    tempDiv.innerText = html; // Use innerText for normal strings
  }

  // Remove style tags from the temporary div
  const styleTags = tempDiv.getElementsByTagName('style');
  for (let i = styleTags.length - 1; i >= 0; i--) {
    styleTags[i].parentNode?.removeChild(styleTags[i]);
  }

  // Remove script tags from the temporary div
  const scriptTags = tempDiv.getElementsByTagName('script');
  for (let i = scriptTags.length - 1; i >= 0; i--) {
    scriptTags[i].parentNode?.removeChild(scriptTags[i]);
  }

  // Return the modified HTML or Text
  return isHtmlString ? tempDiv.innerHTML : tempDiv.innerText;
}
