import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription, combineLatest, startWith } from 'rxjs';
import {
  OnboardIndividualFinishedFormStructure,
  OnboardIndividualSingupFinishedFormStructure,
} from '../../utils/onboarding/form.types';

/**
 * Allows use to hold a form group in this service that we can then inject
 * into multiple pages in order so build up a complex form submission
 */
@Injectable({
  providedIn: 'root',
})
export class MultiPartFormHolderService {
  config: StagesHolder = new StagesHolder([]);
  setFormStages(formStages: Array<FormStage>) {
    this.config = new StagesHolder(formStages);
  }
  resetFormStages() {
    this.config.clearAllForms();
  }
}

export class StagesHolder {
  constructor(stages: Array<FormStage>) {
    this.stages = stages;
    const formStatusChangeObservables = this.stages
      .filter((stage) => stage.form !== undefined)
      .map((stage) => stage.form.statusChanges.pipe(startWith(null)));
    this.currentValueChangesTracking = combineLatest(formStatusChangeObservables).subscribe((change) => {
      this.allFormsValid.next(this.stages.map((stage) => stage.form?.valid).every((result) => result));
    });
  }
  private stages: Array<FormStage> = [];
  private currentValueChangesTracking: Subscription;
  private allFormsValid = new Subject<boolean>();
  public allFormsValid$ = this.allFormsValid.asObservable();

  concatFormsToJson(): OnboardIndividualFinishedFormStructure {
    const form: any = {};
    this.stages.forEach((stage) => {
      if (stage.form !== undefined) {
        form[stage.name] = stage.form.getRawValue();
      }
    });
    return form;
  }
  private removeDuplicateFormStages(formStages: Array<FormStage>): Array<FormStage> {
    return formStages.filter((item, index, self) => {
      return index === self.findIndex((obj) => obj.name === item.name);
    });
  }

  concatSignupFormsToJson(): OnboardIndividualSingupFinishedFormStructure {
    const form: any = {};
    const formStages = this.removeDuplicateFormStages(this.stages);
    formStages.forEach((stage) => {
      if (stage.form !== undefined) {
        form[stage.name] = stage.form.getRawValue();
      }
    });
    return form;
  }

  getStage(name: string): FormStage {
    return this.stages.filter((v) => v.name == name)[0];
  }

  totalStages() {
    return this.stages.length;
  }

  nextStageRoute(position: number): string {
    return this.stages.filter((v) => v.position == position + 1)[0].route;
  }

  stageIsValid(stageName: string): boolean {
    return (
      this.stages.find((stage) => {
        return stage.name === stageName;
      })?.form?.valid ?? false
    );
  }

  formsAreValid(): boolean {
    return this.stages.every((stage) => {
      if (stage.form !== undefined) {
        if (!stage.form.valid) return false;
        else return true;
      } else return true;
    });
  }

  clearAllForms() {
    this.stages.forEach((stage) => {
      if (stage.form !== undefined) {
        stage.form.reset();
      }
    });
    this.currentValueChangesTracking.unsubscribe();
  }

  addStage(stage: FormStage) {
    /* Checks if form is already exists inside the stage then 
    it will give you the index of it and replace it with new form */
    const stageIndex = this.stages.findIndex((form) => form.name === stage.name);
    if (stageIndex !== -1) {
      this.stages.splice(stageIndex, 1, stage);
    } else {
      this.stages.push(stage);
    }
    this.currentValueChangesTracking.unsubscribe();
    const formStatusChangeObservables = this.stages
      .filter((stage) => stage.form !== undefined)
      .map((stage) => stage.form.statusChanges.pipe(startWith(null)));
    this.currentValueChangesTracking = combineLatest(formStatusChangeObservables).subscribe((change) => {
      this.allFormsValid.next(this.stages.map((stage) => stage.form?.valid).every((result) => result));
    });
  }

  concatDynamicFormsToJson() {
    const form: any = {};
    this.stages.forEach((stage) => {
      if (stage.form !== undefined) {
        form[stage.name] = stage.form.getRawValue();
      }
    });
    return form;
  }
}

export interface IFormStage {
  position: number;
  name: string;
  route: string;
  form: FormGroup<any>;
}

export class FormStage {
  position: number;
  name: string;
  route: string;
  form: FormGroup<any>;

  constructor({ position, name, route, form }: IFormStage) {
    this.position = position;
    this.name = name;
    this.route = route;
    this.form = form;
  }

  getForm() {
    return this.form;
  }
}
