import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, ViewUserAssignPersona } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function mangeUseredit(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  metadataService: MetadataService,
) {
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.MANAGE_USER_EDIT),
  )?.formData;

  if (formData) {
    const payload: ViewUserAssignPersona = convertFormDataInPayload(formData);

    if (payload.roleId == '') {
      alertHandlerService.showAlertFn('error', 'Please select persona');
      return;
    }
    metadataService
      .viewUserAssignPersona(payload)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'User updated successfully');
            widgetProperties['urlToNavigate'] = 'select-user';
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occured, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn(
      'error',
      'Form data not available, please make sure all steps are completed',
    );
  }
}
function convertFormDataInPayload(formData: Record<string, any>): ViewUserAssignPersona {
  return {
    id: formData?.['id'],
    roleId: formData?.['assign_persona'],
    additionalRoles: formData?.['assign_role_permission'] ?? [],
  };
}
