import { FormActionType, FormPagesType, FormSubmissionType } from '../state/form-submision.state';

export class SetFormAction {
  static readonly type = '[FormAction] Set Form Action';
  constructor(public payload: FormActionType) {}
}

export class SetFormActionWithId {
  static readonly type = '[FormAction] Set Form Action With Id';
  constructor(public payload: FormActionType, public id: string) {}
}

export class UpdateFormDataAction {
  static readonly type = '[formSubmissionHandler] Update Form Data';
  constructor(
    public payload: FormSubmissionType | Record<string, string | undefined | null>,
    public id?: string,
  ) {}
}

export class UpdateFormDataActionWithId {
  static readonly type = '[formSubmissionHandler] Update Form Data With Id';
  constructor(public formData: Record<string, any>, public id: string) {}
}

export class ClearFormDataActionWithId {
  static readonly type = '[formSubmissionHandler] Clear Form Data With Id';
  constructor(public id: string) {}
}

export class UpdateFormPageAction {
  static readonly type = '[formPagesHandler] Update Form Pages';
  constructor(public payload: FormPagesType) {}
}

export class ResetFormDataAction {
  static readonly type = '[FormActionState] ResetFormDataAction';
}

export class RemoveSelectedCSVFileFromBulkPaymentsFormState {
  static readonly type = '[FormActionState] RemoveSelectedCSVFileFromBulkPaymentsFormState';
}
