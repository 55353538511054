import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { AccountService } from '../../../services/account-service/account-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';
export function removeBeneficiaryFromAccount(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  ____: MetadataService,
  __?: GraphqlServiceService,
  ___?: UiZoneWidgetAttributeConfig,
  accountService?: AccountService,
) {
  const beneficiaryId = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData?.bid;
  if (beneficiaryId) {
    accountService
      ?.removeBeneficiary(beneficiaryId)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Beneficiary removed successfully');
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Selected Beneficiary not found, please try again');
  }
}
