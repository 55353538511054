import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { redirectToPage } from '../cta-button-actions.utils';

export function resetAssignPersonaFormStateAndRedirect(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
) {
  store.dispatch(new ResetFormDataAction());
  redirectToPage(router, widgetProperties);
}
