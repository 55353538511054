export class GetUsers {
  static readonly type = '[Users] GetUsers';
  constructor(
    public page: number = 1,
    public limit: number = 5,
    public sortKey: string = 'lastName',
    public sortOrder: string = 'DESC',
    public query: string = '',
  ) {}
}
