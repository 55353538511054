import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsersList } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { GetEmployeeList } from '../actions/employee-list.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

@State<UsersList[]>({
  name: 'employeeList',
  defaults: [],
})
@Injectable()
export class EmployeeListState {
  constructor(private metadataService: MetadataService, private router: Router) {}

  @Selector()
  static getEmployeeList(state: UsersList) {
    return state;
  }
  @Selector()
  static getUsersCount(state: UsersList) {
    return state.totalRecords;
  }

  @Action(GetEmployeeList)
  fetchTravelUsersWithId(ctx: StateContext<UsersList>, action: GetEmployeeList) {
    const paramData = this.router.url.split('/');
    const eventId = paramData[4];
    console.log(eventId);
    try {
      return this.metadataService
        .getTravelUsersList(action.userType, eventId, action.page, action.limit)
        .pipe(
          tap((usersList: UsersList) => {
            ctx.setState(usersList);
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }
}
