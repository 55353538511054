import { Router } from '@angular/router';
import { ResetFormDataAction } from '@app/finxone-web-frontend/app/lib/actions/form-submission.action';
import { RefreshCurrentEntity } from '@app/finxone-web-frontend/app/lib/actions/travel-mgmt-entity.actions';
import { MetadataService } from '@app/finxone-web-frontend/app/lib/services/metadata-service/metadata-service.service';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { TravelMgmtEntityState } from '@app/finxone-web-frontend/app/lib/state/travel-mgmt-entity.state';
import { formatNameAsUrl } from '@app/finxone-web-frontend/app/lib/utils/zone-url.utils';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties, InviteGuestResponseType } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { GetEventDetailById } from '../../../actions/add-facility.action';
import { UsersListState } from '../../../state/users-list.state';

function getTypeIdFromUrl(): string {
  const urlParams = window.location.pathname;
  const params = urlParams.split('/');
  params.splice(0, 3);
  return params[1];
}

export function goToInviteGuest(
  widgetProperties: BaseWidgetProperties,
  _: Store,
  __: Actions,
  router: Router,
  ___: AlertHandlerService,
  ____: MetadataService,
) {
  const eventId = getTypeIdFromUrl();
  // navigate to Invite Guest page
  router.navigateByUrl(
    `/zones/${formatNameAsUrl(widgetProperties['zoneToNavigate'])}/${
      widgetProperties['urlToNavigate']
    }/${eventId}`,
  );
}

export function inviteGuest(
  _: BaseWidgetProperties,
  store: Store,
  __: Actions,
  ___: Router,
  alertHandlerService: AlertHandlerService,
  metadataService: MetadataService,
) {
  const email = store.selectSnapshot(FormActionState.getFormActionState).response?.formData['email'] ?? '';
  store.dispatch(new RefreshCurrentEntity('trip', ''));
  const tripEntity: any = store.selectSnapshot(TravelMgmtEntityState.getCurrentEntity);
  const name = tripEntity?.trip?.name ?? '';
  const type = 'event-guest';
  const typeId = getTypeIdFromUrl();

  if (email && name && type) {
    metadataService.inviteGuest({ email, name, type, typeId }).subscribe((_: InviteGuestResponseType) => {
      alertHandlerService.showAlertFn(
        'success',
        'Your guest has been invited. New guests will have to onboard.',
      );
      store.dispatch(new ResetFormDataAction()); // wipe email from formActionState
    });
  } else {
    console.error('email, name, or type is missing.');
  }
}

export function emailToGuests(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  ___: AlertHandlerService,
  ____: MetadataService,
) {
  const employees = store.selectSnapshot(UsersListState.getUsersList);
  if (employees.result.length) {
    const emails = employees.result.map((user) => user.email);
    const bccString = emails.join(',');
    const mailtoUrl = `mailto:?bcc=${bccString} `;
    window.location.href = mailtoUrl;
  }
}

export function goToEditEvent(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  ___: AlertHandlerService,
  ____: MetadataService,
) {
  const eventId = getTypeIdFromUrl();
  store.dispatch(new GetEventDetailById({ eventId: eventId, isUpdate: true }));
  router.navigate(['zones/broker-events/edit-information']);
}
