import {
  PaymentRequestUpdateRequest,
  PaymentRequestorRequest,
} from '../services/account-service/account.type';

export class CreatePaymentRequest {
  static readonly type = '[Payment] CreatePaymentRequest';
  constructor(public paymentRequest: PaymentRequestorRequest) {}
}

export class PaymentRequestSuccess {
  static readonly type = '[Payment] PaymentRequestSuccess';
}

export class PaymentRequestFailed {
  static readonly type = '[Payment] PaymentRequestFailed';
}

export class UpdatePaymentRequest {
  static readonly type = '[Payment] UpdatePaymentRequest';
  constructor(public paymentRequest: PaymentRequestUpdateRequest) {}
}

export class UpdatePaymentRequestSuccess {
  static readonly type = '[Payment] UpdatePaymentRequestSuccess';
}

export class UpdatePaymentRequestFailed {
  static readonly type = '[Payment] UpdatePaymentRequestFailed';
}
