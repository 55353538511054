import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { redirectToPage } from '../cta-button-actions.utils';

export function saveTransactionAction(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
) {
  widgetProperties['urlToNavigate'] = 'scanning-receipt';
  redirectToPage(router, widgetProperties);

  setTimeout(() => {
    widgetProperties['urlToNavigate'] = 'add-transaction-step-2';
    redirectToPage(router, widgetProperties);
  }, 3000);
}
