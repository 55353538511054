import { Component, Input } from '@angular/core';

@Component({
  selector: 'finxone-platform-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() color = 'var(--color-3)';
  @Input() styleClass = 'app-progress-bar';
  @Input() style = { height: '5px' };
  @Input() mode = 'determinate';
  @Input() value: number;
  @Input() showValue: boolean;
  @Input() unit: string;
}
