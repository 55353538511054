import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { FormActionTypeEnum } from 'libs/shared/enums/form-action.enum';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function checkFormActionDataKycDetailsAndRedirect(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  __: MetadataService,
) {
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState)?.[
    FormActionTypeEnum.KYC_DETAILS
  ]?.formData;
  if (formStateData) {
    if (formStateData?.['isEmailVerified'] === false || formStateData?.['isPhoneVerified'] === false) {
      alertHandlerService.showAlertFn('error', 'Please verify phone and email beofre proceeding');
      return;
    }
    if (formStateData?.['isEmailVerified'] === true || formStateData?.['isPhoneVerified'] === true) {
      redirectToPage(router, widgetProperties);
    }
  }
}
