import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { map, Subject, takeUntil } from 'rxjs';
import { ConfigService } from '../../../../lib/services/config-service/config-service.service';
import { MetadataService } from '../../../../lib/services/metadata-service/metadata-service.service';

/**
 * The `EmailVerificationComponent` class is responsible for handling the email verification process.
 * It verifies the email token received from the route parameters and displays a success or error message accordingly.
 */
@Component({
  selector: 'finxone-web-frontend-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailVerificationComponent implements OnInit {
  /**
   * A Subject used for unsubscribing from observables to prevent memory leaks.
   */
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  /**
   * A string variable used to store and display the message text.
   */
  messageText = '';

  /**
   * A string variable used to store the platform name.
   */
  platformName = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private metaDataService: MetadataService,
    private configService: ConfigService,
  ) {}

  /**
   * Initializes the component by setting the initial message text and subscribing to the route parameters.
   */
  ngOnInit() {
    this.messageText = 'Verifying your email address...';

    this.configService
      .getSystemName()
      .pipe(
        takeUntil(this.destroy$),
        map((systemName) => {
          this.platformName = systemName;
        }),
      )
      .subscribe();

    // Get the token from the route parameters
    this.route.params.subscribe((params) => {
      const token = params['token'];

      // Send the token to the backend for verification
      this.metaDataService.verifyEmailToken(token).subscribe({
        next: (response) => {
          // Handle the verification response
          // For example, show a success message or an error message
          if (response) {
            this.messageText = 'Your email has been verified and you will be redirecting to next page...';
          }
        },
        error: (error) => {
          // Handle the error, e.g., display an error message
          this.messageText = error;
        },
        complete: () => {
          // Handle completion
          this.router.navigate(['/login']);
        },
      });
    });
  }
}
