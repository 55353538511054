import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiPartFormHolderService } from './multipart-form-holder.service';

@NgModule({})
export class MultipartFormHolderModule {
  // Done this way to ensure only one instance exists in the entire application even inside the lazy loaded modules
  // see the MultipartFormHolderModule.forRoot() import in AppModule as well
  static forRoot() {
    return {
      ngModule: MultipartFormHolderModule,
      providers: [MultiPartFormHolderService],
    };
  }
}
