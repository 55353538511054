import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetSelectedClient } from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function handleRemoveClientFromFeeGroupRequest(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const selectedClientDetails = store.selectSnapshot(
    FeeManagementState.getFeeManagement,
  )?.selectedClientDetails;
  if (selectedClientDetails?.id) {
    feeManagementService
      .removeClientFromFeeGroup(selectedClientDetails.feeGroupId, selectedClientDetails.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Client removed from fee group successfully');
            store.dispatch(new ResetSelectedClient());
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}
