import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, throwError } from 'rxjs';
import { clearLocalStorageExcept } from './utils/utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(protected keycloakService: KeycloakService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            clearLocalStorageExcept([
              'account',
              'transactions',
              'beneficiary',
              'notifications',
              'lastLoggedInId',
              'APP_PREFERENCES',
              'firstTime',
            ]);
            this.keycloakService.login({
              redirectUri: window.location.href,
            });
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
