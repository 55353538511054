import { Injectable } from '@angular/core';
import { SingleExecutionResult } from '@apollo/client/core';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AddEmployeeRequestType,
  ENTITY_TYPE,
  EmployeeTripType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import {
  AddEntityFile,
  AddEvent,
  AddEventDetailsMockData,
  AddFacilityDetails,
  AddFacilityDetailsMockData,
  AddUpdateEventDetailsFailed,
  AddUpdateEventDetailsSuccess,
  AddUpdateFacilityDetailsFailed,
  AddUpdateFacilityDetailsSuccess,
  GetEventDetailById,
  GetFacilityDetailById,
  GetFacilityList,
  GetFacilityTypes,
  UpdateEvent,
  UpdateFacilityDetails,
  UpdateFacilityDetailsState,
} from '../actions/add-facility.action';
import {
  AddEmployeeToTripAction,
  AddEmployeeToTripSuccessAction,
  AddTripEmployeeMockData,
  GetEmployeeDetailsById,
  GetNextTripDetails,
  GetTotalLinkedTripToEmployee,
  GetTripList,
  GetTripListByEmployeeId,
  RemoveEmployeeFromTrip,
  UpdateSelectedEmployee,
} from '../actions/crud-entity.action';
import { UpdateFormDataActionWithId } from '../actions/form-submission.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import {
  AddEmployeeToTripGQL,
  AddEntityFileGQL,
  AddEventGQL,
  AddFacilityGQL,
  GetFacilityByIdQueryGQL,
  GetFacilityGQL,
  GetFacilityTypesGQL,
  GetTotalEmployeesGQL,
  GetTripByIdQueryGQL,
  GetTripEmployeesQueryGQL,
  GetTripWithoutQueryGQL,
  RemoveEmployeeFromTripEmployeeGQL,
  UpdateEventGQL,
  UpdateFacilityGQL,
} from '../custom-data-models/travel-management/generated';
import { MetadataService } from '../services/metadata-service/metadata-service.service';
import { ProfileState } from './user-profile.state';

export interface CrudEntityStateWorkflowModel {
  facilityTypes: { id: string; key: string; value: string }[];
  facilityList: {
    id: string;
    name: string;
    is_mobile: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributes: any;
  }[];

  currentFacility: {
    id: string;
    name: string;
    isUpdating: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributes: any;
  };

  currentEvent: {
    id: string;
    name: string;
    isUpdating: boolean;
  };

  tripEmployee: {
    selectedEmployeeId: string;
    selectedEmployeeDetails: AddEmployeeRequestType;
    nextTripAt: string;
    totalLinkedTrip: number;
    trip: EmployeeTripType[];
  };

  tripList: { id: string; name: string }[];
}

@State<CrudEntityStateWorkflowModel>({
  name: 'crudEntity',
  defaults: {
    facilityTypes: [],
    facilityList: [],
    currentFacility: { id: '', name: '', isUpdating: false, attributes: {} },
    currentEvent: {
      id: '',
      name: '',
      isUpdating: false,
    },
    tripEmployee: {
      selectedEmployeeId: '',
      nextTripAt: '',
      selectedEmployeeDetails: {} as AddEmployeeRequestType,
      totalLinkedTrip: 0,
      trip: [],
    },
    tripList: [],
  },
})
@Injectable()
export class CrudEntityState {
  constructor(
    private store: Store,
    private metadataService: MetadataService,
    private alertHandlerService: AlertHandlerService,
    public getFacilityTypesGQL: GetFacilityTypesGQL,
    private getFacilityGQL: GetFacilityGQL,
    private addFacilityGQL: AddFacilityGQL,
    private addEventGQL: AddEventGQL,
    private updateEventGQL: UpdateEventGQL,
    private updateFacilityGQL: UpdateFacilityGQL,
    private addEntityFileGQL: AddEntityFileGQL,
    private getFacilityByIdQuery: GetFacilityByIdQueryGQL,
    private getEventById: GetTripByIdQueryGQL,
    private getEmployeeTrips: GetTripEmployeesQueryGQL,
    private getTotalEmployeesGQL: GetTotalEmployeesGQL,
    private getTripWithoutQueryGQL: GetTripWithoutQueryGQL,
    private removeEmployeeFromTripEmployeeGQL: RemoveEmployeeFromTripEmployeeGQL,
    private addEmployeeToTripGQL: AddEmployeeToTripGQL,
  ) {}

  @Selector()
  static getFacilityTypes(state: CrudEntityStateWorkflowModel) {
    return state.facilityTypes;
  }

  @Selector()
  static getFacilityList(state: CrudEntityStateWorkflowModel) {
    return state.facilityList;
  }

  @Selector()
  static getCurrentFacility(state: CrudEntityStateWorkflowModel) {
    return state.currentFacility;
  }

  @Selector()
  static getCurrentEvent(state: CrudEntityStateWorkflowModel) {
    return state.currentEvent;
  }

  @Selector()
  static getTripEmployee(state: CrudEntityStateWorkflowModel) {
    return state.tripEmployee;
  }

  @Selector()
  static getProfilePictureId(state: CrudEntityStateWorkflowModel) {
    return (state.tripEmployee?.selectedEmployeeDetails?.logo ??
      state.tripEmployee?.selectedEmployeeDetails?.profilePicture) as string;
  }

  @Selector()
  static getTripList(state: CrudEntityStateWorkflowModel) {
    return state.tripList;
  }

  @Action(UpdateFacilityDetailsState)
  updateFacilityDetailsState(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    action: UpdateFacilityDetailsState,
  ) {
    const currentState = ctx.getState();
    const facilityDetail = currentState.facilityList.find((item) => item.id === action.facilityId);

    ctx.patchState({
      ...currentState,
      currentFacility: {
        ...currentState.currentFacility,
        id: facilityDetail?.id ?? currentState.currentFacility?.id,
        name: facilityDetail?.name ?? currentState.currentFacility?.name,
        attributes: facilityDetail?.attributes ?? currentState.currentFacility?.attributes,
      },
    });
  }

  @Action(AddFacilityDetailsMockData)
  createFacilityDetailsMockData(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    action: AddFacilityDetailsMockData,
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      ...currentState,
      currentFacility: { ...currentState.currentFacility, ...action.payload },
    });
  }

  @Action(AddEventDetailsMockData)
  createEventDetailsMockData(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    action: AddEventDetailsMockData,
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      ...currentState,
      currentEvent: { ...currentState.currentEvent, ...action.payload },
    });
  }

  @Action(AddTripEmployeeMockData)
  createTripEmployeeMockData(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    action: AddTripEmployeeMockData,
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      ...currentState,
      tripEmployee: { ...currentState.tripEmployee, ...action.payload },
    });
  }

  @Action(UpdateSelectedEmployee)
  updateSelectedEmployee(ctx: StateContext<CrudEntityStateWorkflowModel>, action: UpdateSelectedEmployee) {
    const currentState = ctx.getState();
    ctx.patchState({
      ...currentState,
      tripEmployee: {
        ...currentState.tripEmployee,
        selectedEmployeeId: action.employeeId,
      },
    });
  }

  @Action(GetEmployeeDetailsById)
  fetchEmployeeDetailsById(ctx: StateContext<CrudEntityStateWorkflowModel>, action: GetEmployeeDetailsById) {
    try {
      return this.metadataService.getUser(action.employeeId).pipe(
        tap((employeeDetails) => {
          const currentState = ctx.getState();
          ctx.patchState({
            ...currentState,
            tripEmployee: {
              ...currentState.tripEmployee,
              selectedEmployeeDetails: employeeDetails as AddEmployeeRequestType,
            },
          });
        }),
      );
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(GetFacilityTypes)
  fetchFacilityTypes(ctx: StateContext<CrudEntityStateWorkflowModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetFacilityTypes' }));

    return this.getFacilityTypesGQL.fetch().pipe(
      map((result) => {
        ctx.setState({
          ...ctx.getState(),
          facilityTypes: result?.data?.facility_type ?? [],
        });
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetFacilityTypes',
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetFacilityTypes',
          }),
        );
        throw _err;
      }),
    );
  }

  @Action(GetFacilityList)
  fetchFacilityList(ctx: StateContext<CrudEntityStateWorkflowModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetFacilityList' }));

    return this.getFacilityGQL.fetch().pipe(
      map((result) => {
        ctx.setState({
          ...ctx.getState(),
          facilityList: result?.data?.facility ?? [],
        });
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetFacilityList',
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetFacilityList',
          }),
        );
        throw _err;
      }),
    );
  }

  @Action(AddEvent)
  createEventDetails(ctx: StateContext<CrudEntityStateWorkflowModel>, action: AddEvent) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'AddEvent' }));

    const request = action.payload;
    const { id: userId } = this.store.selectSnapshot(ProfileState.getProfile);

    const obs = this.addEventGQL.mutate({ ...request, user_id: userId });

    return this.addUpdateObs(ctx, obs, 'AddEvent', 'insert_trip', ENTITY_TYPE.EVENT);
  }

  @Action(UpdateEvent)
  updateEventDetails(ctx: StateContext<CrudEntityStateWorkflowModel>, action: UpdateEvent) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'UpdateEvent' }));

    const request = action.payload;

    const obs = this.updateEventGQL.mutate(request);

    return this.addUpdateObs(ctx, obs, 'UpdateEvent', 'update_trip', ENTITY_TYPE.EVENT);
  }

  @Action(AddFacilityDetails)
  createFacilityDetails(ctx: StateContext<CrudEntityStateWorkflowModel>, action: AddFacilityDetails) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'AddFacilityDetails' }));

    const request = action.payload;

    const obs = this.addFacilityGQL.mutate({
      name: request.name,
      is_mobile: request.is_mobile,
      attributes: request.attributes,
    });

    return this.addUpdateObs(ctx, obs, 'AddFacilityDetails', 'insert_facility', ENTITY_TYPE.FACILITY);
  }

  @Action(UpdateFacilityDetails)
  updateFacilityDetails(ctx: StateContext<CrudEntityStateWorkflowModel>, action: UpdateFacilityDetails) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'UpdateFacilityDetails' }));

    const request = action.payload;

    const obs = this.updateFacilityGQL.mutate({
      _id: request.id,
      name: request.name,
      is_mobile: request.is_mobile,
      attributes: request.attributes,
    });

    return this.addUpdateObs(ctx, obs, 'UpdateFacilityDetails', 'update_facility', ENTITY_TYPE.FACILITY);
  }

  public addUpdateObs<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends SingleExecutionResult<Record<string, any>>,
  >(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    obs: Observable<T>,
    actionName: string,
    resultKey: string,
    entityType: string,
  ) {
    return obs.pipe(
      map((result) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: actionName,
          }),
        );
        const data = result.data?.[resultKey]?.returning?.[0];
        if (data) {
          if (entityType === ENTITY_TYPE.FACILITY) {
            this.updateState(ctx, data, ENTITY_TYPE.FACILITY);
            ctx.dispatch(
              new AddUpdateFacilityDetailsSuccess({
                facilityId: data.id,
              }),
            );
          } else if (entityType === ENTITY_TYPE.EVENT) {
            this.updateState(ctx, data, ENTITY_TYPE.EVENT);
            ctx.dispatch(
              new AddUpdateEventDetailsSuccess({
                eventId: data.id,
              }),
            );
          }
        }
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        this.updateState(ctx);
        if (entityType === ENTITY_TYPE.FACILITY) {
          ctx.dispatch(new AddUpdateFacilityDetailsFailed());
        } else if (entityType === ENTITY_TYPE.EVENT) {
          ctx.dispatch(new AddUpdateEventDetailsFailed());
        }
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: actionName,
          }),
        );
        throw _err;
      }),
    );
  }

  public updateState(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    data?: { id: string; name: string },
    entityType?: string,
  ) {
    const currentState = ctx.getState();
    ctx.patchState({
      ...currentState,
      currentFacility:
        entityType === ENTITY_TYPE.FACILITY
          ? {
              ...currentState.currentFacility,
              id: data?.id ?? currentState.currentFacility.id,
              name: data?.name ?? currentState.currentFacility.name,
              isUpdating: false,
            }
          : currentState.currentFacility,

      currentEvent:
        entityType === ENTITY_TYPE.EVENT
          ? {
              ...currentState.currentEvent,
              id: data?.id ?? currentState.currentEvent.id,
              name: data?.name ?? currentState.currentEvent.name,
            }
          : currentState.currentEvent,
    });
  }

  @Action(AddEntityFile)
  createEntityFile(ctx: StateContext<CrudEntityStateWorkflowModel>, action: AddEntityFile) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'AddEntityFile' }));
    return this.addEntityFileGQL.mutate(action.payload).pipe(
      map(() => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'AddEntityFile',
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'AddEntityFile',
          }),
        );
        throw _err;
      }),
    );
  }

  @Action(GetFacilityDetailById)
  fetchFacilityDetailById(ctx: StateContext<CrudEntityStateWorkflowModel>, action: GetFacilityDetailById) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetFacilityDetailById' }));
    return this.getFacilityByIdQuery
      .fetch({
        id: action.payload.facilityId,
      })
      .pipe(
        map((result) => {
          const facilityDetails = result?.data?.facility_by_pk;
          const currentState = ctx.getState();

          ctx.patchState({
            ...currentState,
            currentFacility: {
              ...currentState.currentFacility,
              ...facilityDetails,
              isUpdating: action.payload?.isUpdate ?? false,
            },
          });

          if (action.payload?.isUpdate) {
            ctx.dispatch(
              new UpdateFormDataActionWithId(
                {
                  facilityName: facilityDetails?.name,
                  facilityType: facilityDetails?.attributes?.['facilityType'],
                },
                FormActionTypeEnum.FACILITY_DETAILS,
              ),
            );

            ctx.dispatch(
              new UpdateFormDataActionWithId(
                {
                  ...facilityDetails?.attributes,
                },
                FormActionTypeEnum.CUSTOM_QUESTION,
              ),
            );
          }

          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetFacilityDetailById',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetFacilityDetailById',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(GetEventDetailById)
  fetchEventDetailById(ctx: StateContext<CrudEntityStateWorkflowModel>, action: GetEventDetailById) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetEventDetailById' }));
    return this.getEventById
      .fetch({
        id: action.payload.eventId,
      })
      .pipe(
        map((result) => {
          const eventDetails = result?.data?.trip_by_pk;
          const currentState = ctx.getState();

          ctx.patchState({
            ...currentState,
            currentEvent: {
              ...currentState.currentEvent,
              ...eventDetails,
              isUpdating: action.payload?.isUpdate ?? false,
            },
          });

          if (action.payload?.isUpdate) {
            ctx.dispatch(
              new UpdateFormDataActionWithId(
                {
                  facility_id: eventDetails?.facility?.id,
                  'event-name': eventDetails?.name,
                  'event-first-line-embark-location': eventDetails?.embarkLocation,
                  'event-second-line-embark-location': eventDetails?.embarkLocation_2,
                  'event-first-line-disembark-location': eventDetails?.disembarkLocation,
                  'event-second-line-disembark-location': eventDetails?.disembarkLocation_2,
                  'event-step-3-embark-time': eventDetails?.['embarkTime'],
                  'event-step-3-embark-date': eventDetails?.['start'],
                  'event-step-3-disembark-date': eventDetails?.['end'],
                  'event-step-3-disembark-time': eventDetails?.['disembarkTime'],

                  'event-first-name': eventDetails?.['firstName'],
                  'event-last-name': eventDetails?.['lastName'],
                  'event-middle-name': eventDetails?.['middleName'],
                  'event-email-address': eventDetails?.['emailAddress'],
                  'event-mobile': eventDetails?.['mobile'],
                  'event-number-of-guests': eventDetails?.['guestNumbers'],
                  'event-budget-amount': eventDetails?.['budgetAmount'],

                  facilityType: eventDetails?.trip_type,
                },
                FormActionTypeEnum.CUSTOM_QUESTION,
              ),
            );

            ctx.dispatch(
              new GetFacilityDetailById({
                facilityId: eventDetails?.facility?.id,
              }),
            );
          }
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetEventDetailById',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetEventDetailById',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(GetTripListByEmployeeId)
  fetchTripListByEmployeeId(
    ctx: StateContext<CrudEntityStateWorkflowModel>,
    action: GetTripListByEmployeeId,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetTripListByEmployeeId' }));

    const employeeId = ctx.getState().tripEmployee.selectedEmployeeId;

    return this.getEmployeeTrips
      .fetch({
        offset: action.payload.offset,
        limit: action.payload.limit,
        where: { user_id: { _eq: employeeId } },
      })
      .pipe(
        map((result) => {
          const totalLinkedTrip = (result.data?.trip_employee as EmployeeTripType[]) ?? [];
          ctx.setState({
            ...ctx.getState(),
            tripEmployee: {
              ...ctx.getState().tripEmployee,
              trip: totalLinkedTrip,
            },
          });
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetTripListByEmployeeId',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetTripListByEmployeeId',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(GetTotalLinkedTripToEmployee)
  fetchTotalLinkedTripToEmployee(ctx: StateContext<CrudEntityStateWorkflowModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetTotalLinkedTripToEmployee' }));

    const employeeId = ctx.getState().tripEmployee.selectedEmployeeId;

    return this.getTotalEmployeesGQL
      .fetch({
        where: { user_id: { _eq: employeeId } },
      })
      .pipe(
        map((result) => {
          const totalLinkedTripCount = result.data?.trip_employee_aggregate?.aggregate?.count ?? 0;
          ctx.setState({
            ...ctx.getState(),
            tripEmployee: {
              ...ctx.getState().tripEmployee,
              totalLinkedTrip: totalLinkedTripCount,
            },
          });
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetTotalLinkedTripToEmployee',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetTotalLinkedTripToEmployee',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(GetNextTripDetails)
  fetchNextTripDetails(ctx: StateContext<CrudEntityStateWorkflowModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetNextTripDetails' }));

    const employeeId = ctx.getState().tripEmployee.selectedEmployeeId;
    const currentISODate = new Date().toISOString();

    return this.getEmployeeTrips
      .fetch({
        where: {
          user_id: { _eq: employeeId },
          // to get Trip greater than and equal to current date
          trip: { start: { _gte: currentISODate } },
        },
      })
      .pipe(
        map((result) => {
          /*
          setting up first index of the record because,
          the first index will be the recent trip from current date
         */
          const nextTripDate = result.data?.trip_employee?.[0]?.trip?.start;

          ctx.setState({
            ...ctx.getState(),
            tripEmployee: {
              ...ctx.getState().tripEmployee,
              nextTripAt: nextTripDate,
            },
          });
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetNextTripDetails',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetNextTripDetails',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(RemoveEmployeeFromTrip)
  removeEmployeeFromTrip(ctx: StateContext<CrudEntityStateWorkflowModel>, action: RemoveEmployeeFromTrip) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'RemoveEmployeeFromTrip' }));

    return this.removeEmployeeFromTripEmployeeGQL.mutate({ _eq: action.employeeTripId }).pipe(
      map((result) => {
        const deletedId = result.data?.delete_trip_employee?.returning?.[0].id;
        const currentEmployeeTrip = { ...ctx.getState() }.tripEmployee.trip;

        const filteredTrip = currentEmployeeTrip?.filter((trip) => trip.id !== deletedId);

        ctx.setState({
          ...ctx.getState(),
          tripEmployee: {
            ...ctx.getState().tripEmployee,
            trip: filteredTrip,
          },
        });

        ctx.dispatch(new GetTotalLinkedTripToEmployee());
        ctx.dispatch(new GetNextTripDetails());

        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'RemoveEmployeeFromTrip',
          }),
        );

        this.alertHandlerService.showAlertFn('success', 'Event has been removed from employee details.');
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'RemoveEmployeeFromTrip',
          }),
        );
        throw _err;
      }),
    );
  }

  @Action(GetTripList)
  fetchTripList(ctx: StateContext<CrudEntityStateWorkflowModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetTripList' }));

    return this.getTripWithoutQueryGQL.fetch().pipe(
      map((result) => {
        const trip = result?.data?.trip ?? [];
        ctx.setState({
          ...ctx.getState(),
          tripList: trip as { id: string; name: string }[],
        });
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetTripList',
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'GetTripList',
          }),
        );
        throw _err;
      }),
    );
  }

  @Action(AddEmployeeToTripAction)
  addEmployeeToTrip(ctx: StateContext<CrudEntityStateWorkflowModel>, action: AddEmployeeToTripAction) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'AddEmployeeToTripAction' }));

    const employeeId = ctx.getState().tripEmployee.selectedEmployeeId;

    return this.addEmployeeToTripGQL
      .mutate({
        user_id: employeeId,
        trip_id: action.employeeTripId,
      })
      .pipe(
        map(() => {
          ctx.dispatch(
            new GetTripListByEmployeeId({
              offset: 0,
              limit: 10,
            }),
          );

          ctx.dispatch(new GetTotalLinkedTripToEmployee());
          ctx.dispatch(new GetNextTripDetails());

          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'AddEmployeeToTripAction',
            }),
          );

          ctx.dispatch(new AddEmployeeToTripSuccessAction());
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'AddEmployeeToTripAction',
            }),
          );
          throw _err;
        }),
      );
  }
}
