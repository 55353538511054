<div class="progress-bar-content">
  <p-progressBar
    [color]="color"
    [styleClass]="styleClass"
    [style]="style"
    [mode]="mode"
    [value]="value"
    [showValue]="showValue"
    [unit]="unit"
    [class]="styleClass"
  ></p-progressBar>
</div>
