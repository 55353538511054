/* eslint-disable @typescript-eslint/no-explicit-any */
export class TTLMap<K, V> {
  private data: Map<K, V>;
  private timeoutData: { [key: string]: ReturnType<typeof setTimeout> };
  private ttl: number;

  constructor(ttl: number) {
    this.ttl = ttl;
    this.timeoutData = {};
    this.data = new Map();
  }

  private clearTimeout(key: K) {
    const stringKey = String(key);
    if (this.timeoutData[stringKey]) {
      clearTimeout(this.timeoutData[stringKey]);
      delete this.timeoutData[stringKey];
    }
  }

  private setTimeout(key: K) {
    const stringKey = String(key);
    this.clearTimeout(key);
    this.timeoutData[stringKey] = setTimeout(() => {
      this.clearTimeout(key);
      this.data.delete(key);
    }, this.ttl);
  }

  get(key: K): V | undefined {
    const value = this.data.get(key);
    if (value !== undefined) {
      this.setTimeout(key); // Reset the timer when the key is accessed
    }
    return value;
  }

  set(key: K, val: V): this {
    this.data.set(key, val);
    this.setTimeout(key);
    return this;
  }

  delete(key: K): boolean {
    this.clearTimeout(key);
    return this.data.delete(key);
  }

  clear(): void {
    this.data.clear();
    Object.values(this.timeoutData).forEach((timeout) => {
      clearTimeout(timeout);
    });
    this.timeoutData = {};
  }
}
