export class AddProgressBarStack {
  static readonly type = '[ProgressBar] AddProgressBarStack';
  constructor(public widget: { uniqueId?: string | number }) {}
}

export class RemoveProgressBarStack {
  static readonly type = '[ProgressBar] RemoveProgressBarStack';
  constructor(public widget: { uniqueId?: string | number }) {}
}

export class RemoveAllProgressBarStack {
  static readonly type = '[ProgressBar] RemoveAllProgressBarStack';
}
