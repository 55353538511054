import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsersList } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { GetTravelUsersList, GetUsersList, GetUsersListWithPersona } from '../actions/users-list.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

@State<UsersList[]>({
  name: 'userslist',
  defaults: [],
})
@Injectable()
export class UsersListState {
  constructor(private metadataService: MetadataService, private router: Router) {}

  @Selector()
  static getUsersList(state: UsersList) {
    return state;
  }
  @Action(GetUsersList)
  fetchUsers(ctx: StateContext<UsersList>, action: GetUsersList) {
    try {
      return this.metadataService.getUsersList(action.id, action.page, action.limit).pipe(
        tap((usersList: UsersList) => {
          ctx.setState(usersList);
        }),
      );
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(GetUsersListWithPersona)
  fetchUsersWithPersona(ctx: StateContext<UsersList>, action: GetUsersListWithPersona) {
    try {
      return this.metadataService
        .getUsersListWithPersona(action.id, action.page, action.limit, action.searchText)
        .pipe(
          tap((usersList: UsersList) => {
            ctx.setState(usersList);
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Selector()
  static getUsersCount(state: UsersList) {
    return state.totalRecords;
  }

  @Action(GetTravelUsersList)
  fetchTravelUsersWithId(ctx: StateContext<UsersList>, action: GetTravelUsersList) {
    const paramData = this.router.url.split('/');
    const eventId = paramData[4];
    try {
      return this.metadataService
        .getTravelUsersList(action.userType, eventId, action.page, action.limit)
        .pipe(
          tap((usersList: UsersList) => {
            ctx.setState(usersList);
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }
}
