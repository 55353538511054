import { Locale } from '@finxone-platform/shared/enums';
import { NotificationDeviceOS } from './common.enum';

export type NotificationProviderInfoType = {
  provider: string;
};
export type NotificationList = {
  title: string;
  description: string;
};

export type NotificationListRequest = {
  page: number;
  userId: string;
  pageSize: number;
  organisationId: string;
};

export type NotificationSubscriberUserInfo = {
  id: string;
  orgIds: string[];
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  deviceTokens: string[];
  deviceType?: NotificationDeviceOS;
};

export type NotificationTriggerInfoRequest = {
  subscriber?: NotificationSubscriberUserInfo;
  notificationPayload: NotificationTriggerRequest;
  tenant?: string;
};

export type NotificationTriggerRequest = {
  subscriberId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: NotificationPayload;
  template: NotificationTemplateEnum;
  locale?: Locale;
};

export type NotificationPayloadEmailSettings = {
  cc?: string[];
  to?: string[];
  from?: string;
  senderName?: string;
  text?: string;
  replyTo?: string;
  bcc?: string[];
};

export type Credentials = {
  deviceTokens: string[];
};

export type SubscriberData = {
  _integrationId: string;
  providerId: string;
  credentials: Credentials;
};

export enum NotificationTransactionStatus {
  SUCCESS = 'success',
  FAIL = 'failed',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum NotificationTypeEnum {
  TRANSACTION_INCOMING = 'transaction-incoming',
  TRANSACTION_OUTGOING = 'transaction-outgoing',
  DAILY_BALANCE = 'daily-balance',
  EXCHANGE_COMPLETED = 'exchange-completed',
  EXCHANGE_FAILED = 'exchange-failed',
  PAYMENT_REQUEST = 'payment-request',
  PAYMENT_REQUEST_APPROVED = 'request-approved',
  PAYMENT_REQUEST_REJECTED = 'request-rejected',
  BENEFICIARY_ADDED = 'beneficiary-added',
  BENEFICIARY_UPDATED = 'beneficiary-updated',
  BENEFICIARY_REMOVED = 'beneficiary-removed',
  BULK_PAYMENT_REQUEST = 'bulk-payment-request',
  BULK_PAYMENT_REQUEST_APPROVAL = 'bulk-payment-request-approval',
}

export enum NotificationAlertTypeEnum {
  SELECTED_VALUE = 'selectValue',
  TRANSACTION_ALERTS = 'transactionAlerts',
  DAILY_BALANCE = 'dailyBalance',
  PAYMENTS_REQUEST_ALERTS = 'paymentsRequestAlerts',
  ADD_BENEFICIARY_ALERTS = 'addBeneficiaryAlerts',
}

export enum NotificationTemplateEnum {
  OUTBOUND_NOTIFICATION = 'outbound-notification',
  INBOUND_NOTIFICATION = 'inbound-notification',
  DAILY_BALANCE = 'daily-balance',
  WELCOME_EMAIL = 'welcome-email',
  WELCOME_EMAIL_AFTER_KYC = 'welcome-email-after-kyc',
  ACCOUNT_CLOSURE = 'account-closure',
  PAYMENT_REJECTED = 'payment-rejected',
  DAILY_BALANCE_SUMMARY = 'daily-balance-summary',

  EXCHANGE_BOOKED = 'exchange-booked',
  EXCHANGE_CANCELLED = 'exchange-cancelled',
  EXCHANGE_COMPLETED = 'exchange-completed',
  EXCHANGE_FAILED = 'exchange-failed',
  PASSWORD_RESET_EMAIL = 'password-reset-email',
  PASSWORD_CHANGED_EMAIL = 'password-changed-email',
  ADD_BENEFICIARY = 'add-beneficiary',
  UPDATE_BENEFICIARY = 'update-beneficiary',
  REMOVE_BENEFICIARY = 'remove-beneficiary',
  SERVICE_PROVIDER_REMINDER = 'service-provider-reminder',
  GUEST_INVITE = 'guest-invite',
  ACCESS_PERSONA_GRANTED = 'access-persona-granted',
  SEND_EMAIL_CREDENTIALS = 'send-email-credentials',
  PAYMENT_REQUEST = 'payment-request',
  PAYMENT_REQUEST_STATUS = 'payment-request-status',
  THREEDS_CHALLENGE = '3ds-challenge',
  BULK_PAYMENT_REQUEST = 'bulk-payment-request',
  BULK_PAYMENT_REQUEST_APPROVAL = 'bulk-payment-request-approval',
  INDIVIDUAL_PROFILE_UPDATE = 'individual-profile-update',
  BUSINESS_PROFILE_UPDATE = 'business-profile-update',
  APPLICATION_REJECTION = 'application-rejection',
}

export const transactionStatusMapping: Record<string, string> = {
  [NotificationTypeEnum.TRANSACTION_INCOMING]: 'incoming',
  [NotificationTypeEnum.TRANSACTION_OUTGOING]: 'outgoing',
};
export type NotificationPayload = {
  [key: string]:
    | string
    | NotificationTypeEnum
    | NotificationTransactionStatus
    | boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any;
};

export enum DefaultToggleOption {
  ON = 'on',
  OFF = 'off',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PushPayload = { [key: string]: any };
