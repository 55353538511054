export class PageConfigurationProvider {
  constructor(
    public gridsterMargin: {
      left: number;
      right: number;
      top: number;
      unit: string;
    },
    public showHeader: boolean,
    public labelsAsPlaceholder?: boolean,
    public isUsingHtmlStructure?: boolean,
  ) {}
}
