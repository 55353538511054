<label *ngIf="!labelAsPlaceholder && label" class="mb-1 d-block" [ngStyle]="colorTextAlignStyle">{{
  label
}}</label>

<ng-template #inputTemplate>
  @if(isCommaDirective || type === 'currency'){
  <input
    appNumberFormat
    onBlurValidation
    [ngStyle]="combinedInputStyles"
    [name]="name"
    [class]="className"
    class="finx-input"
    [maxLength]="maxLength"
    [placeholder]="labelAsPlaceholder ? label : placeholder ?? ''"
    [type]="type"
    (keypress)="error && onKeypress()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (change)="onChange($event)"
    (ngModelChange)="onNgModelChange()"
    [disabled]="readOnly"
    [value]="value"
    (keyup)="onKeyup($event)"
    (keydown)="onEnterClick($event)"
    [formControl]="formControl"
    [ngModel]="value"
    [checked]="checked"
    [min]="minValue"
    [class.disabled-input]="readOnly"
    [attr.inputmode]="inputMode"
  />
  } @else {
  <input
    onBlurValidation
    [ngStyle]="combinedInputStyles"
    [name]="name"
    [class]="className"
    class="finx-input"
    [maxLength]="maxLength"
    [placeholder]="labelAsPlaceholder ? label : placeholder ?? ''"
    [type]="type"
    (keypress)="error && onKeypress()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (change)="onChange($event)"
    (ngModelChange)="onNgModelChange()"
    [disabled]="readOnly"
    [value]="value"
    (keyup)="onKeyup($event)"
    (keydown)="onEnterClick($event)"
    [formControl]="formControl"
    [ngModel]="value"
    [checked]="checked"
    [min]="minValue"
    [max]="maxValue"
    [class.disabled-input]="readOnly"
    [attr.inputmode]="inputMode"
  />
  }
</ng-template>

@if (icon) {
<div class="with-icon position-relative">
  <span class="icon">
    <mat-icon [svgIcon]="icon" [ngStyle]="iconSize"></mat-icon>
  </span>
  <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
</div>
} @if (!isInputMask && !icon) {
<ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
}
