import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetSelectedFee, ResetSelectedFeeGroup } from '../../../actions/fee-management.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { redirectToPage } from '../cta-button-actions.utils';
export function removeFeeGroupAction(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const getFeeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);
  const selectedGroup = getFeeManagementState.selectedFeeGroupData;
  if (selectedGroup?.id) {
    feeManagementService
      .removeFeeGroup(selectedGroup.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Fee Group removed successfully');
            store.dispatch(new ResetSelectedFeeGroup());
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}

export function removeFeeAction(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const getFeeManagementState = store.selectSnapshot(FeeManagementState.getFeeManagement);
  const selectedFeeData = getFeeManagementState.selectedFeeData;
  if (selectedFeeData?.id) {
    feeManagementService
      .removeFee(selectedFeeData?.id)
      .pipe(
        tap((response) => {
          if (response) {
            alertHandlerService.showAlertFn('success', 'Fee removed successfully');
            store.dispatch(new ResetSelectedFee());
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'An unexpected error occurred, please try again');
          }
        }),
      )
      .subscribe();
  }
}
