import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FormActionState } from '../../../state/form-submision.state';

export function subscribeToAddEditFeeFormStateDataToHandleBtnValidation(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  attri?: UiZoneWidgetAttributeConfig,
  buttonType?: string,
) {
  const data = store.select(FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EDIT_FEE));

  return data.subscribe({
    next: (res) => {
      const key = attri?.['buttonActionType'] as string;
      const value =
        buttonType === 'ad-hoc'
          ? !!res?.formData?.['isCurrencyFeeFormValid']
          : !!(res?.formData?.['isCurrencyFeeFormValid'] && res?.formData?.['isFeeGroupFormValid']);
      ctaButtonSignalService.setSignal({
        [key]: value ?? false,
      });
    },
  });
}
