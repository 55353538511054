import { AddEmployeeRequestType, EmployeeTripType } from '@finxone-platform/shared/sys-config-types';

export class GetTripListByEmployeeId {
  constructor(public payload: { offset: number; limit: number }) {}
  static readonly type = '[Crud Entity] GetTripListByEmployeeId';
}

export class GetTotalLinkedTripToEmployee {
  static readonly type = '[Crud Entity] GetTotalLinkedTripToEmployee';
}

export class AddTripEmployeeMockData {
  static readonly type = '[Crud Entity] AddTripEmployeeMockData';
  constructor(
    public payload: {
      selectedEmployeeDetails: AddEmployeeRequestType;
      nextTripAt: string;
      totalLinkedTrip: number;
      trip: EmployeeTripType[];
    },
  ) {}
}

export class UpdateSelectedEmployee {
  static readonly type = '[Crud Entity] UpdateSelectedEmployee';
  constructor(public employeeId: string) {}
}

export class GetEmployeeDetailsById {
  static readonly type = '[Crud Entity] GetEmployeeDetailsById';
  constructor(public employeeId: string) {}
}

export class GetNextTripDetails {
  static readonly type = '[Crud Entity] GetNextTripDetails';
}

export class RemoveEmployeeFromTrip {
  static readonly type = '[Crud Entity] RemoveEmployeeFromTrip';
  constructor(public employeeTripId: string) {}
}

export class GetTripList {
  static readonly type = '[Crud Entity] GetTripList';
}

export class AddEmployeeToTripAction {
  static readonly type = '[Crud Entity] AddEmployeeToTripAction';
  constructor(public employeeTripId: string) {}
}

export class AddEmployeeToTripSuccessAction {
  static readonly type = '[Crud Entity] AddEmployeeToTripSuccessAction';
}
