import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { baseCssConfigStyle, WidgetStyle } from '@finxone-platform/shared/sys-config-types';

// Inspired by: https://www.lucaspaganini.com/academy/angular-control-value-accessor-custom-form-components#control-value-accessor
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'finx-input',
  templateUrl: './finx-input.component.html',
  styleUrls: ['./finx-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinxInputComponent),
      multi: true,
    },
  ],
})
export class FinxInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  isFocused = false;
  @Input() className = '';
  @Input() name = '';
  @Input() label = '';
  @Input() required = false;
  @Input() type = 'text';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value: any = '';
  @Input() placeholder = '';
  @Input() maxLength = 1000;
  @Input() visible = true;
  @Input() error = '';
  @Input() readOnly = false;
  @Input() checked = false;
  @Input() icon = '';
  @Input() iconSize: baseCssConfigStyle;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() textColor: WidgetStyle;
  @Input() labelAsPlaceholder?: boolean;
  @Output() valueChange = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEnterClickvalueChange = new EventEmitter<string>();
  @Output() errorChange = new EventEmitter<string>();
  @Input() colorTextAlignStyle: WidgetStyle;
  @Input() textInputFontStyle: WidgetStyle;
  @Input() textInputBackgroundColor: WidgetStyle;
  @Input() isSortCode = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() formGroup: FormGroup<any> = new FormGroup<any>({});
  @Input() formControlName: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public formControl: FormControl<any>;
  public readonly valueAccessorFormControl = new FormControl();

  @Input() isInputMask = false;
  @Input() mask = '';
  @Input() inputStyle: WidgetStyle = {};
  @Input() inputMode = 'text';
  @Input() isCommaDirective = false;
  @Output() focusInput = new EventEmitter<string>();
  @Output() focusEvent = new EventEmitter<boolean>();
  public combinedInputStyles: WidgetStyle = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() checkboxValueChanges = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelChange: Function = () => {};

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
  onModelTouched: Function = () => {};

  constructor(public el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.combinedInputStyles = Object.assign(
      this.textInputBackgroundColor || {},
      this.textColor || {},
      this.textInputFontStyle || {},
      this.inputStyle || {},
    );
    this.valueAccessorFormControl.valueChanges.subscribe((inputString) => {
      this.onModelChange(inputString);
    });
    this.formControl = this.getFormControl();
  }

  private getFormControl() {
    if (this.formGroup.get(this.formControlName)) {
      return this.formGroup.get(this.formControlName) as FormControl;
    }
    return this.valueAccessorFormControl;
  }

  ngOnChanges(): void {
    this.combinedInputStyles = Object.assign(
      this.textInputBackgroundColor || {},
      this.textColor || {},
      this.textInputFontStyle || {},
      this.inputStyle || {},
    );
  }

  writeValue(value: string): void {
    value = value ?? '';
    this.valueAccessorFormControl.setValue(value);
    this.cd.markForCheck();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.valueAccessorFormControl.disable();
    } else {
      this.valueAccessorFormControl.enable();
    }
    this.cd.markForCheck();
  }

  onKeypress = () => {
    this.error = '';
  };

  onFocus = () => {
    this.isFocused = true;
    this.focusInput.emit(this.value);
    this.focusEvent.emit(true);
    this.onModelTouched(this.value);
  };

  onBlur = () => {
    this.isFocused = false;
    this.focusEvent.emit(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange = (event: any) => {
    this.value = event.target.value;
    this.valueChange.emit(this.value ?? '');
    this.checkboxValueChanges.emit({
      checked: event.target.checked,
      value: this.value ?? '',
    });

    this.onModelChange(this.value);

    this.error = '';
    this.errorChange.emit('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyup = (event: any) => {
    this.value = event.target.value;
    this.valueChange.emit(this.value ?? '');
    this.onModelChange(this.value);

    this.error = '';
    this.errorChange.emit('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEnterClick = (event: any) => {
    this.value = event.target.value;
    if (event.key == 'Enter') {
      this.onEnterClickvalueChange.emit(this.value ?? '');
    }

    this.onModelChange(this.value);

    this.error = '';
    this.errorChange.emit('');
  };

  onNgModelChange = () => {
    this.valueChange.emit(this.value ?? '');
    this.onModelChange(this.value);

    this.error = '';
    this.errorChange.emit('');
  };
}
