import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { removeStyleAndScriptTags } from '@app/finxone-web-frontend/app/lib/utils/remove-style-script-tags.util';

@Pipe({ name: 'safeHtml', pure: true })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  transform(value: string, removeTag = true): SafeHtml {
    // Remove <style> and <script> tags from the HTML content
    const sanitizedValue = removeTag ? removeStyleAndScriptTags(value) : value;

    // Use bypassSecurityTrustHtml on the modified HTML
    return this.sanitized.bypassSecurityTrustHtml(sanitizedValue);
  }
}
