import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetImageAction } from '../actions/image-submission.action';
export type ImageSubmitActionType = {
  useAsNext: boolean;
};
export interface ImageActionStateModel {
  response: ImageSubmitActionType;
}

const initialImageState: ImageActionStateModel = {
  response: {
    useAsNext: false,
  },
};

@State<ImageActionStateModel>({
  name: 'ImageActionState',
  defaults: initialImageState,
})
@Injectable()
export class ImageActionState {
  @Selector()
  static getImageActionState(state: ImageActionStateModel) {
    return state;
  }
  @Action(SetImageAction)
  SetImageAction(ctx: StateContext<ImageActionStateModel>, action: SetImageAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      response: {
        ...state.response,
        ...action.payload,
      },
    });
  }
}
