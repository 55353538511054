import { AddBeneficiaryRequest } from '../services/account-service/account.type';

export class SetAddBeneficiaryFormState {
  static readonly type = '[AddBeneficiaryForm] SetAddBeneficiaryFormState';
  constructor(public payload: AddBeneficiaryRequest) {}
}

export class UpdateAddBeneficiaryFormName {
  static readonly type = '[AddBeneficiaryForm] UpdateAddBeneficiaryFormName';
  constructor(public payload: string) {}
}
