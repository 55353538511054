import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, firstValueFrom, map, tap } from 'rxjs';
import {
  AddUpdateCustomQuestionAnswerAction,
  DeleteCustomQuestionAnswerAction,
  GetCustomQuestionAnswerDetailsAction,
  GetCustomQuestionAnswerDetailsActionSuccess,
  GetCustomQuestionDetailsAction,
} from '../actions/add-custom-qestion.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import {
  AddCustomQuestionAnswersGQL,
  DeleteCustomQuestionAnswerGQL,
  GetCustomQuestionAnswersDetailsGQL,
  GetCustomQuestionDetailsGQL,
  UpdateCustomQuestionAnswersGQL,
} from '../custom-data-models/travel-management/generated';
import { ProfileState } from './user-profile.state';

export interface CustomQuestionStateWorkflowModel {
  entityCustomQuestionId: string;
  answerListData: {
    id: string;
    value: string;
  }[];
}

@State<CustomQuestionStateWorkflowModel>({
  name: 'customQuestion',
  defaults: {
    entityCustomQuestionId: '',
    answerListData: [],
  },
})
@Injectable()
export class CustomQuestionState {
  constructor(
    private store: Store,
    public getCustomQuestionDetailsGQL: GetCustomQuestionDetailsGQL,
    public addCustomQuestionAnswersGQL: AddCustomQuestionAnswersGQL,
    private getCustomQuestionAnswersDetailsGQL: GetCustomQuestionAnswersDetailsGQL,
    private updateCustomQuestionAnswersGQL: UpdateCustomQuestionAnswersGQL,
    private deleteCustomQuestionAnswerGQL: DeleteCustomQuestionAnswerGQL,
  ) {}

  @Action(GetCustomQuestionDetailsAction)
  fetchCustomQuestionDetails(
    ctx: StateContext<CustomQuestionStateWorkflowModel>,
    action: GetCustomQuestionDetailsAction,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCustomQuestionDetailsAction' }));

    return this.getCustomQuestionDetailsGQL
      .fetch({
        _customQuestionUniqueId: action.where,
      })
      .pipe(
        map((result) => {
          ctx.setState({
            ...ctx.getState(),
            entityCustomQuestionId:
              result?.data.entity_fixed_custom_question?.[
                result?.data.entity_fixed_custom_question?.length - 1
              ]?.id,
          });
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetCustomQuestionDetailsAction',
            }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetCustomQuestionDetailsAction',
            }),
          );
          throw _err;
        }),
      );
  }

  @Action(AddUpdateCustomQuestionAnswerAction)
  async addCustomQuestionAnswer(
    ctx: StateContext<CustomQuestionStateWorkflowModel>,
    request: AddUpdateCustomQuestionAnswerAction,
  ) {
    ctx.dispatch(
      new AddProgressBarStack({
        uniqueId: 'AddUpdateCustomQuestionAnswerAction',
      }),
    );
    const { id: userId } = this.store.selectSnapshot(ProfileState.getProfile);

    const result = await this.onGetCustomQuestionDetails(request.payload.customQuestionUniqueId);

    if (!result?.data.entity_fixed_custom_question?.length) {
      return;
    }

    const customQuestionArray = result?.data.entity_fixed_custom_question;
    const customQuestion = customQuestionArray?.[customQuestionArray?.length - 1];

    const answerListData = await this.onGetCustomQuestionAnswersDetails(customQuestion?.id, userId as string);

    let obs: Observable<unknown>;

    if (this.isCheckboxValue(customQuestion.question_type)) {
      const isValueExist = answerListData?.some((item) => item.value === request.payload.value);

      const valueNotExistInDB = answerListData?.filter(
        (item) => request.payload.checkboxValues?.indexOf(item.value) === -1,
      );

      if (valueNotExistInDB?.length) {
        valueNotExistInDB.map(async (item) => {
          await firstValueFrom(
            this.deleteCustomQuestionAnswerGQL.mutate({
              _id: item.id,
            }),
          );
          return item;
        });
      }

      if (isValueExist) {
        obs = this.updateCustomQuestionAnswer(answerListData, request);
      } else {
        obs = this.addCustomQuestionAnswerFunction(customQuestion, request, userId as string);
      }
    } else if (answerListData?.length) {
      obs = this.updateCustomQuestionAnswer(answerListData, request);
    } else {
      obs = this.addCustomQuestionAnswerFunction(customQuestion, request, userId as string);
    }

    return obs?.pipe(
      tap(() => {
        ctx.patchState({
          ...ctx.getState(),
          answerListData: [],
        });
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'AddUpdateCustomQuestionAnswerAction',
          }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.patchState({
          ...ctx.getState(),
          answerListData: [],
        });
        ctx.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'AddUpdateCustomQuestionAnswerAction',
          }),
        );
        throw _err;
      }),
    );
  }

  private addCustomQuestionAnswerFunction(
    customQuestion: {
      id: string;
      question_type: string;
    },
    request: AddUpdateCustomQuestionAnswerAction,
    userId: string,
  ) {
    return this.addCustomQuestionAnswersGQL.mutate({
      value: request.payload.value,
      entity_custom_question_id: customQuestion?.id,
      user_id: userId,
    });
  }

  private updateCustomQuestionAnswer(
    answerListData: {
      id: string;
      value: string;
    }[],
    request: AddUpdateCustomQuestionAnswerAction,
  ) {
    const entityFixedCustomQuestionId = answerListData[answerListData?.length - 1]?.id;
    return this.updateCustomQuestionAnswersGQL.mutate({
      value: request.payload.value,
      _id: entityFixedCustomQuestionId,
    });
  }

  @Action(GetCustomQuestionAnswerDetailsAction)
  async fetchCustomQuestionAnswerDetails(
    ctx: StateContext<CustomQuestionStateWorkflowModel>,
    request: GetCustomQuestionAnswerDetailsAction,
  ) {
    const { id: userId } = this.store.selectSnapshot(ProfileState.getProfile);

    const result = await firstValueFrom(
      this.getCustomQuestionDetailsGQL.fetch({
        _customQuestionUniqueId: request.payload.customQuestionUniqueId,
      }),
    );

    if (!result?.data.entity_fixed_custom_question?.length) {
      return;
    }

    const customQuestionArray = result?.data.entity_fixed_custom_question;

    const customQuestion = customQuestionArray?.[customQuestionArray?.length - 1];

    ctx.dispatch(
      new AddProgressBarStack({
        uniqueId: 'GetCustomQuestionAnswerDetailsAction',
      }),
    );

    return this.getCustomQuestionAnswersDetailsGQL
      .fetch({
        _entity_custom_question_id: customQuestion?.id,
        _user_id: userId,
      })
      .pipe(
        map(({ data: { entity_fixed_custom_question_answer: answerListData } }) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetCustomQuestionAnswerDetailsAction',
            }),
          );
          const entityFixedCustomQuestion = answerListData?.[answerListData?.length - 1];

          if (entityFixedCustomQuestion && request.payload.isSucessCallbackRequired) {
            if (
              customQuestion.question_type === 'multicheckbox' ||
              customQuestion.question_type === 'checkbox'
            ) {
              answerListData.forEach((item) => {
                ctx.dispatch(
                  new GetCustomQuestionAnswerDetailsActionSuccess({
                    customQuestionUniqueId: request.payload.customQuestionUniqueId,
                    value: item.value,
                  }),
                );
              });
            } else {
              ctx.dispatch(
                new GetCustomQuestionAnswerDetailsActionSuccess({
                  customQuestionUniqueId: request.payload.customQuestionUniqueId,
                  value: entityFixedCustomQuestion.value,
                }),
              );
            }
          } else {
            ctx.patchState({
              ...ctx.getState(),
              answerListData,
            });
          }
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({
              uniqueId: 'GetCustomQuestionAnswerDetailsAction',
            }),
          );
          throw _err;
        }),
      );
  }

  private async onGetCustomQuestionDetails(customQuestionUniqueId: string) {
    const result = await firstValueFrom(
      this.getCustomQuestionDetailsGQL.fetch({
        _customQuestionUniqueId: customQuestionUniqueId,
      }),
    );
    return result;
  }

  private async onGetCustomQuestionAnswersDetails(customQuestionId: string, userId: string) {
    const {
      data: { entity_fixed_custom_question_answer: answerListData },
    } = await firstValueFrom(
      this.getCustomQuestionAnswersDetailsGQL.fetch({
        _entity_custom_question_id: customQuestionId,
        _user_id: userId,
      }),
    );

    return answerListData;
  }

  isCheckboxValue = (questionType: string) => questionType === 'multicheckbox' || questionType === 'checkbox';

  @Action(DeleteCustomQuestionAnswerAction)
  async deleteCustomQuestionAnswerDetails(
    ctx: StateContext<CustomQuestionStateWorkflowModel>,
    request: DeleteCustomQuestionAnswerAction,
  ) {
    const { id: userId } = this.store.selectSnapshot(ProfileState.getProfile);

    const result = await this.onGetCustomQuestionDetails(request.payload.customQuestionUniqueId);

    if (!result?.data.entity_fixed_custom_question?.length) {
      return;
    }

    const customQuestionArray = result?.data.entity_fixed_custom_question;
    const customQuestion = customQuestionArray?.[customQuestionArray?.length - 1];

    const answerListData = await this.onGetCustomQuestionAnswersDetails(customQuestion?.id, userId as string);

    if (this.isCheckboxValue(customQuestion.question_type)) {
      answerListData.map(async (item) => {
        await firstValueFrom(
          this.deleteCustomQuestionAnswerGQL.mutate({
            _id: item.id,
          }),
        );
        return item;
      });
    }
  }
}
