import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';

import { FormActionState } from '../../../state/form-submision.state';
import { PaymentRequestUpdateRequest } from '../../../services/account-service/account.type';
import { NotificationState } from '../../../state/notifications.state';
import { UpdatePaymentRequest, UpdatePaymentRequestFailed } from '../../../actions/payment-request.action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { map, take } from 'rxjs';

export function paymentRequestReject(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const formState = store.selectSnapshot(FormActionState.getFormActionState);

  if (formState?.response?.formData) {
    const paymentRequestSummary = store.selectSnapshot(
      NotificationState.getNotification,
    ).paymentRequestSummary;

    if (paymentRequestSummary) {
      const updatePayload: PaymentRequestUpdateRequest = {
        id: paymentRequestSummary.id,
        approvedStatus: 'rejected',
      };
      store.dispatch(new UpdatePaymentRequest(updatePayload));

      action
        .pipe(
          ofActionDispatched(UpdatePaymentRequestFailed),
          take(1),
          map(() => {
            router.navigateByUrl(`/zones/profile/notification-centre`, {
              replaceUrl: true,
            });
          }),
        )
        .subscribe();
    }

    router.navigateByUrl(`/zones/payments/payment-request-failed`, {
      replaceUrl: true,
    });
  } else {
    alertHandlerService.showAlertFn('error', 'FormState data not found.');
    throw Error('FormState data not found.');
  }
}
