export function nthLetter(str: string, nth: number): string {
  return str.charAt(nth);
}

export function truncate(string: string, length: number) {
  if (length <= 0) return string;
  return string.length > length ? string.slice(0, length) : string;
}

export function addComma(string?: string) {
  if (string) {
    return string + ', ';
  }
  return string;
}

export function addDashesToSortCode(inputString: string): string {
  // Add dashes every two numbers
  return inputString?.replace(/(\d{2})(?=\d)/g, '$1-');
}
