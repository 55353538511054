import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CardInfoType,
  CardSortType,
  PaymentProviderType,
  ProfileStateModel,
  UiZoneWidgetAttributeConfig,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Buffer } from 'buffer';

@Component({
  template: '',
})
export abstract class CardAdapterParentComponent<ProviderType = PaymentProviderType> {
  @Input() public paymentGatewayConfig: {
    payment: ProviderType;
    sandboxMode?: string;
  };
  @Input() public text = '';
  @Input() public cardStyle: { [key: string]: string };
  @Input() public cardClass: { [key: string]: boolean };
  @Output() public clicked = new EventEmitter<void>();

  @Input() virtualCardData: CardInfoType;
  @Input() cardConfigData: { [key: string]: boolean } = {};
  @Input() isFreezeCard: boolean;
  @Input() isCardAvailable: boolean;

  private _isCardFlipped: boolean;

  @Input() set isCardFlipped(value: boolean) {
    this._isCardFlipped = value;
  }

  get isCardFlipped() {
    return this._isCardFlipped;
  }

  @Input() isCardActivation: boolean;
  @Input() borderStyle: baseCssConfigStyle;
  @Input() borderStyleBlackStripe: baseCssConfigStyle;
  @Input() attri: UiZoneWidgetAttributeConfig;
  @Input() pageType: string;
  @Input() userProfileDetail: ProfileStateModel;

  public onClick() {
    this.clicked.emit();
  }

  private get cardMapped() {
    return {
      apiKeyId: 'API_KEY_ID',
      apiKey: 'API_KEY',
      cardId: this.isCardInfoType(this.virtualCardData) ? this.virtualCardData.id : '',
      uniqueToken: `001#${this.isCardInfoType(this.virtualCardData) ? this.virtualCardData.id : ''}`,
      fieldNames: this.cardMappedFieldNamesString,
      loader: 'bars',
      class: 'if-card',
      styleUrl: 'http://localhost:4200/assets/IF-card.component.css',
    };
  }

  private get cardMappedFieldNamesString() {
    return JSON.stringify({
      pan: this.isCardInfoType(this.virtualCardData) ? this.virtualCardData.maskedPan : '',
      exp: this.isCardInfoType(this.virtualCardData) ? this.virtualCardData.expiry : '',
      cvv: 'CVV:',
      details: 'Show Card Details:',
      clickToCopyText: 'Click to copy',
      clickToCopyCopied: 'Copied to clipboard!',
    });
  }

  get cardBase64() {
    return Buffer.from(JSON.stringify(this.cardMapped)).toString('base64');
  }

  get physicalCardColor(): string {
    if (this.attri['physicalCardColor']) return this.attri['physicalCardColor'];
    else return '#02AEB9';
  }

  get virtualCardColor(): string {
    if (this.attri['virtualCardColor']) return this.attri['virtualCardColor'];
    else return '#0788F5';
  }

  get showCardHolder(): boolean {
    if (this.attri['showCardHolder'] != null) return this.attri['showCardHolder'];
    else return true;
  }

  get showExpiry(): boolean {
    if (this.attri['showExpiry'] != null) return this.attri['showExpiry'];
    else return true;
  }

  get leftTopPlaceholderText(): string {
    if (this.attri['leftTopPlaceholderText']) return this.attri['leftTopPlaceholderText'];
    else return '';
  }

  get prefixLast4PanText(): string {
    if (this.attri['prefixLast4PanText']) return this.attri['prefixLast4PanText'];
    else return '**** ';
  }

  get addCardOption(): string {
    if (this.attri['addCardOption']) return this.attri['addCardOption'];
    else return 'above';
  }

  get cardSortBy(): string {
    if (this.attri['cardSortBy']) return this.attri['cardSortBy'];
    else return CardSortType.UNORDERED;
  }
  get cardIFrameUrl() {
    return `https://public.test.meawallet.com/easylaunch?var=0.15666&data=${this.cardBase64}`;
  }

  private isCardInfoType(data: CardInfoType): data is CardInfoType {
    return data.maskedPan !== undefined;
  }
}
