import { v4 as secure } from '@lukeed/uuid/secure';

export class InvalidUuidError extends Error {
  constructor(m?: string) {
    super(m || 'Error: invalid UUID !');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvalidUuidError.prototype);
  }
}

export class UUID {
  protected m_str: string;

  constructor(str?: string) {
    this.m_str = str || UUID.newUuid().toString();

    const reg = new RegExp('[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}', 'i');
    if (!reg.test(this.m_str)) throw new InvalidUuidError();
  }

  toString() {
    return this.m_str;
  }

  public static newUuid(): UUID {
    const uuid = secure();
    return new UUID(uuid);
  }
}
