import {
  FormActionState,
  FormActionStateModel,
  FormActionType,
} from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { BaseComponent } from '../base/base.component';

export abstract class FormSubmitter extends BaseComponent {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @Select(FormActionState.getFormActionState)
  formAction!: Observable<FormActionStateModel>;
  @Select(FormActionState.getFormActionStateWithId)
  formActionWithId!: Observable<FormActionStateModel>;
  constructor(protected store: Store) {
    super();
  }
  subscribeToFormAction(id?: string): Observable<FormActionType | null> {
    if (id) {
      return this.store.select(FormActionState.getFormActionStateWithId(id)).pipe(
        takeUntil(this.destroy$),
        map((response) => {
          return response;
        }),
      );
    } else {
      return this.formAction.pipe(
        takeUntil(this.destroy$),
        map((response) => {
          return response?.response;
        }),
      );
    }
  }
  getFormActionDataWithId(id: string): Observable<Record<string, any> | null> {
    return this.formAction.pipe(
      takeUntil(this.destroy$),
      map((response) => {
        return response?.[id];
      }),
    );
  }
}
