import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FeeManagementState } from '../../../state/fee-management.state';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function handleUpdateClientFeeGroupRequest(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const formData = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData;
  const clientDetails = store.selectSnapshot(FeeManagementState.getFeeManagement)?.selectedClientDetails;
  if (formData && clientDetails) {
    feeManagementService
      .updateClientFeeGroupRequest(formData?.feeGroupId, clientDetails?.id)
      .pipe(
        tap((res) => {
          if (res) {
            alertHandlerService.showAlertFn('success', 'Client fee group updated successfully');
            store.dispatch(new ResetFormDataAction());
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn('error', 'Client fee group updated failed, please try again');
          }
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn('error', 'Details not available, please try again');
  }
}
