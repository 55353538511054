import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';
import { GetOrganisation } from '../actions/organisation.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

export interface OrganisationStateModel {
  id: string;
  name: string;
  address: {
    country: string;
    state: string;
    city: string;
    street: string;
    postalCode: string;
  };
  identificationNumber: string;
  incorporationDate: string;
}
@State<OrganisationStateModel>({
  name: 'organisation',
  defaults: {
    id: '',
    name: '',
    address: {
      country: '',
      state: '',
      city: '',
      street: '',
      postalCode: '',
    },
    identificationNumber: '',
    incorporationDate: '',
  },
})
@Injectable()
export class OrganisationState {
  constructor(private metadataService: MetadataService) {}

  @Selector()
  static getOrganisation(state: OrganisationStateModel) {
    return state;
  }
  @Action(GetOrganisation)
  fetchOrganisation(ctx: StateContext<OrganisationStateModel>, action: GetOrganisation) {
    // patch state with query values first
    ctx.patchState({
      id: action.organisationId,
    });
    return this.metadataService.getOrganisation(action.organisationId).pipe(
      map((response) => {
        // Update state with the response data
        ctx.patchState({
          id: response.id,
          name: response.name,
          address: {
            country: response.address.country,
            state: response.address.state,
            city: response.address.city,
            street: response.address.street,
            postalCode: response.address.postalCode,
          },
          identificationNumber: response.identificationNumber,
          incorporationDate: response.incorporationDate,
        });
      }),
      catchError((error) => {
        console.error('Error fetching organisation:', error);
        return of(null);
      }),
    );
  }
}
