import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  BaseCssProperties,
  ProviderClass,
  baseCssConfigStyle,
  buttonNestedWidgetConfigStyles,
} from '@finxone-platform/shared/sys-config-types';

import { Subject } from 'rxjs';
import { BaseComponent } from '../base/base.component';
export interface AccountRequirements {
  fullName?: {
    requirement: string;
  };
  firstName?: {
    requirement: string;
  };
  lastName?: {
    requirement: string;
  };
  businessName?: {
    requirement: string;
  };
  accountNumber?: {
    regex?: RegExp;
    requirement: string;
  };
  bankName?: {
    regex?: RegExp;
    requirement: string;
  };
  iban?: {
    regex?: RegExp;
    requirement: string;
  };
}

@Component({
  selector: 'finxone-web-frontend-international-beneficiary-credentials-widget',
  templateUrl: './international-beneficiary-credentials-widget.component.html',
})
export class InternationalBeneficiaryCredentialsWidgetComponent extends BaseComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();

  public addressRequired: string;
  public recipientName: string;
  public firstName: string;
  public lastName: string;
  public beneficiaryType: string;
  public continueButtonContent: string = 'Continue';
  public previousButtonContent: string;
  public nextButtonContent: string;
  public continueButtonStyle: baseCssConfigStyle = {};
  public previousButtonStyle: baseCssConfigStyle = {};
  public nextButtonStyle: baseCssConfigStyle = {};

  public inputStyle: baseCssConfigStyle;
  public inputBaseStyle: baseCssConfigStyle;
  public inputInactiveStyle: baseCssConfigStyle;
  public textInputFontStyle: baseCssConfigStyle;

  constructor(public dataIn: ProviderClass<any>, private configService: ConfigService) {
    super();
    this.recipientName = 'name';
    this.firstName = 'firstName';
    this.lastName = 'lastName';
  }
  ngOnInit(): void {
    this.beneficiaryType = this.dataIn.attri.widgetProperties['beneficiaryType'];
    if (this.dataIn.widgetConfig?.nestedWidgets?.['continueButton']) {
      this.continueButtonContent =
        this.dataIn.widgetConfig?.nestedWidgets?.['continueButton']?.widgetProperties?.textContent ??
        'Continue';

      this.continueButtonStyle = this.configService.getSpecificBaseCssStyle(
        this.getNestedWidgetConfig(this.dataIn, 'continueButton'),
        buttonNestedWidgetConfigStyles,
      );
    } else {
      this.continueButtonStyle = {};
    }

    if (this.dataIn.widgetConfig?.nestedWidgets?.['previousButton']) {
      this.previousButtonContent =
        this.dataIn.widgetConfig?.nestedWidgets?.['previousButton']?.widgetProperties?.textContent ??
        'Previous';

      this.previousButtonStyle = this.configService.getSpecificBaseCssStyle(
        this.getNestedWidgetConfig(this.dataIn, 'previousButton'),
        buttonNestedWidgetConfigStyles,
      );
    } else {
      this.previousButtonStyle = {};
    }

    if (this.dataIn.widgetConfig?.nestedWidgets?.['nextButton']) {
      this.nextButtonContent =
        this.dataIn.widgetConfig?.nestedWidgets?.['nextButton']?.widgetProperties?.textContent ?? 'Next';

      this.nextButtonStyle = this.configService.getSpecificBaseCssStyle(
        this.getNestedWidgetConfig(this.dataIn, 'nextButton'),
        buttonNestedWidgetConfigStyles,
      );
    } else {
      this.nextButtonStyle = {};
    }

    const { baseStyle, activeStyle, inactiveStyle } = this.configService.getInputFieldCssStyles(
      this.dataIn.attri.widgetProperties,
    );

    this.inputStyle = baseStyle;
    this.inputBaseStyle = { ...activeStyle };
    this.inputInactiveStyle = inactiveStyle;

    this.contentStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.PADDING,
      BaseCssProperties.BACKGROUND_COLOR,
      BaseCssProperties.MARGIN,
      BaseCssProperties.BORDER,
      BaseCssProperties.BOX_SHADOW,
      BaseCssProperties.COLOR,
      BaseCssProperties.TEXT_FONT_STYLE,
      BaseCssProperties.FONT_SIZE,
      BaseCssProperties.TEXTALIGN,
    ]);
    this.textInputFontStyle = this.configService.getSpecificBaseCssStyle(this.dataIn.attri, [
      BaseCssProperties.TEXT_INPUT_FONT_SIZE,
    ]);
  }

  get widgetProperties() {
    return this.dataIn.attri.widgetProperties;
  }
}
