<div
  appAccountDropdown
  (selectionChanged)="onAccountSelected($event)"
  (dropdownOpened)="onToggleDropdown($event)"
  [dropdownListing]="dropdownListing"
  [dropdownTemplate]="dropdownTemplate"
  [showFlag]="showFlag"
  [ngStyle]="currentInputStyle"
  (scrolled)="handleChildScroll()"
  class="d-flex h-100 accounts-context-dropdown is-space-between position-relative"
>
  <div class="d-flex align-items-center">
    <mat-icon
      [ngStyle]="leftIconStyle"
      class="left mr-2"
      *ngIf="leftIcon"
      svgIcon="{{ leftIcon }}"
    ></mat-icon>
    <div
      class="d-flex mr-2"
      *ngIf="selectedAccount && showFlag"
      [innerHTML]="getCountryFlag(selectedAccount?.currencyCode) | safeHtml"
    ></div>
    <div *ngIf="!selectedAccount">{{ textContent }}</div>
    <div *ngIf="selectedAccount" [innerHTML]="renderedContent | safeHtml"></div>
  </div>
  <span class="is-center">
    <mat-icon [ngStyle]="rightIconStyle" class="right" *ngIf="rightIcon" svgIcon="{{ rightIcon }}"></mat-icon>
    <img src="assets/images/arrow-down.svg" alt="arrow" class="icon" />
  </span>
</div>
