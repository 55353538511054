import { RevenirTripType } from '@finxone-platform/shared/sys-config-types';
import { FormSubmissionType } from '../state/form-submision.state';

export class GetRevenirTransactionList {
  static readonly type = '[RevenirState] GetRevenirTransactionList';
  constructor(public tripId: string) {}
}
export class GetRevenirTripList {
  static readonly type = '[RevenirState] GetRevenirTripList';
  constructor(public param?: string) {}
}
export class SetRevenirTrip {
  static readonly type = '[RevenirState] SetRevenirTrip';
  constructor(public selectedTripDetails: RevenirTripType) {}
}

export class GetRevenirTripDetails {
  static readonly type = '[RevenirState] GetRevenirTripDetails';
  constructor(public tripId: string) {}
}

export class GetRevenirTransactionDetails {
  static readonly type = '[RevenirState] GetRevenirTransactionDetails';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionItems {
  static readonly type = '[RevenirState] GetRevenirTransactionItems';
  constructor(public transactionId: string) {}
}

export class GetRevenirTransactionReceipt {
  static readonly type = '[RevenirState] GetRevenirTransactionReceipt';
  constructor(public transactionId: string) {}
}

export class GetRevenirArchivedTrips {
  static readonly type = '[RevenirState] GetRevenirArchivedTrips';
  constructor(public param?: string) {}
}

export class SetArchivedRevenirTrip {
  static readonly type = '[RevenirState] SetArchivedRevenirTrip';
  constructor(public archivedTripDetails: RevenirTripType) {}
}

export class ClearArchivedRevenirTrip {
  static readonly type = '[RevenirState] Clear Archived Trip';
}

export class MakeTripAsArchiveTrip {
  static readonly type = '[RevenirState] MakeTripAsArchiveTrip';
  constructor(public trip: RevenirTripType) {}
}

export class GetRevenirBarcodeImage {
  static readonly type = '[RevenirState] GetRevenirBarcodeImage';
  constructor(public tripId: string) {}
}
export class GetPortList {
  static readonly type = '[RevenirState] GetPortList';
  constructor(public countryCode: string, public type: string) {}
}

export class UploadBoardingPass {
  static readonly type = '[RevenirState] UploadBoardingPass';
  constructor(public payload: FormData) {}
}

export class SetRevenirReviewTransactionReceipt {
  static readonly type = '[RevenirState] SetRevenirReviewTransactionReceipt';
  constructor(public file: File) {}
}

export class UploadRevenirTransactionReceipt {
  static readonly type = '[RevenirState] UploadRevenirTransactionReceipt';
  constructor(public transactionId: string) {}
}

export class GetBoardingPass {
  static readonly type = '[RevenirState] GetBoardingPass';
  constructor(public tripId: string) {}
}

export class CreateRevenirTrip {
  static readonly type = '[RevenirState] CreateRevenirTrip';
  constructor(public payload: FormSubmissionType | null) {}
}

export class CreateRevenirSubjectToken {
  static readonly type = '[RevenirState] CreateRevenirSubjectToken';
}
export class UploadBoardingPassWithId {
  static readonly type = '[RevenirState] UploadBoardingPassWithId';
  constructor(public payload: FormData, public tripId: string) {}
}

export class ResetSelectedTripDetails {
  static readonly type = '[RevenirState] ResetSelectedTripDetails';
}

export class UploadPassport {
  static readonly type = '[RevenirState] UploadPassPort';
  constructor(public blobUrl: string, public userId: string) {}
}
