import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, catchError, map, of } from 'rxjs';
import {
  GetEventByEmployeeIdGQL,
  RemoveEmployeeFromEventGQL,
  RemoveGuestFromEventGQL,
} from '../../custom-data-models/travel-management/generated';
import { EventGuestState } from '../../state/event-guest.state';
@Injectable({
  providedIn: 'root',
})
export class GraphqlServiceService {
  constructor(
    private getEventByEmployeeId: GetEventByEmployeeIdGQL,
    private _removeGuestFromEvent: RemoveGuestFromEventGQL,
    private removeEmployeeFromEventGQL: RemoveEmployeeFromEventGQL,
    private store: Store,
  ) {}

  getUserEvent(userId: string) {
    return this.getEventByEmployeeId.fetch({
      where: { user_id: { _eq: userId } },
    });
  }

  removeGuestFromEvent(id?: string, entityId?: string): Observable<boolean | Observable<boolean>> {
    const idFromState = id ?? this.getGuestIdEventGuestState();
    return this._removeGuestFromEvent.mutate({ id: idFromState, tripId: entityId }).pipe(
      map((response) => {
        if (response.data) return of(true);
        else throw new Error('Failed to remove guest from event.');
      }),
      catchError((error) => {
        console.error('Error removing guest from event:', error);
        return of(false);
      }),
    );
  }

  getGuestIdEventGuestState() {
    return this.store.selectSnapshot(EventGuestState).id;
  }
  removeEmployeeFromEvent(id: string, entityId: string): Observable<boolean | Observable<boolean>> {
    return this.removeEmployeeFromEventGQL.mutate({ id: id, tripId: entityId }).pipe(
      map((response) => {
        if (response.data) return of(true);
        else throw new Error('Failed to remove employee from event.');
      }),
      catchError((error) => {
        console.error('Error removing employee from event:', error);
        return of(false);
      }),
    );
  }
}
