export const currencyCodeToFlag = [
  { country_code: 'AF', currency_code: 'AFN' },
  { country_code: 'AL', currency_code: 'ALL' },
  { country_code: 'DZ', currency_code: 'DZD' },
  { country_code: 'AO', currency_code: 'AOA' },
  { country_code: 'AI', currency_code: 'XCD' },
  { country_code: 'AQ', currency_code: 'XCD' },
  { country_code: 'AG', currency_code: 'XCD' },
  { country_code: 'AR', currency_code: 'ARS' },
  { country_code: 'AM', currency_code: 'AMD' },
  { country_code: 'AW', currency_code: 'AWG' },
  { country_code: 'AU', currency_code: 'AUD' },
  { country_code: 'european_union', currency_code: 'EUR' },
  { country_code: 'AZ', currency_code: 'AZN' },
  { country_code: 'BS', currency_code: 'BSD' },
  { country_code: 'BH', currency_code: 'BHD' },
  { country_code: 'BD', currency_code: 'BDT' },
  { country_code: 'BB', currency_code: 'BBD' },
  { country_code: 'BY', currency_code: 'BYR' },
  { country_code: 'BZ', currency_code: 'BZD' },
  { country_code: 'BJ', currency_code: 'XOF' },
  { country_code: 'BM', currency_code: 'BMD' },
  { country_code: 'BT', currency_code: 'BTN' },
  { country_code: 'BO', currency_code: 'BOB' },
  { country_code: 'BA', currency_code: 'BAM' },
  { country_code: 'BW', currency_code: 'BWP' },
  { country_code: 'BR', currency_code: 'BRL' },
  { country_code: 'BN', currency_code: 'BND' },
  { country_code: 'BG', currency_code: 'BGN' },
  { country_code: 'BF', currency_code: 'XOF' },
  { country_code: 'BI', currency_code: 'BIF' },
  { country_code: 'KH', currency_code: 'KHR' },
  { country_code: 'CM', currency_code: 'XAF' },
  { country_code: 'CA', currency_code: 'CAD' },
  { country_code: 'CV', currency_code: 'CVE' },
  { country_code: 'KY', currency_code: 'KYD' },
  { country_code: 'CF', currency_code: 'XAF' },
  { country_code: 'TD', currency_code: 'XAF' },
  { country_code: 'CL', currency_code: 'CLP' },
  { country_code: 'CN', currency_code: 'CNY' },
  { country_code: 'CN', currency_code: 'CNH' },
  { country_code: 'CX', currency_code: 'AUD' },
  { country_code: 'CC', currency_code: 'AUD' },
  { country_code: 'CO', currency_code: 'COP' },
  { country_code: 'KM', currency_code: 'KMF' },
  { country_code: 'CG', currency_code: 'XAF' },
  { country_code: 'CR', currency_code: 'CRC' },
  { country_code: 'CU', currency_code: 'CUP' },
  { country_code: 'CZ', currency_code: 'CZK' },
  { country_code: 'DK', currency_code: 'DKK' },
  { country_code: 'DJ', currency_code: 'DJF' },
  { country_code: 'DM', currency_code: 'XCD' },
  { country_code: 'DO', currency_code: 'DOP' },
  { country_code: 'EC', currency_code: 'ECS' },
  { country_code: 'EG', currency_code: 'EGP' },
  { country_code: 'SV', currency_code: 'SVC' },
  { country_code: 'GQ', currency_code: 'XAF' },
  { country_code: 'ER', currency_code: 'ERN' },
  { country_code: 'ET', currency_code: 'ETB' },
  { country_code: 'FK', currency_code: 'FKP' },
  { country_code: 'FO', currency_code: 'DKK' },
  { country_code: 'FJ', currency_code: 'FJD' },
  { country_code: 'PF', currency_code: 'XPF' },
  { country_code: 'GA', currency_code: 'XAF' },
  { country_code: 'GM', currency_code: 'GMD' },
  { country_code: 'GE', currency_code: 'GEL' },
  { country_code: 'GH', currency_code: 'GHS' },
  { country_code: 'GI', currency_code: 'GIP' },
  { country_code: 'GL', currency_code: 'DKK' },
  { country_code: 'GD', currency_code: 'XCD' },
  { country_code: 'GT', currency_code: 'QTQ' },
  { country_code: 'GN', currency_code: 'GNF' },
  { country_code: 'GW', currency_code: 'CFA' },
  { country_code: 'GY', currency_code: 'GYD' },
  { country_code: 'HT', currency_code: 'HTG' },
  { country_code: 'HM', currency_code: 'AUD' },
  { country_code: 'HN', currency_code: 'HNL' },
  { country_code: 'HK', currency_code: 'HKD' },
  { country_code: 'HR', currency_code: 'HRK' },
  { country_code: 'HU', currency_code: 'HUF' },
  { country_code: 'IS', currency_code: 'ISK' },
  { country_code: 'IN', currency_code: 'INR' },
  { country_code: 'ID', currency_code: 'IDR' },
  { country_code: 'IR', currency_code: 'IRR' },
  { country_code: 'IQ', currency_code: 'IQD' },
  { country_code: 'IL', currency_code: 'ILS' },
  { country_code: 'CI', currency_code: 'XOF' },
  { country_code: 'JM', currency_code: 'JMD' },
  { country_code: 'JP', currency_code: 'JPY' },
  { country_code: 'JO', currency_code: 'JOD' },
  { country_code: 'KZ', currency_code: 'KZT' },
  { country_code: 'KE', currency_code: 'KES' },
  { country_code: 'KI', currency_code: 'AUD' },
  { country_code: 'KW', currency_code: 'KWD' },
  { country_code: 'KG', currency_code: 'KGS' },
  { country_code: 'LA', currency_code: 'LAK' },
  { country_code: 'LB', currency_code: 'LBP' },
  { country_code: 'LS', currency_code: 'LSL' },
  { country_code: 'LR', currency_code: 'LRD' },
  { country_code: 'LY', currency_code: 'LYD' },
  { country_code: 'MK', currency_code: 'MKD' },
  { country_code: 'MG', currency_code: 'MGF' },
  { country_code: 'MW', currency_code: 'MWK' },
  { country_code: 'MY', currency_code: 'MYR' },
  { country_code: 'MV', currency_code: 'MVR' },
  { country_code: 'ML', currency_code: 'XOF' },
  { country_code: 'MR', currency_code: 'MRO' },
  { country_code: 'MU', currency_code: 'MUR' },
  { country_code: 'MX', currency_code: 'MXN' },
  { country_code: 'MD', currency_code: 'MDL' },
  { country_code: 'MN', currency_code: 'MNT' },
  { country_code: 'MS', currency_code: 'XCD' },
  { country_code: 'MA', currency_code: 'MAD' },
  { country_code: 'MZ', currency_code: 'MZN' },
  { country_code: 'MM', currency_code: 'MMR' },
  { country_code: 'NA', currency_code: 'NAD' },
  { country_code: 'NR', currency_code: 'AUD' },
  { country_code: 'NP', currency_code: 'NPR' },
  { country_code: 'AN', currency_code: 'ANG' },
  { country_code: 'NC', currency_code: 'XPF' },
  { country_code: 'NZ', currency_code: 'NZD' },
  { country_code: 'NI', currency_code: 'NIO' },
  { country_code: 'NE', currency_code: 'XOF' },
  { country_code: 'NG', currency_code: 'NGN' },
  { country_code: 'NF', currency_code: 'AUD' },
  { country_code: 'KP', currency_code: 'KPW' },
  { country_code: 'GB', currency_code: 'GBP' },
  { country_code: 'NO', currency_code: 'NOK' },
  { country_code: 'OM', currency_code: 'OMR' },
  { country_code: 'PK', currency_code: 'PKR' },
  { country_code: 'PA', currency_code: 'PAB' },
  { country_code: 'PG', currency_code: 'PGK' },
  { country_code: 'PY', currency_code: 'PYG' },
  { country_code: 'PE', currency_code: 'PEN' },
  { country_code: 'PH', currency_code: 'PHP' },
  { country_code: 'PL', currency_code: 'PLN' },
  { country_code: 'QA', currency_code: 'QAR' },
  { country_code: 'RO', currency_code: 'RON' },
  { country_code: 'RU', currency_code: 'RUB' },
  { country_code: 'RW', currency_code: 'RWF' },
  { country_code: 'SH', currency_code: 'SHP' },
  { country_code: 'KN', currency_code: 'XCD' },
  { country_code: 'LC', currency_code: 'XCD' },
  { country_code: 'VC', currency_code: 'XCD' },
  { country_code: 'WS', currency_code: 'WST' },
  { country_code: 'ST', currency_code: 'STD' },
  { country_code: 'SA', currency_code: 'SAR' },
  { country_code: 'SN', currency_code: 'XOF' },
  { country_code: 'RS', currency_code: 'RSD' },
  { country_code: 'SC', currency_code: 'SCR' },
  { country_code: 'SL', currency_code: 'SLL' },
  { country_code: 'SG', currency_code: 'SGD' },
  { country_code: 'SB', currency_code: 'SBD' },
  { country_code: 'SO', currency_code: 'SOS' },
  { country_code: 'ZA', currency_code: 'ZAR' },
  { country_code: 'GS', currency_code: 'GBP' },
  { country_code: 'KR', currency_code: 'KRW' },
  { country_code: 'SS', currency_code: 'SSP' },
  { country_code: 'LK', currency_code: 'LKR' },
  { country_code: 'SD', currency_code: 'SDG' },
  { country_code: 'SR', currency_code: 'SRD' },
  { country_code: 'SZ', currency_code: 'SZL' },
  { country_code: 'SE', currency_code: 'SEK' },
  { country_code: 'CH', currency_code: 'CHF' },
  { country_code: 'SY', currency_code: 'SYP' },
  { country_code: 'TJ', currency_code: 'TJS' },
  { country_code: 'TZ', currency_code: 'TZS' },
  { country_code: 'TH', currency_code: 'THB' },
  { country_code: 'CD', currency_code: 'CDF' },
  { country_code: 'TG', currency_code: 'XOF' },
  { country_code: 'TO', currency_code: 'TOP' },
  { country_code: 'TT', currency_code: 'TTD' },
  { country_code: 'TN', currency_code: 'TND' },
  { country_code: 'TR', currency_code: 'TRY' },
  { country_code: 'TM', currency_code: 'TMT' },
  { country_code: 'TV', currency_code: 'AUD' },
  { country_code: 'UG', currency_code: 'UGX' },
  { country_code: 'UA', currency_code: 'UAH' },
  { country_code: 'AE', currency_code: 'AED' },
  { country_code: 'US', currency_code: 'USD' },
  { country_code: 'UY', currency_code: 'UYU' },
  { country_code: 'UZ', currency_code: 'UZS' },
  { country_code: 'VU', currency_code: 'VUV' },
  { country_code: 'VE', currency_code: 'VEF' },
  { country_code: 'VN', currency_code: 'VND' },
  { country_code: 'WF', currency_code: 'XPF' },
  { country_code: 'EH', currency_code: 'MAD' },
  { country_code: 'YE', currency_code: 'YER' },
  { country_code: 'ZM', currency_code: 'ZMW' },
  { country_code: 'ZW', currency_code: 'ZWD' },
];
