export const accountSwitchWidgetVisualTemplate = `
<div>
  <div class="font-weight-normal text-capitalize">{{countryName}} {{currency}}</div>
  {{#if accounts.enableTotalWealth}}
    {{#if accounts.totalWealthCalculating}}
      <span class="typog-xs">Total Wealth: 
        <span class="typog-xs text-italic">Calculating...</span>
      </span>
    {{else}}
      <span class="typog-xs">Total Wealth: {{ currencyPipe accounts.totalWealthValue currencyCode=currency }}</span>
    {{/if}}
  {{else}}
    <span class="typog-xs">Balance: {{ currencyPipe availableBalance currencyCode=currency }}</span>
  {{/if}}
</div>
`;

export const accountSwitchDropdownTemplate = `
    <div>
        <div class="font-weight-semi">{{currencyCode}}</div>
        <div class="typog-xs">{{currencyName}} | {{currencyCode}}</div>
    </div>
    <div class="position-absolute mr-2" style="right: 0">
      <h4 class="font-weight-semi">{{totalBalance}}</h4>
    </div>
`;
