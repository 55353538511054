import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BulkPaymentResultList, PaginatedResponse } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import { GetBulkPaymentList } from '../actions/bulk-payment.action';
import { AccountService } from '../services/account-service/account-service.service';
@State<PaginatedResponse<BulkPaymentResultList>[]>({
  name: 'bulkPaymentList',
  defaults: [],
})
@Injectable()
export class BulkPaymentListState {
  constructor(private accountService: AccountService, private router: Router) {}

  @Selector()
  static getBulkPaymentList(state: PaginatedResponse<BulkPaymentResultList>) {
    return state;
  }
  @Action(GetBulkPaymentList)
  fetchUsers(ctx: StateContext<PaginatedResponse<BulkPaymentResultList>>, action: GetBulkPaymentList) {
    try {
      return this.accountService
        .getBulkPaymentById(action.page, action.limit, action.masterId, action.searchValue)
        .pipe(
          tap((listData: PaginatedResponse<BulkPaymentResultList>) => {
            ctx.setState(listData);
          }),
        );
    } catch (err) {
      return throwError(() => err);
    }
  }
  @Selector()
  static getBulkPaymentSummary(state: PaginatedResponse<BulkPaymentResultList>) {
    return state?.meta?.bulkPaymentsSummary;
  }
}
