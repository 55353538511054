export const confirmBtnTemplate = `Confirm`;

export const beneficiaryDetailsTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label">Full Name</div>
    <div class="ben-value">{{ beneficiaryDetails.name }}</div>
</div>
<hr class="my-4 mx-1">
<div class="bank-info">
    <div class='is-vertical-align' style='padding-right: 66px; justify-content: space-between;'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Sort Code</div>
            <div class="ben-value">{{ beneficiaryDetails.sortCode}}</div>
        </div>
    </div>
    <div class='is-vertical-align align-left'>
        <div class="ben-label" >Account Number</div>
        <div class="ben-value">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
</div>
</div>`;

export const suggestedBankBeneficiaryDetailsTemplate = `<div class="w-100 h-100">
<div class='is-vertical-align' style='align-items: flex-start;'>
    <div class="ben-label">Full Name</div>
    <div class="ben-value">{{ beneficiaryDetails.name }}</div>
</div>
<hr class="my-4 mx-1">
<div class="bank-info">
    <div class='is-vertical-align' style='padding-right: 66px; justify-content: space-between;'>
        <div class='is-vertical-align align-left'>
            <div class="ben-label">Sort Code</div>
            <div class="ben-value">{{ beneficiaryDetails.sortCode}}</div>
        </div>
    </div>
    <div class='is-vertical-align align-left'>
        <div class="ben-label" >Account Number</div>
        <div class="ben-value">{{ beneficiaryDetails.accountNumber }}</div>
    </div>
</div>
</div>`;
