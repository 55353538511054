import { Injectable, signal } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class CtaButtonSignalService {
  _buttonDisabled = signal<any[]>([]);
  buttonDisabled = this._buttonDisabled;

  _buttonHide = signal<any[]>([]);
  buttonHide = this._buttonHide;
  constructor() {}
  setSignal(newValue: any): void {
    const index = this._buttonDisabled().findIndex((button) => Object.keys(newValue)[0] in button);
    if (index > -1) {
      this._buttonDisabled().splice(index, 1);
    }

    this._buttonDisabled.update((val) => [...val, newValue]);
  }

  setHideButtonSignal(newValue: any): void {
    const index = this._buttonHide().findIndex((button) => Object.keys(newValue)[0] in button);
    if (index > -1) {
      this._buttonHide().splice(index, 1);
    }
    this._buttonHide.update((val) => [...val, newValue]);
  }
}
