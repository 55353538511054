import { APP_ZONES, ZoneInfo, defaultModules } from '@finxone-platform/shared/sys-config-types';

export function zoneUrlKeyToZoneInfo(zoneUrlName: string): ZoneInfo {
  const zones = Object.values(defaultModules);

  return (
    zones.find((zone) => {
      return zone.url === zoneUrlName;
    }) ?? {
      ...defaultModules['default'],
      url: zoneUrlName,
    }
  );
}

/**
 * To remove hyphen, underscore from zone or page name.
 * @param ParamName - zone or page name
 * @param type - zone or page
 */
export function formatNameAsUrl(paramName: string | APP_ZONES): string {
  if (!paramName) {
    return '';
  }
  const words = paramName.split(/[-_ ]/);
  const formattedString = words
    .map((word) => word.toLowerCase())
    .filter((word) => word !== 'zone')
    .join('-');
  return formattedString;
}

export function zoneNameToZoneInfo(zoneName: string): ZoneInfo {
  const zones = Object.values(defaultModules);

  return (
    zones.find((zone) => {
      return zone.zoneName === zoneName;
    }) ?? {
      ...defaultModules['default'],
      zoneName: zoneName,
      url: zoneName,
    }
  );
}
