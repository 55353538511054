import { CommonModule, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BaseWidgetProperties, baseCssConfigStyle } from '@finxone-platform/shared/sys-config-types';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'finxone-web-frontend-lottie-animation',
  template: `
    <div class="lottie-wrapper" [ngStyle]="contentStyle">
      <ng-lottie [options]="options"> </ng-lottie>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, LottieComponent, NgStyle],
  styleUrl: './lottie-animation.component.scss',
})
export class LottieAnimationComponent implements OnInit {
  @Input() public lottieWidgetProperties: BaseWidgetProperties;
  @Input() public contentStyle: baseCssConfigStyle;
  public options: AnimationOptions;

  ngOnInit(): void {
    const isLoop = this.lottieWidgetProperties?.animationType?.toLowerCase() === 'loop';
    this.options = {
      path: this.lottieWidgetProperties.assetUrl,
      loop: isLoop,
    };
  }
}
