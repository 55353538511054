import { Injectable } from '@angular/core';
import { from } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor() {}

  getState(state: string) {
    const apiUrl = './assets/JSON/states/' + state + '-states.json';
    return from(
      fetch(apiUrl).then((response) => {
        return response.json();
      }),
    );
  }
}
