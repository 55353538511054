import { ProjectSettingsStateModel } from '../state/project-settings.state';

export class UpdateProjectSettings {
  static readonly type = '[ProjectSettings] UpdateProjectSettings';
  constructor(public payload: ProjectSettingsStateModel) {}
}

export class SetProjectSettings {
  static readonly type = '[ProjectSettings] SetProjectSettings';
  constructor(public payload: ProjectSettingsStateModel) {}
}
