<ng-container *ngIf="alerts?.length">
  @for (alert of alerts; track alert) {
  <div class="alert d-flex w-100 p-2" [ngStyle]="alert.position" [class.mb-3]="$index !== alerts.length - 1">
    @if (alert.displayAlert) {
    <finxone-platform-alert-message
      [activeDevice]="activeDevice"
      [alertData]="alert"
      [alertIndex]="$index"
      [contentStyle]="alert.contentStyle ?? {}"
      [alertTitleStyle]="alert.alertTitleStyle ?? { 'font-size': '16px', 'font-family': 'Arial' }"
      [alertDescriptionStyle]="alert.alertDescriptionStyle ?? { 'font-size': '16px', 'font-family': 'Arial' }"
      [leftIcon]="alert.leftIcon ?? { name: '', size: '0', color: '', unit: '' }"
      [rightIcon]="alert.rightIcon ?? { name: '', size: '0', color: '', unit: '' }"
    ></finxone-platform-alert-message>
    }
  </div>
  }
</ng-container>
