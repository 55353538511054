import { CurrencyPipe, DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { currencyCodeToFlag } from '@app/finxone-web-frontend/app/lib/utils/template/handlebar-helpers/currency-code-to-flag';
import { currencyToCircleFlag } from '@app/finxone-web-frontend/app/lib/utils/template/handlebar-helpers/currency-to-flag.utils';
import * as Handlebars from 'handlebars';
import { DropdownListingInterface } from '../accounts-context-dropdown-widget.component';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appAccountDropdown]',
  providers: [CurrencyPipe],
})
export class AccountsContextDropdownWidgetDirective implements OnInit, OnDestroy, OnChanges {
  @Input() dropdownListing: DropdownListingInterface[] = [];
  @Input() dropdownTemplate: string = '';
  @Input() showFlag = true;
  @Output()
  selectionChanged: EventEmitter<DropdownListingInterface> = new EventEmitter<DropdownListingInterface>();
  @Output() dropdownOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() scrolled = new EventEmitter<void>();
  public dropdown: HTMLElement;
  public isOpen: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.createDropdown();
    this.document.addEventListener('scroll', this.onScroll.bind(this), true);
  }

  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    const scrollPercentage = (element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100;
    if (scrollPercentage > 80) {
      this.scrolled.emit();
    }
    if (this.isOpen && !this.dropdown.contains(event.target as Node)) {
      this.toggleDropdown();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dropdownListing'] && this.dropdown) {
      this.clearDropdown();
      this.createDropdown();
    }
  }

  private updateDropdownContent(account: DropdownListingInterface): string {
    // Compile the Handlebars template
    const template = Handlebars.compile(this.dropdownTemplate);

    const model: any = { ...account };

    // Custom transformation for specific keys
    if (model.totalBalance && model.currencyCode) {
      model.totalBalance = this.currencyPipe.transform(
        model.totalBalance,
        model.currencyCode,
        'symbol-narrow',
      );
    }

    // Render the template with the model
    const renderedTemplate = template(model);

    return renderedTemplate;
  }

  private getCountryFlag(currency_code: string): string {
    return currencyToCircleFlag(currency_code, '24px') ?? '';
  }

  createAccountSection(account: DropdownListingInterface) {
    const section = this.renderer.createElement('section');
    this.renderer.addClass(section, 'd-flex');
    this.renderer.listen(section, 'click', () => {
      this.selectionChanged.emit(account);
      this.toggleDropdown();
    });
    this.renderer.addClass(section, 'justify-content-between');
    this.renderer.addClass(section, 'ml-2');

    const accountDiv = this.renderer.createElement('div');
    this.renderer.addClass(accountDiv, 'd-flex');
    this.renderer.addClass(accountDiv, 'items-center');

    const entry = currencyCodeToFlag.find((option: { country_code: string; currency_code: string }) => {
      return option.currency_code === account.currencyCode?.toUpperCase();
    });
    const img = this.renderer.createElement('img');
    if (entry) {
      this.renderer.setAttribute(img, 'src', `/assets/flags/${entry.country_code.toLowerCase()}.svg`);
      this.renderer.setStyle(img, 'width', '24px');
    }

    const infoDiv = this.renderer.createElement('div');
    this.renderer.addClass(infoDiv, 'd-flex');
    this.renderer.addClass(infoDiv, 'ml-1');
    this.renderer.setProperty(infoDiv, 'innerHTML', this.updateDropdownContent(account));
    if (this.showFlag) {
      this.renderer.appendChild(accountDiv, img);
    }
    this.renderer.appendChild(accountDiv, infoDiv);

    this.renderer.appendChild(section, accountDiv);

    return section;
  }

  clearDropdown() {
    while (this.dropdown.firstChild) {
      this.dropdown.removeChild(this.dropdown.firstChild);
    }
  }

  createDropdown() {
    this.dropdown = this.renderer.createElement('div');
    this.renderer.addClass(this.dropdown, 'account-dropdown');
    this.renderer.setStyle(this.dropdown, 'position', 'absolute');
    this.renderer.setStyle(this.dropdown, 'display', 'none');
    this.renderer.setStyle(this.dropdown, 'cursor', 'pointer');

    if (this.dropdownListing && this.dropdownListing.length > 0) {
      this.dropdownListing.forEach((account) => {
        const section = this.createAccountSection(account);
        this.renderer.appendChild(this.dropdown, section);
      });
    }

    this.renderer.appendChild(this.document.body, this.dropdown);
  }

  @HostListener('click')
  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.dropdownOpened.emit(this.isOpen);
    this.setPosition();
    this.renderer.setStyle(this.dropdown, 'display', this.isOpen ? 'block' : 'none');
  }

  setPosition() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.dropdown, 'top', `${rect.bottom + window.scrollY}px`);
    this.renderer.setStyle(this.dropdown, 'left', `${rect.left + window.scrollX}px`);
    this.renderer.setStyle(this.dropdown, 'width', `${rect.width}px`);
  }

  ngOnDestroy() {
    // Remove the dropdown from the body when the directive is destroyed
    if (this.dropdown) {
      this.renderer.removeChild(this.document.body, this.dropdown);
    }
  }
}
