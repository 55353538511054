import { SupportedDevice } from '@finxone-platform/shared/sys-config-types';

export class SetRole {
  static readonly type = '[Role] SetRole';
  constructor(
    public roleId: string,
    public deviceType?: SupportedDevice,
    public subscription: string = 'default',
  ) {}
}

export class UpdateRole {
  static readonly type = '[Role] UpdateRole';
  constructor(public roleId: string) {}
}

export class UpdateDevice {
  static readonly type = '[Role] UpdateDevice';
  constructor(public device: SupportedDevice) {}
}
