<div
  id="alertMessageWrapper"
  class="alert-wrapper is-center"
  [ngStyle]="getAlertStyles()"
  [ngClass]="activeDevice === 'mobile' ? 'mobile-alert' : 'desktop-alert'"
  [class.success-alert]="alertData?.type === 'success'"
  [class.error-alert]="alertData?.type === 'error'"
  [class.info-alert]="alertData?.type === 'info'"
  [class.warning-alert]="alertData?.type === 'warning'"
  [class.close-on-hover]="alertData?.dismissalType === dismissalTypes.CLOSE_ON_HOVER"
  (mouseover)="checkDismissalTypeAndClose(alertData, 'alertMessageWrapper')"
  (touchstart)="handleSwipe($event, 'touchstart')"
  (touchmove)="handleSwipe($event, 'touchmove')"
  (touchend)="handleSwipe($event, 'touchend')"
>
  <div class="alert-message">
    @if (leftIcon) {
    <mat-icon
      alt="icon"
      class="error-succes-icon mr-1"
      [svgIcon]="leftIcon.name"
      [ngStyle]="{ color: leftIcon.color, width: leftIcon.size, height: leftIcon?.size }"
    ></mat-icon>
    }
    <div class="alert-msg-info">
      <div [ngStyle]="alertTitleStyle" class="alert-header">
        {{ alertData?.headerMsg }}
      </div>

      <div class="alert-body">
        <span [ngStyle]="alertDescriptionStyle">{{ alertData?.msg }} </span>
      </div>
    </div>
    @if(rightIcon){
    <span class="close-icon d-flex">
      <mat-icon
        [ngStyle]="{ color: rightIcon?.color, width: rightIcon?.size, height: rightIcon?.size }"
        (click)="close()"
        alt="icon"
        [svgIcon]="rightIcon?.name ?? 'close'"
      ></mat-icon>
    </span>
    }
  </div>
</div>
