import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties, RevenirTripType } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { CreateRevenirTrip, MakeTripAsArchiveTrip } from '../../../actions/revenir.action';
import { RevenirService } from '../../../services/revenir-service/revenir.service';
import { FormActionState } from '../../../state/form-submision.state';
import { RevenirState } from '../../../state/revenir.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function addRevenirTrip(widgetProperties: BaseWidgetProperties, store: Store, router: Router) {
  const data = store.select(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE),
  );
  data
    .pipe(
      switchMap((res) => {
        return store.dispatch(new CreateRevenirTrip(res));
      }),
    )
    .subscribe(() => {
      widgetProperties['urlToNavigate'] = 'trips-home-page';
      widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, widgetProperties);
    });
}

export function archiveRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  revenirService: RevenirService,
) {
  const data = store.selectSnapshot(RevenirState.getArchivedRevenirTripDetails);
  store.dispatch(new MakeTripAsArchiveTrip(data as RevenirTripType));
}

export function checkBarcodeScanDoneRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
) {
  store.dispatch(new ResetFormDataAction());
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] = 'missing-passport';
  redirectToPage(router, widgetProperties);
}
export function confirmScanArchiveRevenirTrip(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  revenirService: RevenirService,
) {
  widgetProperties['urlToNavigate'] = 'confirm-archive-success';
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  redirectToPage(router, widgetProperties);
}

export function downloadTaxFreeForms(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  revenirService: RevenirService,
) {
  //TODO: Implement download tax free forms
}

export function skipBoardingPass(widgetProperties: BaseWidgetProperties, router: Router, store: Store) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'trips-home-page' : 'exit-point';

  redirectToPage(router, widgetProperties);
}

export function reviewBoardingPass(widgetProperties: BaseWidgetProperties, router: Router, store: Store) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  widgetProperties['urlToNavigate'] =
    data && !data.is_boarding_pass_uploaded ? 'barcode-scanning' : 'exit-point';
  redirectToPage(router, widgetProperties);
}

export function skipPassportDetails(widgetProperties: BaseWidgetProperties, store: Store, router: Router) {
  const data = store.selectSnapshot(RevenirState.getSelectedRevenirTripDetails);
  widgetProperties['zoneToNavigate'] = APP_ZONES.REVENIR_ZONE;
  if (
    data &&
    !data.is_boarding_pass_uploaded &&
    (data.country_exit === 'BEL' || data.country_entry === 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'missing-boarding-pass';
  } else if (
    (data && data?.is_boarding_pass_uploaded) ||
    (data?.country_exit !== 'BEL' && data?.country_entry !== 'BEL')
  ) {
    widgetProperties['urlToNavigate'] = 'barcode-scanning';
  }
  redirectToPage(router, widgetProperties);
}
