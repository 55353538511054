<div *ngIf="virtualCardData && cardConfigData && isCardAvailable; else noCardAvailable" class="h-100">
  <div
    class="card isRelative h-100 if-card"
    [ngClass]="{
      'card-frozen': isFreezeCard
    }"
  >
    <div class="if-card-body" [ngStyle]="borderStyle">
      <div
        class="if-card-front pointer-events-none"
        (click)="goToCardManagement()"
        [ngClass]="{
          'card-frozen': isFreezeCard
        }"
        [ngStyle]="borderStyle"
      >
        <div
          class="row mt-3 no-wrap"
          style="height: 30%; justify-content: space-between; align-items: center"
        >
          <div class="ml-4 col-6 is-left">
            <h3 *ngIf="parent.leftTopPlaceholderText.length > 0" class="account-number">
              {{ parent.leftTopPlaceholderText }}
            </h3>
          </div>
          <div class="is-right" [ngClass]="{ 'mr-4': !isVirtualCard }">
            <h3 *ngIf="cardConfigData['showCardDetails']" class="masked-pan">
              {{ formatMaskedPan(virtualCardData.maskedPan) }}
            </h3>

            <!-- Delete virtual card -->
            <finxone-web-frontend-virtual-card-options
              *ngIf="isVirtualCard"
              [isVirtualCard]="isVirtualCard"
              [virtualCardData]="virtualCardData"
              [paymentProvider]="parent.paymentGatewayConfig.payment"
            >
            </finxone-web-frontend-virtual-card-options>
          </div>
        </div>
        <div class="row mb-4 align-card-bottom mt-auto ff-unset">
          <div class="ml-4 col-6 text-left">
            <span class="card-format"> {{ virtualCardData.type | titlecase }} Card </span>
          </div>
          <div class="mr-4 text-right di-rtl">
            <span class="account-name" *ngIf="parent.showCardHolder">
              <b>{{ virtualCardData.name }} </b><span></span
            ></span>
            <p *ngIf="cardConfigData['showCardDetails'] && parent.showExpiry" class="card-logo">
              <span class="">{{ getExpiryDateWithFormat(virtualCardData.expiry) }}</span>
            </p>
          </div>
        </div>
        <div *ngIf="isCardActivation">
          <finxone-web-frontend-card-activation
            [borderStyleCardActivationStripe]="borderStyleCardActivationStripe"
            [parentAttribute]="attri"
          ></finxone-web-frontend-card-activation>
        </div>
        <finxone-web-frontend-frozen-strip *ngIf="isFreezeCard"></finxone-web-frontend-frozen-strip>
      </div>
    </div>
  </div>
</div>

<ng-template #noCardAvailable>
  <div class="card isRelative no-card h-100">
    <div class="card-body">
      <div class="card-front" [ngStyle]="borderStyle">
        <div class="row" style="justify-content: space-between" (click)="openAddCardBottomPopup()">
          <mat-icon svgIcon="credit-card"></mat-icon>
          <div class="title">Add a new Card</div>
          <p class="subtitle">No Cards are registered to this account</p>
          <div class="line"></div>
          <div class="add-card-sub-title">+ Add Card</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
