import { Injectable } from '@angular/core';
import { PRIVACY_SLUG } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, forkJoin, tap } from 'rxjs';
import { GetPrivacyContent } from '../actions/privacy-widget.action';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

export interface PrivacyWidgetStateModel {
  terms: string;
  privacy: string;
}

@State<PrivacyWidgetStateModel>({
  name: 'privacyWidget',
  defaults: {
    terms: '',
    privacy: '',
  },
})
@Injectable()
export class PrivacyWidgetState {
  constructor(private metadataService: MetadataService) {}

  @Selector()
  static privacyContent(state: PrivacyWidgetStateModel) {
    return state;
  }

  @Action(GetPrivacyContent)
  fetchPrivacyContent(ctx: StateContext<PrivacyWidgetStateModel>) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetPrivacyContent' }));

    return forkJoin([
      this.metadataService.getPrivacyContent(PRIVACY_SLUG.TERMS),
      this.metadataService.getPrivacyContent(PRIVACY_SLUG.PRIVACY_POLICY),
    ]).pipe(
      tap((privacyContentResult) => {
        ctx.patchState({
          terms: privacyContentResult[0].page_content,
          privacy: privacyContentResult[1].page_content,
        });

        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetPrivacyContent' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetPrivacyContent' }));
        throw _err;
      }),
    );
  }
}
