import { NotificationSettings, UserInfoType } from '@finxone-platform/shared/sys-config-types';

export class GetProfile {
  static readonly type = '[Profile] GetProfile';
  constructor(public emitUserProfileFetched: boolean = true) {}
}
export class SetProfile {
  static readonly type = '[Profile] SetProfile';
  constructor(public payload: UserInfoType) {}
}

export class SetActiveRole {
  static readonly type = '[Profile] SetActiveRole';
  constructor(public role: string) {}
}

export class SetActiveOrgId {
  static readonly type = '[Profile] SetActiveOrgId';
  constructor(public organisationId: string) {}
}

export class NotificationSettingsUpdate {
  static readonly type = '[Profile] NotificationSettingsUpdate';
  constructor(public payload: NotificationSettings) {}
}

export class UserProfileUpdated {
  static readonly type = '[Profile] UserProfileUpdated';
}

export class UserProfileFetched {
  static readonly type = '[Profile] UserProfileFetched';
}
