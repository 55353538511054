import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appNumberFormat]',
})
export class NumberFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInput(event: any): void {
    // Get input value and remove commas
    let inputValue: string = event.target.value.replace(/,/g, '');

    // Add commas for thousands
    inputValue = this.addCommas(inputValue);

    // Update the input value
    this.el.nativeElement.value = inputValue;
  }

  private addCommas(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
