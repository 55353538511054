import { Component, Input } from '@angular/core';
import { DeleteVirtualCardAction } from '@app/finxone-web-frontend/app/lib/actions/card-management.action';
import { FlipCard } from '@app/finxone-web-frontend/app/lib/actions/card.actions';
import { AccountState } from '@app/finxone-web-frontend/app/lib/state/account.state';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { CardInfoType, PaymentProviderType } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ConfirmationService } from 'primeng/api';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'finxone-web-frontend-virtual-card-options',
  templateUrl: './virtual-card-options.component.html',
  styleUrls: ['./virtual-card-options.component.scss'],
})
export class VirtualCardOptionsComponent {
  @Input() public virtualCardData: CardInfoType;
  @Input() public isVirtualCard: boolean;
  @Input() public paymentProvider: PaymentProviderType;

  public isCardMenuOpen: boolean;

  constructor(
    private confirmationService: ConfirmationService,
    private alertHandlerService: AlertHandlerService,
    private store: Store,
  ) {}

  private currentAccountId = () => this.store.selectSnapshot(AccountState.getCurrentAccountId);

  public flipCard() {
    this.store.dispatch(new FlipCard());
  }
  public onDeleteVirtualCard(): void {
    this.confirmationService.confirm({
      key: 'virtual-card-options',
      message: 'Are you sure you want to <b>delete</b> virtual card?',
      accept: () => {
        this.store
          .dispatch(
            new DeleteVirtualCardAction({
              cardId: this.virtualCardData.id,
              accountId: this.currentAccountId(),
            }),
          )
          .pipe(
            take(1),
            tap(() => this.alertHandlerService.showAlertFn('success', 'Card has been deleted.')),
          )
          .subscribe();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
}
