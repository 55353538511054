import { AddCustomQuestionAnswerRequest } from '@finxone-platform/shared/sys-config-types';

export class AddUpdateCustomQuestionAnswerAction {
  static readonly type = '[Custom Question] AddCustomQuestionAnswerAction';
  constructor(public payload: AddCustomQuestionAnswerRequest) {}
}

export class GetCustomQuestionDetailsAction {
  static readonly type = '[Custom Question] GetCustomQuestionDetailsAction';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public where: any) {}
}

export class RefreshCurrentEntity {
  static readonly type = '[Entity] RefreshCurrentEntity';
  constructor(public entity: string) {}
}

export class GetCustomQuestionAnswerDetailsAction {
  static readonly type = '[Custom Question] GetCustomQuestionAnswerDetailsAction';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(
    public payload: {
      customQuestionUniqueId: string;
      isSucessCallbackRequired: boolean;
    },
  ) {}
}

export class GetCustomQuestionAnswerDetailsActionSuccess {
  static readonly type = '[Custom Question] GetCustomQuestionAnswerDetailsActionSuccess';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: { value: string; customQuestionUniqueId: string }) {}
}

export class DeleteCustomQuestionAnswerAction {
  static readonly type = '[Custom Question] DeleteCustomQuestionAnswerAction';
  constructor(public payload: { customQuestionUniqueId: string }) {}
}
