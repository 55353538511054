import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  jsonb: { input: any; output: any };
  numeric: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  firstName: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  lastName: Scalars['String']['output'];
  trip_guest_profile?: Maybe<Trip_Guest>;
  trip_user_profile: Array<Trip>;
  trip_user_profile_aggregate: Trip_Aggregate;
};

export type SampleOutputTrip_User_ProfileArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type SampleOutputTrip_User_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  rel_addresses: Array<Rel_Address>;
  /** An aggregate relationship */
  rel_addresses_aggregate: Rel_Address_Aggregate;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['numeric']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "address" */
export type AddressRel_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

/** columns and relationships of "address" */
export type AddressRel_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rel_addresses?: InputMaybe<Rel_Address_Bool_Exp>;
  rel_addresses_aggregate?: InputMaybe<Rel_Address_Aggregate_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  street_number?: InputMaybe<Numeric_Comparison_Exp>;
  suburb?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey',
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  street_number?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rel_addresses?: InputMaybe<Rel_Address_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['numeric']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['numeric']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_number?: Maybe<Scalars['numeric']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  code?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rel_addresses_aggregate?: InputMaybe<Rel_Address_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street_number?: InputMaybe<Order_By>;
  suburb?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  Suburb = 'suburb',
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['numeric']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_number?: InputMaybe<Scalars['numeric']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  street_number?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number',
  /** column name */
  Suburb = 'suburb',
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  street_number?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "entity_dynamic_question" */
export type Entity_Dynamic_Question = {
  __typename?: 'entity_dynamic_question';
  entity: Scalars['String']['output'];
  entity_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  question_header: Scalars['String']['output'];
  question_subheader: Scalars['String']['output'];
  question_type: Scalars['String']['output'];
};

/** aggregated selection of "entity_dynamic_question" */
export type Entity_Dynamic_Question_Aggregate = {
  __typename?: 'entity_dynamic_question_aggregate';
  aggregate?: Maybe<Entity_Dynamic_Question_Aggregate_Fields>;
  nodes: Array<Entity_Dynamic_Question>;
};

/** aggregate fields of "entity_dynamic_question" */
export type Entity_Dynamic_Question_Aggregate_Fields = {
  __typename?: 'entity_dynamic_question_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Dynamic_Question_Max_Fields>;
  min?: Maybe<Entity_Dynamic_Question_Min_Fields>;
};

/** aggregate fields of "entity_dynamic_question" */
export type Entity_Dynamic_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Dynamic_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer = {
  __typename?: 'entity_dynamic_question_answer';
  entity_dynamic_question_id: Scalars['uuid']['output'];
  entity_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  page_name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  question_option_selected?: Maybe<Scalars['Int']['output']>;
  user_id: Scalars['uuid']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Aggregate = {
  __typename?: 'entity_dynamic_question_answer_aggregate';
  aggregate?: Maybe<Entity_Dynamic_Question_Answer_Aggregate_Fields>;
  nodes: Array<Entity_Dynamic_Question_Answer>;
};

/** aggregate fields of "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Aggregate_Fields = {
  __typename?: 'entity_dynamic_question_answer_aggregate_fields';
  avg?: Maybe<Entity_Dynamic_Question_Answer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Dynamic_Question_Answer_Max_Fields>;
  min?: Maybe<Entity_Dynamic_Question_Answer_Min_Fields>;
  stddev?: Maybe<Entity_Dynamic_Question_Answer_Stddev_Fields>;
  stddev_pop?: Maybe<Entity_Dynamic_Question_Answer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Entity_Dynamic_Question_Answer_Stddev_Samp_Fields>;
  sum?: Maybe<Entity_Dynamic_Question_Answer_Sum_Fields>;
  var_pop?: Maybe<Entity_Dynamic_Question_Answer_Var_Pop_Fields>;
  var_samp?: Maybe<Entity_Dynamic_Question_Answer_Var_Samp_Fields>;
  variance?: Maybe<Entity_Dynamic_Question_Answer_Variance_Fields>;
};

/** aggregate fields of "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Entity_Dynamic_Question_Answer_Avg_Fields = {
  __typename?: 'entity_dynamic_question_answer_avg_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "entity_dynamic_question_answer". All fields are combined with a logical 'AND'. */
export type Entity_Dynamic_Question_Answer_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Bool_Exp>>;
  _not?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Bool_Exp>>;
  entity_dynamic_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  entity_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  page_name?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  question_option_selected?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_dynamic_question_answer" */
export enum Entity_Dynamic_Question_Answer_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityDynamicQuestionAnswerPkey = 'entity_dynamic_question_answer_pkey',
}

/** input type for incrementing numeric columns in table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Inc_Input = {
  question_option_selected?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Insert_Input = {
  entity_dynamic_question_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  page_name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  question_option_selected?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entity_Dynamic_Question_Answer_Max_Fields = {
  __typename?: 'entity_dynamic_question_answer_max_fields';
  entity_dynamic_question_id?: Maybe<Scalars['uuid']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  page_name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  question_option_selected?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Entity_Dynamic_Question_Answer_Min_Fields = {
  __typename?: 'entity_dynamic_question_answer_min_fields';
  entity_dynamic_question_id?: Maybe<Scalars['uuid']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  page_name?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['uuid']['output']>;
  question_option_selected?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Mutation_Response = {
  __typename?: 'entity_dynamic_question_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Dynamic_Question_Answer>;
};

/** on_conflict condition type for table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_On_Conflict = {
  constraint: Entity_Dynamic_Question_Answer_Constraint;
  update_columns?: Array<Entity_Dynamic_Question_Answer_Update_Column>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_dynamic_question_answer". */
export type Entity_Dynamic_Question_Answer_Order_By = {
  entity_dynamic_question_id?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page_name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  question_option_selected?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_dynamic_question_answer */
export type Entity_Dynamic_Question_Answer_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "entity_dynamic_question_answer" */
export enum Entity_Dynamic_Question_Answer_Select_Column {
  /** column name */
  EntityDynamicQuestionId = 'entity_dynamic_question_id',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  PageName = 'page_name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QuestionOptionSelected = 'question_option_selected',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Set_Input = {
  entity_dynamic_question_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  page_name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  question_option_selected?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Entity_Dynamic_Question_Answer_Stddev_Fields = {
  __typename?: 'entity_dynamic_question_answer_stddev_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Entity_Dynamic_Question_Answer_Stddev_Pop_Fields = {
  __typename?: 'entity_dynamic_question_answer_stddev_pop_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Entity_Dynamic_Question_Answer_Stddev_Samp_Fields = {
  __typename?: 'entity_dynamic_question_answer_stddev_samp_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "entity_dynamic_question_answer" */
export type Entity_Dynamic_Question_Answer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Dynamic_Question_Answer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Dynamic_Question_Answer_Stream_Cursor_Value_Input = {
  entity_dynamic_question_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  page_name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['uuid']['input']>;
  question_option_selected?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Entity_Dynamic_Question_Answer_Sum_Fields = {
  __typename?: 'entity_dynamic_question_answer_sum_fields';
  question_option_selected?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "entity_dynamic_question_answer" */
export enum Entity_Dynamic_Question_Answer_Update_Column {
  /** column name */
  EntityDynamicQuestionId = 'entity_dynamic_question_id',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  PageName = 'page_name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  QuestionOptionSelected = 'question_option_selected',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Entity_Dynamic_Question_Answer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Entity_Dynamic_Question_Answer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Dynamic_Question_Answer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Dynamic_Question_Answer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Entity_Dynamic_Question_Answer_Var_Pop_Fields = {
  __typename?: 'entity_dynamic_question_answer_var_pop_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Entity_Dynamic_Question_Answer_Var_Samp_Fields = {
  __typename?: 'entity_dynamic_question_answer_var_samp_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Entity_Dynamic_Question_Answer_Variance_Fields = {
  __typename?: 'entity_dynamic_question_answer_variance_fields';
  question_option_selected?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "entity_dynamic_question". All fields are combined with a logical 'AND'. */
export type Entity_Dynamic_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Dynamic_Question_Bool_Exp>>;
  _not?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Dynamic_Question_Bool_Exp>>;
  entity?: InputMaybe<String_Comparison_Exp>;
  entity_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question_header?: InputMaybe<String_Comparison_Exp>;
  question_subheader?: InputMaybe<String_Comparison_Exp>;
  question_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_dynamic_question" */
export enum Entity_Dynamic_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityDynamicQuestionPkey = 'entity_dynamic_question_pkey',
}

/** input type for inserting data into table "entity_dynamic_question" */
export type Entity_Dynamic_Question_Insert_Input = {
  entity?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_header?: InputMaybe<Scalars['String']['input']>;
  question_subheader?: InputMaybe<Scalars['String']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entity_Dynamic_Question_Max_Fields = {
  __typename?: 'entity_dynamic_question_max_fields';
  entity?: Maybe<Scalars['String']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_header?: Maybe<Scalars['String']['output']>;
  question_subheader?: Maybe<Scalars['String']['output']>;
  question_type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Entity_Dynamic_Question_Min_Fields = {
  __typename?: 'entity_dynamic_question_min_fields';
  entity?: Maybe<Scalars['String']['output']>;
  entity_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_header?: Maybe<Scalars['String']['output']>;
  question_subheader?: Maybe<Scalars['String']['output']>;
  question_type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entity_dynamic_question" */
export type Entity_Dynamic_Question_Mutation_Response = {
  __typename?: 'entity_dynamic_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Dynamic_Question>;
};

/** on_conflict condition type for table "entity_dynamic_question" */
export type Entity_Dynamic_Question_On_Conflict = {
  constraint: Entity_Dynamic_Question_Constraint;
  update_columns?: Array<Entity_Dynamic_Question_Update_Column>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_dynamic_question". */
export type Entity_Dynamic_Question_Order_By = {
  entity?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_header?: InputMaybe<Order_By>;
  question_subheader?: InputMaybe<Order_By>;
  question_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_dynamic_question */
export type Entity_Dynamic_Question_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "entity_dynamic_question" */
export enum Entity_Dynamic_Question_Select_Column {
  /** column name */
  Entity = 'entity',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionHeader = 'question_header',
  /** column name */
  QuestionSubheader = 'question_subheader',
  /** column name */
  QuestionType = 'question_type',
}

/** input type for updating data in table "entity_dynamic_question" */
export type Entity_Dynamic_Question_Set_Input = {
  entity?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_header?: InputMaybe<Scalars['String']['input']>;
  question_subheader?: InputMaybe<Scalars['String']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entity_dynamic_question" */
export type Entity_Dynamic_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Dynamic_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Dynamic_Question_Stream_Cursor_Value_Input = {
  entity?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_header?: InputMaybe<Scalars['String']['input']>;
  question_subheader?: InputMaybe<Scalars['String']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entity_dynamic_question" */
export enum Entity_Dynamic_Question_Update_Column {
  /** column name */
  Entity = 'entity',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionHeader = 'question_header',
  /** column name */
  QuestionSubheader = 'question_subheader',
  /** column name */
  QuestionType = 'question_type',
}

export type Entity_Dynamic_Question_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Dynamic_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Dynamic_Question_Bool_Exp;
};

/** columns and relationships of "entity_files" */
export type Entity_Files = {
  __typename?: 'entity_files';
  entity_id: Scalars['uuid']['output'];
  entity_type: Scalars['String']['output'];
  file_id: Scalars['uuid']['output'];
  file_type: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "entity_files" */
export type Entity_Files_Aggregate = {
  __typename?: 'entity_files_aggregate';
  aggregate?: Maybe<Entity_Files_Aggregate_Fields>;
  nodes: Array<Entity_Files>;
};

export type Entity_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Entity_Files_Aggregate_Bool_Exp_Count>;
};

export type Entity_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Entity_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Entity_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "entity_files" */
export type Entity_Files_Aggregate_Fields = {
  __typename?: 'entity_files_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Files_Max_Fields>;
  min?: Maybe<Entity_Files_Min_Fields>;
};

/** aggregate fields of "entity_files" */
export type Entity_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entity_files" */
export type Entity_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entity_Files_Max_Order_By>;
  min?: InputMaybe<Entity_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "entity_files" */
export type Entity_Files_Arr_Rel_Insert_Input = {
  data: Array<Entity_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "entity_files". All fields are combined with a logical 'AND'. */
export type Entity_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Files_Bool_Exp>>;
  _not?: InputMaybe<Entity_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Files_Bool_Exp>>;
  entity_id?: InputMaybe<Uuid_Comparison_Exp>;
  entity_type?: InputMaybe<String_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_files" */
export enum Entity_Files_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  EntityFilesFileIdKey = 'entity_files_file_id_key',
  /** unique or primary key constraint on columns "id" */
  EntityFilesIdKey = 'entity_files_id_key',
  /** unique or primary key constraint on columns "id" */
  EntityFilesPkey = 'entity_files_pkey',
}

/** input type for inserting data into table "entity_files" */
export type Entity_Files_Insert_Input = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Entity_Files_Max_Fields = {
  __typename?: 'entity_files_max_fields';
  entity_id?: Maybe<Scalars['uuid']['output']>;
  entity_type?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "entity_files" */
export type Entity_Files_Max_Order_By = {
  entity_id?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entity_Files_Min_Fields = {
  __typename?: 'entity_files_min_fields';
  entity_id?: Maybe<Scalars['uuid']['output']>;
  entity_type?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  file_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "entity_files" */
export type Entity_Files_Min_Order_By = {
  entity_id?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "entity_files" */
export type Entity_Files_Mutation_Response = {
  __typename?: 'entity_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Files>;
};

/** on_conflict condition type for table "entity_files" */
export type Entity_Files_On_Conflict = {
  constraint: Entity_Files_Constraint;
  update_columns?: Array<Entity_Files_Update_Column>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_files". */
export type Entity_Files_Order_By = {
  entity_id?: InputMaybe<Order_By>;
  entity_type?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_files */
export type Entity_Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "entity_files" */
export enum Entity_Files_Select_Column {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "entity_files" */
export type Entity_Files_Set_Input = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "entity_files" */
export type Entity_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Files_Stream_Cursor_Value_Input = {
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "entity_files" */
export enum Entity_Files_Update_Column {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
}

export type Entity_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Files_Bool_Exp;
};

/** columns and relationships of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question = {
  __typename?: 'entity_fixed_custom_question';
  /** An array relationship */
  answers: Array<Entity_Fixed_Custom_Question_Answer>;
  /** An aggregate relationship */
  answers_aggregate: Entity_Fixed_Custom_Question_Answer_Aggregate;
  entity: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  question_type: Scalars['String']['output'];
  unique_id?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_QuestionAnswersArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

/** columns and relationships of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_QuestionAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

/** aggregated selection of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Aggregate = {
  __typename?: 'entity_fixed_custom_question_aggregate';
  aggregate?: Maybe<Entity_Fixed_Custom_Question_Aggregate_Fields>;
  nodes: Array<Entity_Fixed_Custom_Question>;
};

/** aggregate fields of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Aggregate_Fields = {
  __typename?: 'entity_fixed_custom_question_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Fixed_Custom_Question_Max_Fields>;
  min?: Maybe<Entity_Fixed_Custom_Question_Min_Fields>;
};

/** aggregate fields of "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Fixed_Custom_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer = {
  __typename?: 'entity_fixed_custom_question_answer';
  createdDate: Scalars['timestamptz']['output'];
  entity_custom_question_id: Scalars['uuid']['output'];
  /** An object relationship */
  fixed_answer_to_fixed_question?: Maybe<Entity_Fixed_Custom_Question>;
  id: Scalars['uuid']['output'];
  parent_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  question?: Maybe<Entity_Fixed_Custom_Question>;
  user_id: Scalars['uuid']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Aggregate = {
  __typename?: 'entity_fixed_custom_question_answer_aggregate';
  aggregate?: Maybe<Entity_Fixed_Custom_Question_Answer_Aggregate_Fields>;
  nodes: Array<Entity_Fixed_Custom_Question_Answer>;
};

export type Entity_Fixed_Custom_Question_Answer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Aggregate_Bool_Exp_Count>;
};

export type Entity_Fixed_Custom_Question_Answer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Aggregate_Fields = {
  __typename?: 'entity_fixed_custom_question_answer_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Entity_Fixed_Custom_Question_Answer_Max_Fields>;
  min?: Maybe<Entity_Fixed_Custom_Question_Answer_Min_Fields>;
};

/** aggregate fields of "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Max_Order_By>;
  min?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Arr_Rel_Insert_Input = {
  data: Array<Entity_Fixed_Custom_Question_Answer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_Answer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "entity_fixed_custom_question_answer". All fields are combined with a logical 'AND'. */
export type Entity_Fixed_Custom_Question_Answer_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Bool_Exp>>;
  _not?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Bool_Exp>>;
  createdDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_custom_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  fixed_answer_to_fixed_question?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  question?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_fixed_custom_question_answer" */
export enum Entity_Fixed_Custom_Question_Answer_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityFixedCustomQuestionAnswerPkey = 'entity_fixed_custom_question_answer_pkey',
}

/** input type for inserting data into table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Insert_Input = {
  createdDate?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_custom_question_id?: InputMaybe<Scalars['uuid']['input']>;
  fixed_answer_to_fixed_question?: InputMaybe<Entity_Fixed_Custom_Question_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  question?: InputMaybe<Entity_Fixed_Custom_Question_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entity_Fixed_Custom_Question_Answer_Max_Fields = {
  __typename?: 'entity_fixed_custom_question_answer_max_fields';
  createdDate?: Maybe<Scalars['timestamptz']['output']>;
  entity_custom_question_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Max_Order_By = {
  createdDate?: InputMaybe<Order_By>;
  entity_custom_question_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entity_Fixed_Custom_Question_Answer_Min_Fields = {
  __typename?: 'entity_fixed_custom_question_answer_min_fields';
  createdDate?: Maybe<Scalars['timestamptz']['output']>;
  entity_custom_question_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Min_Order_By = {
  createdDate?: InputMaybe<Order_By>;
  entity_custom_question_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Mutation_Response = {
  __typename?: 'entity_fixed_custom_question_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Fixed_Custom_Question_Answer>;
};

/** input type for inserting object relation for remote table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Obj_Rel_Insert_Input = {
  data: Entity_Fixed_Custom_Question_Answer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_Answer_On_Conflict>;
};

/** on_conflict condition type for table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_On_Conflict = {
  constraint: Entity_Fixed_Custom_Question_Answer_Constraint;
  update_columns?: Array<Entity_Fixed_Custom_Question_Answer_Update_Column>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_fixed_custom_question_answer". */
export type Entity_Fixed_Custom_Question_Answer_Order_By = {
  createdDate?: InputMaybe<Order_By>;
  entity_custom_question_id?: InputMaybe<Order_By>;
  fixed_answer_to_fixed_question?: InputMaybe<Entity_Fixed_Custom_Question_Order_By>;
  id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  question?: InputMaybe<Entity_Fixed_Custom_Question_Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_fixed_custom_question_answer */
export type Entity_Fixed_Custom_Question_Answer_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "entity_fixed_custom_question_answer" */
export enum Entity_Fixed_Custom_Question_Answer_Select_Column {
  /** column name */
  CreatedDate = 'createdDate',
  /** column name */
  EntityCustomQuestionId = 'entity_custom_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Set_Input = {
  createdDate?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_custom_question_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entity_fixed_custom_question_answer" */
export type Entity_Fixed_Custom_Question_Answer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Fixed_Custom_Question_Answer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Fixed_Custom_Question_Answer_Stream_Cursor_Value_Input = {
  createdDate?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_custom_question_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entity_fixed_custom_question_answer" */
export enum Entity_Fixed_Custom_Question_Answer_Update_Column {
  /** column name */
  CreatedDate = 'createdDate',
  /** column name */
  EntityCustomQuestionId = 'entity_custom_question_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Entity_Fixed_Custom_Question_Answer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Fixed_Custom_Question_Answer_Bool_Exp;
};

/** Boolean expression to filter rows from the table "entity_fixed_custom_question". All fields are combined with a logical 'AND'. */
export type Entity_Fixed_Custom_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Fixed_Custom_Question_Bool_Exp>>;
  _not?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Fixed_Custom_Question_Bool_Exp>>;
  answers?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
  answers_aggregate?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Aggregate_Bool_Exp>;
  entity?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  question_type?: InputMaybe<String_Comparison_Exp>;
  unique_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_fixed_custom_question" */
export enum Entity_Fixed_Custom_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  EntityFixedCustomQuestionPkey = 'entity_fixed_custom_question_pkey',
  /** unique or primary key constraint on columns "unique_id" */
  EntityFixedCustomQuestionUniqueIdKey = 'entity_fixed_custom_question_unique_id_key',
}

/** input type for inserting data into table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Insert_Input = {
  answers?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Arr_Rel_Insert_Input>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Entity_Fixed_Custom_Question_Max_Fields = {
  __typename?: 'entity_fixed_custom_question_max_fields';
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_type?: Maybe<Scalars['String']['output']>;
  unique_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Entity_Fixed_Custom_Question_Min_Fields = {
  __typename?: 'entity_fixed_custom_question_min_fields';
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question_type?: Maybe<Scalars['String']['output']>;
  unique_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Mutation_Response = {
  __typename?: 'entity_fixed_custom_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Fixed_Custom_Question>;
};

/** input type for inserting object relation for remote table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Obj_Rel_Insert_Input = {
  data: Entity_Fixed_Custom_Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_On_Conflict>;
};

/** on_conflict condition type for table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_On_Conflict = {
  constraint: Entity_Fixed_Custom_Question_Constraint;
  update_columns?: Array<Entity_Fixed_Custom_Question_Update_Column>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_fixed_custom_question". */
export type Entity_Fixed_Custom_Question_Order_By = {
  answers_aggregate?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Aggregate_Order_By>;
  entity?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question_type?: InputMaybe<Order_By>;
  unique_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_fixed_custom_question */
export type Entity_Fixed_Custom_Question_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "entity_fixed_custom_question" */
export enum Entity_Fixed_Custom_Question_Select_Column {
  /** column name */
  Entity = 'entity',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionType = 'question_type',
  /** column name */
  UniqueId = 'unique_id',
}

/** input type for updating data in table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Set_Input = {
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "entity_fixed_custom_question" */
export type Entity_Fixed_Custom_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Entity_Fixed_Custom_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Entity_Fixed_Custom_Question_Stream_Cursor_Value_Input = {
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  question_type?: InputMaybe<Scalars['String']['input']>;
  unique_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "entity_fixed_custom_question" */
export enum Entity_Fixed_Custom_Question_Update_Column {
  /** column name */
  Entity = 'entity',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionType = 'question_type',
  /** column name */
  UniqueId = 'unique_id',
}

export type Entity_Fixed_Custom_Question_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Entity_Fixed_Custom_Question_Bool_Exp;
};

/** columns and relationships of "facility" */
export type Facility = {
  __typename?: 'facility';
  attributes: Scalars['jsonb']['output'];
  /** An array relationship */
  facility_facility_attributes: Array<Facility_Attribute>;
  /** An aggregate relationship */
  facility_facility_attributes_aggregate: Facility_Attribute_Aggregate;
  /** An array relationship */
  files: Array<Entity_Files>;
  /** An aggregate relationship */
  files_aggregate: Entity_Files_Aggregate;
  id: Scalars['uuid']['output'];
  is_mobile: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  rel_addresses: Array<Rel_Address>;
  /** An aggregate relationship */
  rel_addresses_aggregate: Rel_Address_Aggregate;
  /** An array relationship */
  rel_metadata: Array<Rel_Metadata>;
  /** An aggregate relationship */
  rel_metadata_aggregate: Rel_Metadata_Aggregate;
  /** An array relationship */
  trips: Array<Trip>;
  /** An aggregate relationship */
  trips_aggregate: Trip_Aggregate;
};

/** columns and relationships of "facility" */
export type FacilityAttributesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "facility" */
export type FacilityFacility_Facility_AttributesArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityFacility_Facility_Attributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityFilesArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityRel_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityRel_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityRel_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityRel_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityTripsArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** columns and relationships of "facility" */
export type FacilityTrips_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** aggregated selection of "facility" */
export type Facility_Aggregate = {
  __typename?: 'facility_aggregate';
  aggregate?: Maybe<Facility_Aggregate_Fields>;
  nodes: Array<Facility>;
};

/** aggregate fields of "facility" */
export type Facility_Aggregate_Fields = {
  __typename?: 'facility_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Facility_Max_Fields>;
  min?: Maybe<Facility_Min_Fields>;
};

/** aggregate fields of "facility" */
export type Facility_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Facility_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Facility_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "facility_attribute" */
export type Facility_Attribute = {
  __typename?: 'facility_attribute';
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  /** An array relationship */
  rel_metadata: Array<Rel_Metadata>;
  /** An aggregate relationship */
  rel_metadata_aggregate: Rel_Metadata_Aggregate;
  value: Scalars['String']['output'];
};

/** columns and relationships of "facility_attribute" */
export type Facility_AttributeRel_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** columns and relationships of "facility_attribute" */
export type Facility_AttributeRel_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** aggregated selection of "facility_attribute" */
export type Facility_Attribute_Aggregate = {
  __typename?: 'facility_attribute_aggregate';
  aggregate?: Maybe<Facility_Attribute_Aggregate_Fields>;
  nodes: Array<Facility_Attribute>;
};

export type Facility_Attribute_Aggregate_Bool_Exp = {
  count?: InputMaybe<Facility_Attribute_Aggregate_Bool_Exp_Count>;
};

export type Facility_Attribute_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Facility_Attribute_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "facility_attribute" */
export type Facility_Attribute_Aggregate_Fields = {
  __typename?: 'facility_attribute_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Facility_Attribute_Max_Fields>;
  min?: Maybe<Facility_Attribute_Min_Fields>;
};

/** aggregate fields of "facility_attribute" */
export type Facility_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "facility_attribute" */
export type Facility_Attribute_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Facility_Attribute_Max_Order_By>;
  min?: InputMaybe<Facility_Attribute_Min_Order_By>;
};

/** input type for inserting array relation for remote table "facility_attribute" */
export type Facility_Attribute_Arr_Rel_Insert_Input = {
  data: Array<Facility_Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Facility_Attribute_On_Conflict>;
};

/** Boolean expression to filter rows from the table "facility_attribute". All fields are combined with a logical 'AND'. */
export type Facility_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Facility_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Facility_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Facility_Attribute_Bool_Exp>>;
  facility_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  rel_metadata?: InputMaybe<Rel_Metadata_Bool_Exp>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "facility_attribute" */
export enum Facility_Attribute_Constraint {
  /** unique or primary key constraint on columns "id" */
  FacilityAttributePkey = 'facility_attribute_pkey',
}

/** input type for inserting data into table "facility_attribute" */
export type Facility_Attribute_Insert_Input = {
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  rel_metadata?: InputMaybe<Rel_Metadata_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Facility_Attribute_Max_Fields = {
  __typename?: 'facility_attribute_max_fields';
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "facility_attribute" */
export type Facility_Attribute_Max_Order_By = {
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Facility_Attribute_Min_Fields = {
  __typename?: 'facility_attribute_min_fields';
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "facility_attribute" */
export type Facility_Attribute_Min_Order_By = {
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "facility_attribute" */
export type Facility_Attribute_Mutation_Response = {
  __typename?: 'facility_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Facility_Attribute>;
};

/** input type for inserting object relation for remote table "facility_attribute" */
export type Facility_Attribute_Obj_Rel_Insert_Input = {
  data: Facility_Attribute_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Facility_Attribute_On_Conflict>;
};

/** on_conflict condition type for table "facility_attribute" */
export type Facility_Attribute_On_Conflict = {
  constraint: Facility_Attribute_Constraint;
  update_columns?: Array<Facility_Attribute_Update_Column>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "facility_attribute". */
export type Facility_Attribute_Order_By = {
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: facility_attribute */
export type Facility_Attribute_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "facility_attribute" */
export enum Facility_Attribute_Select_Column {
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "facility_attribute" */
export type Facility_Attribute_Set_Input = {
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "facility_attribute" */
export type Facility_Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Facility_Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Facility_Attribute_Stream_Cursor_Value_Input = {
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "facility_attribute" */
export enum Facility_Attribute_Update_Column {
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value',
}

export type Facility_Attribute_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Facility_Attribute_Set_Input>;
  /** filter the rows which have to be updated */
  where: Facility_Attribute_Bool_Exp;
};

/** Boolean expression to filter rows from the table "facility". All fields are combined with a logical 'AND'. */
export type Facility_Bool_Exp = {
  _and?: InputMaybe<Array<Facility_Bool_Exp>>;
  _not?: InputMaybe<Facility_Bool_Exp>;
  _or?: InputMaybe<Array<Facility_Bool_Exp>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  facility_facility_attributes?: InputMaybe<Facility_Attribute_Bool_Exp>;
  facility_facility_attributes_aggregate?: InputMaybe<Facility_Attribute_Aggregate_Bool_Exp>;
  files?: InputMaybe<Entity_Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Entity_Files_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_mobile?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rel_addresses?: InputMaybe<Rel_Address_Bool_Exp>;
  rel_addresses_aggregate?: InputMaybe<Rel_Address_Aggregate_Bool_Exp>;
  rel_metadata?: InputMaybe<Rel_Metadata_Bool_Exp>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Bool_Exp>;
  trips?: InputMaybe<Trip_Bool_Exp>;
  trips_aggregate?: InputMaybe<Trip_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "facility" */
export enum Facility_Constraint {
  /** unique or primary key constraint on columns "id" */
  FacilityPkey = 'facility_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Facility_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Facility_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Facility_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "facility" */
export type Facility_Insert_Input = {
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
  facility_facility_attributes?: InputMaybe<Facility_Attribute_Arr_Rel_Insert_Input>;
  files?: InputMaybe<Entity_Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_mobile?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rel_addresses?: InputMaybe<Rel_Address_Arr_Rel_Insert_Input>;
  rel_metadata?: InputMaybe<Rel_Metadata_Arr_Rel_Insert_Input>;
  trips?: InputMaybe<Trip_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Facility_Max_Fields = {
  __typename?: 'facility_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Facility_Min_Fields = {
  __typename?: 'facility_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "facility" */
export type Facility_Mutation_Response = {
  __typename?: 'facility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Facility>;
};

/** input type for inserting object relation for remote table "facility" */
export type Facility_Obj_Rel_Insert_Input = {
  data: Facility_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Facility_On_Conflict>;
};

/** on_conflict condition type for table "facility" */
export type Facility_On_Conflict = {
  constraint: Facility_Constraint;
  update_columns?: Array<Facility_Update_Column>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

/** Ordering options when selecting data from "facility". */
export type Facility_Order_By = {
  attributes?: InputMaybe<Order_By>;
  facility_facility_attributes_aggregate?: InputMaybe<Facility_Attribute_Aggregate_Order_By>;
  files_aggregate?: InputMaybe<Entity_Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rel_addresses_aggregate?: InputMaybe<Rel_Address_Aggregate_Order_By>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Order_By>;
  trips_aggregate?: InputMaybe<Trip_Aggregate_Order_By>;
};

/** primary key columns input for table: facility */
export type Facility_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Facility_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "facility" */
export enum Facility_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Id = 'id',
  /** column name */
  IsMobile = 'is_mobile',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "facility" */
export type Facility_Set_Input = {
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_mobile?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "facility" */
export type Facility_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Facility_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Facility_Stream_Cursor_Value_Input = {
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_mobile?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "facility_type" */
export type Facility_Type = {
  __typename?: 'facility_type';
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  /** An object relationship */
  tripeTypeEnum?: Maybe<Trip_Type>;
  value: Scalars['String']['output'];
};

/** aggregated selection of "facility_type" */
export type Facility_Type_Aggregate = {
  __typename?: 'facility_type_aggregate';
  aggregate?: Maybe<Facility_Type_Aggregate_Fields>;
  nodes: Array<Facility_Type>;
};

/** aggregate fields of "facility_type" */
export type Facility_Type_Aggregate_Fields = {
  __typename?: 'facility_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Facility_Type_Max_Fields>;
  min?: Maybe<Facility_Type_Min_Fields>;
};

/** aggregate fields of "facility_type" */
export type Facility_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Facility_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "facility_type". All fields are combined with a logical 'AND'. */
export type Facility_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Facility_Type_Bool_Exp>>;
  _not?: InputMaybe<Facility_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Facility_Type_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  tripeTypeEnum?: InputMaybe<Trip_Type_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "facility_type" */
export enum Facility_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  FacilityTypeIdKey = 'facility_type_id_key',
  /** unique or primary key constraint on columns "key" */
  FacilityTypePkey = 'facility_type_pkey',
}

/** input type for inserting data into table "facility_type" */
export type Facility_Type_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  tripeTypeEnum?: InputMaybe<Trip_Type_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Facility_Type_Max_Fields = {
  __typename?: 'facility_type_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Facility_Type_Min_Fields = {
  __typename?: 'facility_type_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "facility_type" */
export type Facility_Type_Mutation_Response = {
  __typename?: 'facility_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Facility_Type>;
};

/** on_conflict condition type for table "facility_type" */
export type Facility_Type_On_Conflict = {
  constraint: Facility_Type_Constraint;
  update_columns?: Array<Facility_Type_Update_Column>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "facility_type". */
export type Facility_Type_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  tripeTypeEnum?: InputMaybe<Trip_Type_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: facility_type */
export type Facility_Type_Pk_Columns_Input = {
  key: Scalars['String']['input'];
};

/** select columns of table "facility_type" */
export enum Facility_Type_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "facility_type" */
export type Facility_Type_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "facility_type" */
export type Facility_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Facility_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Facility_Type_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "facility_type" */
export enum Facility_Type_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Value = 'value',
}

export type Facility_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Facility_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Facility_Type_Bool_Exp;
};

/** update columns of table "facility" */
export enum Facility_Update_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Id = 'id',
  /** column name */
  IsMobile = 'is_mobile',
  /** column name */
  Name = 'name',
}

export type Facility_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Facility_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Facility_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Facility_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Facility_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Facility_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Facility_Set_Input>;
  /** filter the rows which have to be updated */
  where: Facility_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "entity_dynamic_question" */
  delete_entity_dynamic_question?: Maybe<Entity_Dynamic_Question_Mutation_Response>;
  /** delete data from the table: "entity_dynamic_question_answer" */
  delete_entity_dynamic_question_answer?: Maybe<Entity_Dynamic_Question_Answer_Mutation_Response>;
  /** delete single row from the table: "entity_dynamic_question_answer" */
  delete_entity_dynamic_question_answer_by_pk?: Maybe<Entity_Dynamic_Question_Answer>;
  /** delete single row from the table: "entity_dynamic_question" */
  delete_entity_dynamic_question_by_pk?: Maybe<Entity_Dynamic_Question>;
  /** delete data from the table: "entity_files" */
  delete_entity_files?: Maybe<Entity_Files_Mutation_Response>;
  /** delete single row from the table: "entity_files" */
  delete_entity_files_by_pk?: Maybe<Entity_Files>;
  /** delete data from the table: "entity_fixed_custom_question" */
  delete_entity_fixed_custom_question?: Maybe<Entity_Fixed_Custom_Question_Mutation_Response>;
  /** delete data from the table: "entity_fixed_custom_question_answer" */
  delete_entity_fixed_custom_question_answer?: Maybe<Entity_Fixed_Custom_Question_Answer_Mutation_Response>;
  /** delete single row from the table: "entity_fixed_custom_question_answer" */
  delete_entity_fixed_custom_question_answer_by_pk?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  /** delete single row from the table: "entity_fixed_custom_question" */
  delete_entity_fixed_custom_question_by_pk?: Maybe<Entity_Fixed_Custom_Question>;
  /** delete data from the table: "facility" */
  delete_facility?: Maybe<Facility_Mutation_Response>;
  /** delete data from the table: "facility_attribute" */
  delete_facility_attribute?: Maybe<Facility_Attribute_Mutation_Response>;
  /** delete single row from the table: "facility_attribute" */
  delete_facility_attribute_by_pk?: Maybe<Facility_Attribute>;
  /** delete single row from the table: "facility" */
  delete_facility_by_pk?: Maybe<Facility>;
  /** delete data from the table: "facility_type" */
  delete_facility_type?: Maybe<Facility_Type_Mutation_Response>;
  /** delete single row from the table: "facility_type" */
  delete_facility_type_by_pk?: Maybe<Facility_Type>;
  /** delete data from the table: "rel_address" */
  delete_rel_address?: Maybe<Rel_Address_Mutation_Response>;
  /** delete single row from the table: "rel_address" */
  delete_rel_address_by_pk?: Maybe<Rel_Address>;
  /** delete data from the table: "rel_metadata" */
  delete_rel_metadata?: Maybe<Rel_Metadata_Mutation_Response>;
  /** delete single row from the table: "rel_metadata" */
  delete_rel_metadata_by_pk?: Maybe<Rel_Metadata>;
  /** delete data from the table: "trip" */
  delete_trip?: Maybe<Trip_Mutation_Response>;
  /** delete single row from the table: "trip" */
  delete_trip_by_pk?: Maybe<Trip>;
  /** delete data from the table: "trip_employee" */
  delete_trip_employee?: Maybe<Trip_Employee_Mutation_Response>;
  /** delete single row from the table: "trip_employee" */
  delete_trip_employee_by_pk?: Maybe<Trip_Employee>;
  /** delete data from the table: "trip_guest" */
  delete_trip_guest?: Maybe<Trip_Guest_Mutation_Response>;
  /** delete single row from the table: "trip_guest" */
  delete_trip_guest_by_pk?: Maybe<Trip_Guest>;
  /** delete data from the table: "trip_guest_invite" */
  delete_trip_guest_invite?: Maybe<Trip_Guest_Invite_Mutation_Response>;
  /** delete single row from the table: "trip_guest_invite" */
  delete_trip_guest_invite_by_pk?: Maybe<Trip_Guest_Invite>;
  /** delete data from the table: "trip_type" */
  delete_trip_type?: Maybe<Trip_Type_Mutation_Response>;
  /** delete single row from the table: "trip_type" */
  delete_trip_type_by_pk?: Maybe<Trip_Type>;
  /** delete data from the table: "user_preference" */
  delete_user_preference?: Maybe<User_Preference_Mutation_Response>;
  /** delete single row from the table: "user_preference" */
  delete_user_preference_by_pk?: Maybe<User_Preference>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "entity_dynamic_question" */
  insert_entity_dynamic_question?: Maybe<Entity_Dynamic_Question_Mutation_Response>;
  /** insert data into the table: "entity_dynamic_question_answer" */
  insert_entity_dynamic_question_answer?: Maybe<Entity_Dynamic_Question_Answer_Mutation_Response>;
  /** insert a single row into the table: "entity_dynamic_question_answer" */
  insert_entity_dynamic_question_answer_one?: Maybe<Entity_Dynamic_Question_Answer>;
  /** insert a single row into the table: "entity_dynamic_question" */
  insert_entity_dynamic_question_one?: Maybe<Entity_Dynamic_Question>;
  /** insert data into the table: "entity_files" */
  insert_entity_files?: Maybe<Entity_Files_Mutation_Response>;
  /** insert a single row into the table: "entity_files" */
  insert_entity_files_one?: Maybe<Entity_Files>;
  /** insert data into the table: "entity_fixed_custom_question" */
  insert_entity_fixed_custom_question?: Maybe<Entity_Fixed_Custom_Question_Mutation_Response>;
  /** insert data into the table: "entity_fixed_custom_question_answer" */
  insert_entity_fixed_custom_question_answer?: Maybe<Entity_Fixed_Custom_Question_Answer_Mutation_Response>;
  /** insert a single row into the table: "entity_fixed_custom_question_answer" */
  insert_entity_fixed_custom_question_answer_one?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  /** insert a single row into the table: "entity_fixed_custom_question" */
  insert_entity_fixed_custom_question_one?: Maybe<Entity_Fixed_Custom_Question>;
  /** insert data into the table: "facility" */
  insert_facility?: Maybe<Facility_Mutation_Response>;
  /** insert data into the table: "facility_attribute" */
  insert_facility_attribute?: Maybe<Facility_Attribute_Mutation_Response>;
  /** insert a single row into the table: "facility_attribute" */
  insert_facility_attribute_one?: Maybe<Facility_Attribute>;
  /** insert a single row into the table: "facility" */
  insert_facility_one?: Maybe<Facility>;
  /** insert data into the table: "facility_type" */
  insert_facility_type?: Maybe<Facility_Type_Mutation_Response>;
  /** insert a single row into the table: "facility_type" */
  insert_facility_type_one?: Maybe<Facility_Type>;
  /** insert data into the table: "rel_address" */
  insert_rel_address?: Maybe<Rel_Address_Mutation_Response>;
  /** insert a single row into the table: "rel_address" */
  insert_rel_address_one?: Maybe<Rel_Address>;
  /** insert data into the table: "rel_metadata" */
  insert_rel_metadata?: Maybe<Rel_Metadata_Mutation_Response>;
  /** insert a single row into the table: "rel_metadata" */
  insert_rel_metadata_one?: Maybe<Rel_Metadata>;
  /** insert data into the table: "trip" */
  insert_trip?: Maybe<Trip_Mutation_Response>;
  /** insert data into the table: "trip_employee" */
  insert_trip_employee?: Maybe<Trip_Employee_Mutation_Response>;
  /** insert a single row into the table: "trip_employee" */
  insert_trip_employee_one?: Maybe<Trip_Employee>;
  /** insert data into the table: "trip_guest" */
  insert_trip_guest?: Maybe<Trip_Guest_Mutation_Response>;
  /** insert data into the table: "trip_guest_invite" */
  insert_trip_guest_invite?: Maybe<Trip_Guest_Invite_Mutation_Response>;
  /** insert a single row into the table: "trip_guest_invite" */
  insert_trip_guest_invite_one?: Maybe<Trip_Guest_Invite>;
  /** insert a single row into the table: "trip_guest" */
  insert_trip_guest_one?: Maybe<Trip_Guest>;
  /** insert a single row into the table: "trip" */
  insert_trip_one?: Maybe<Trip>;
  /** insert data into the table: "trip_type" */
  insert_trip_type?: Maybe<Trip_Type_Mutation_Response>;
  /** insert a single row into the table: "trip_type" */
  insert_trip_type_one?: Maybe<Trip_Type>;
  /** insert data into the table: "user_preference" */
  insert_user_preference?: Maybe<User_Preference_Mutation_Response>;
  /** insert a single row into the table: "user_preference" */
  insert_user_preference_one?: Maybe<User_Preference>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "entity_dynamic_question" */
  update_entity_dynamic_question?: Maybe<Entity_Dynamic_Question_Mutation_Response>;
  /** update data of the table: "entity_dynamic_question_answer" */
  update_entity_dynamic_question_answer?: Maybe<Entity_Dynamic_Question_Answer_Mutation_Response>;
  /** update single row of the table: "entity_dynamic_question_answer" */
  update_entity_dynamic_question_answer_by_pk?: Maybe<Entity_Dynamic_Question_Answer>;
  /** update multiples rows of table: "entity_dynamic_question_answer" */
  update_entity_dynamic_question_answer_many?: Maybe<
    Array<Maybe<Entity_Dynamic_Question_Answer_Mutation_Response>>
  >;
  /** update single row of the table: "entity_dynamic_question" */
  update_entity_dynamic_question_by_pk?: Maybe<Entity_Dynamic_Question>;
  /** update multiples rows of table: "entity_dynamic_question" */
  update_entity_dynamic_question_many?: Maybe<Array<Maybe<Entity_Dynamic_Question_Mutation_Response>>>;
  /** update data of the table: "entity_files" */
  update_entity_files?: Maybe<Entity_Files_Mutation_Response>;
  /** update single row of the table: "entity_files" */
  update_entity_files_by_pk?: Maybe<Entity_Files>;
  /** update multiples rows of table: "entity_files" */
  update_entity_files_many?: Maybe<Array<Maybe<Entity_Files_Mutation_Response>>>;
  /** update data of the table: "entity_fixed_custom_question" */
  update_entity_fixed_custom_question?: Maybe<Entity_Fixed_Custom_Question_Mutation_Response>;
  /** update data of the table: "entity_fixed_custom_question_answer" */
  update_entity_fixed_custom_question_answer?: Maybe<Entity_Fixed_Custom_Question_Answer_Mutation_Response>;
  /** update single row of the table: "entity_fixed_custom_question_answer" */
  update_entity_fixed_custom_question_answer_by_pk?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  /** update multiples rows of table: "entity_fixed_custom_question_answer" */
  update_entity_fixed_custom_question_answer_many?: Maybe<
    Array<Maybe<Entity_Fixed_Custom_Question_Answer_Mutation_Response>>
  >;
  /** update single row of the table: "entity_fixed_custom_question" */
  update_entity_fixed_custom_question_by_pk?: Maybe<Entity_Fixed_Custom_Question>;
  /** update multiples rows of table: "entity_fixed_custom_question" */
  update_entity_fixed_custom_question_many?: Maybe<
    Array<Maybe<Entity_Fixed_Custom_Question_Mutation_Response>>
  >;
  /** update data of the table: "facility" */
  update_facility?: Maybe<Facility_Mutation_Response>;
  /** update data of the table: "facility_attribute" */
  update_facility_attribute?: Maybe<Facility_Attribute_Mutation_Response>;
  /** update single row of the table: "facility_attribute" */
  update_facility_attribute_by_pk?: Maybe<Facility_Attribute>;
  /** update multiples rows of table: "facility_attribute" */
  update_facility_attribute_many?: Maybe<Array<Maybe<Facility_Attribute_Mutation_Response>>>;
  /** update single row of the table: "facility" */
  update_facility_by_pk?: Maybe<Facility>;
  /** update multiples rows of table: "facility" */
  update_facility_many?: Maybe<Array<Maybe<Facility_Mutation_Response>>>;
  /** update data of the table: "facility_type" */
  update_facility_type?: Maybe<Facility_Type_Mutation_Response>;
  /** update single row of the table: "facility_type" */
  update_facility_type_by_pk?: Maybe<Facility_Type>;
  /** update multiples rows of table: "facility_type" */
  update_facility_type_many?: Maybe<Array<Maybe<Facility_Type_Mutation_Response>>>;
  /** update data of the table: "rel_address" */
  update_rel_address?: Maybe<Rel_Address_Mutation_Response>;
  /** update single row of the table: "rel_address" */
  update_rel_address_by_pk?: Maybe<Rel_Address>;
  /** update multiples rows of table: "rel_address" */
  update_rel_address_many?: Maybe<Array<Maybe<Rel_Address_Mutation_Response>>>;
  /** update data of the table: "rel_metadata" */
  update_rel_metadata?: Maybe<Rel_Metadata_Mutation_Response>;
  /** update single row of the table: "rel_metadata" */
  update_rel_metadata_by_pk?: Maybe<Rel_Metadata>;
  /** update multiples rows of table: "rel_metadata" */
  update_rel_metadata_many?: Maybe<Array<Maybe<Rel_Metadata_Mutation_Response>>>;
  /** update data of the table: "trip" */
  update_trip?: Maybe<Trip_Mutation_Response>;
  /** update single row of the table: "trip" */
  update_trip_by_pk?: Maybe<Trip>;
  /** update data of the table: "trip_employee" */
  update_trip_employee?: Maybe<Trip_Employee_Mutation_Response>;
  /** update single row of the table: "trip_employee" */
  update_trip_employee_by_pk?: Maybe<Trip_Employee>;
  /** update multiples rows of table: "trip_employee" */
  update_trip_employee_many?: Maybe<Array<Maybe<Trip_Employee_Mutation_Response>>>;
  /** update data of the table: "trip_guest" */
  update_trip_guest?: Maybe<Trip_Guest_Mutation_Response>;
  /** update single row of the table: "trip_guest" */
  update_trip_guest_by_pk?: Maybe<Trip_Guest>;
  /** update data of the table: "trip_guest_invite" */
  update_trip_guest_invite?: Maybe<Trip_Guest_Invite_Mutation_Response>;
  /** update single row of the table: "trip_guest_invite" */
  update_trip_guest_invite_by_pk?: Maybe<Trip_Guest_Invite>;
  /** update multiples rows of table: "trip_guest_invite" */
  update_trip_guest_invite_many?: Maybe<Array<Maybe<Trip_Guest_Invite_Mutation_Response>>>;
  /** update multiples rows of table: "trip_guest" */
  update_trip_guest_many?: Maybe<Array<Maybe<Trip_Guest_Mutation_Response>>>;
  /** update multiples rows of table: "trip" */
  update_trip_many?: Maybe<Array<Maybe<Trip_Mutation_Response>>>;
  /** update data of the table: "trip_type" */
  update_trip_type?: Maybe<Trip_Type_Mutation_Response>;
  /** update single row of the table: "trip_type" */
  update_trip_type_by_pk?: Maybe<Trip_Type>;
  /** update multiples rows of table: "trip_type" */
  update_trip_type_many?: Maybe<Array<Maybe<Trip_Type_Mutation_Response>>>;
  /** update data of the table: "user_preference" */
  update_user_preference?: Maybe<User_Preference_Mutation_Response>;
  /** update single row of the table: "user_preference" */
  update_user_preference_by_pk?: Maybe<User_Preference>;
  /** update multiples rows of table: "user_preference" */
  update_user_preference_many?: Maybe<Array<Maybe<User_Preference_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Entity_Dynamic_QuestionArgs = {
  where: Entity_Dynamic_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Entity_Dynamic_Question_AnswerArgs = {
  where: Entity_Dynamic_Question_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Entity_Dynamic_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Entity_Dynamic_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Entity_FilesArgs = {
  where: Entity_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Entity_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Entity_Fixed_Custom_QuestionArgs = {
  where: Entity_Fixed_Custom_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Entity_Fixed_Custom_Question_AnswerArgs = {
  where: Entity_Fixed_Custom_Question_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Entity_Fixed_Custom_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Entity_Fixed_Custom_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_FacilityArgs = {
  where: Facility_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Facility_AttributeArgs = {
  where: Facility_Attribute_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Facility_Attribute_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Facility_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Facility_TypeArgs = {
  where: Facility_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Facility_Type_By_PkArgs = {
  key: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Rel_AddressArgs = {
  where: Rel_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rel_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Rel_MetadataArgs = {
  where: Rel_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rel_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_TripArgs = {
  where: Trip_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trip_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Trip_EmployeeArgs = {
  where: Trip_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trip_Employee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Trip_GuestArgs = {
  where: Trip_Guest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trip_Guest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Trip_Guest_InviteArgs = {
  where: Trip_Guest_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trip_Guest_Invite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Trip_TypeArgs = {
  where: Trip_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trip_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_User_PreferenceArgs = {
  where: User_Preference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Preference_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Dynamic_QuestionArgs = {
  objects: Array<Entity_Dynamic_Question_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Dynamic_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Dynamic_Question_AnswerArgs = {
  objects: Array<Entity_Dynamic_Question_Answer_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Dynamic_Question_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Dynamic_Question_Answer_OneArgs = {
  object: Entity_Dynamic_Question_Answer_Insert_Input;
  on_conflict?: InputMaybe<Entity_Dynamic_Question_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Dynamic_Question_OneArgs = {
  object: Entity_Dynamic_Question_Insert_Input;
  on_conflict?: InputMaybe<Entity_Dynamic_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_FilesArgs = {
  objects: Array<Entity_Files_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Files_OneArgs = {
  object: Entity_Files_Insert_Input;
  on_conflict?: InputMaybe<Entity_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Fixed_Custom_QuestionArgs = {
  objects: Array<Entity_Fixed_Custom_Question_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Fixed_Custom_Question_AnswerArgs = {
  objects: Array<Entity_Fixed_Custom_Question_Answer_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Fixed_Custom_Question_Answer_OneArgs = {
  object: Entity_Fixed_Custom_Question_Answer_Insert_Input;
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Entity_Fixed_Custom_Question_OneArgs = {
  object: Entity_Fixed_Custom_Question_Insert_Input;
  on_conflict?: InputMaybe<Entity_Fixed_Custom_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FacilityArgs = {
  objects: Array<Facility_Insert_Input>;
  on_conflict?: InputMaybe<Facility_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Facility_AttributeArgs = {
  objects: Array<Facility_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Facility_Attribute_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Facility_Attribute_OneArgs = {
  object: Facility_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Facility_Attribute_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Facility_OneArgs = {
  object: Facility_Insert_Input;
  on_conflict?: InputMaybe<Facility_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Facility_TypeArgs = {
  objects: Array<Facility_Type_Insert_Input>;
  on_conflict?: InputMaybe<Facility_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Facility_Type_OneArgs = {
  object: Facility_Type_Insert_Input;
  on_conflict?: InputMaybe<Facility_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rel_AddressArgs = {
  objects: Array<Rel_Address_Insert_Input>;
  on_conflict?: InputMaybe<Rel_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rel_Address_OneArgs = {
  object: Rel_Address_Insert_Input;
  on_conflict?: InputMaybe<Rel_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rel_MetadataArgs = {
  objects: Array<Rel_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Rel_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rel_Metadata_OneArgs = {
  object: Rel_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Rel_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TripArgs = {
  objects: Array<Trip_Insert_Input>;
  on_conflict?: InputMaybe<Trip_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_EmployeeArgs = {
  objects: Array<Trip_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Trip_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_Employee_OneArgs = {
  object: Trip_Employee_Insert_Input;
  on_conflict?: InputMaybe<Trip_Employee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_GuestArgs = {
  objects: Array<Trip_Guest_Insert_Input>;
  on_conflict?: InputMaybe<Trip_Guest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_Guest_InviteArgs = {
  objects: Array<Trip_Guest_Invite_Insert_Input>;
  on_conflict?: InputMaybe<Trip_Guest_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_Guest_Invite_OneArgs = {
  object: Trip_Guest_Invite_Insert_Input;
  on_conflict?: InputMaybe<Trip_Guest_Invite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_Guest_OneArgs = {
  object: Trip_Guest_Insert_Input;
  on_conflict?: InputMaybe<Trip_Guest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_OneArgs = {
  object: Trip_Insert_Input;
  on_conflict?: InputMaybe<Trip_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_TypeArgs = {
  objects: Array<Trip_Type_Insert_Input>;
  on_conflict?: InputMaybe<Trip_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trip_Type_OneArgs = {
  object: Trip_Type_Insert_Input;
  on_conflict?: InputMaybe<Trip_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_PreferenceArgs = {
  objects: Array<User_Preference_Insert_Input>;
  on_conflict?: InputMaybe<User_Preference_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Preference_OneArgs = {
  object: User_Preference_Insert_Input;
  on_conflict?: InputMaybe<User_Preference_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_QuestionArgs = {
  _set?: InputMaybe<Entity_Dynamic_Question_Set_Input>;
  where: Entity_Dynamic_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_Question_AnswerArgs = {
  _inc?: InputMaybe<Entity_Dynamic_Question_Answer_Inc_Input>;
  _set?: InputMaybe<Entity_Dynamic_Question_Answer_Set_Input>;
  where: Entity_Dynamic_Question_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_Question_Answer_By_PkArgs = {
  _inc?: InputMaybe<Entity_Dynamic_Question_Answer_Inc_Input>;
  _set?: InputMaybe<Entity_Dynamic_Question_Answer_Set_Input>;
  pk_columns: Entity_Dynamic_Question_Answer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_Question_Answer_ManyArgs = {
  updates: Array<Entity_Dynamic_Question_Answer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_Question_By_PkArgs = {
  _set?: InputMaybe<Entity_Dynamic_Question_Set_Input>;
  pk_columns: Entity_Dynamic_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Dynamic_Question_ManyArgs = {
  updates: Array<Entity_Dynamic_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_FilesArgs = {
  _set?: InputMaybe<Entity_Files_Set_Input>;
  where: Entity_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Files_By_PkArgs = {
  _set?: InputMaybe<Entity_Files_Set_Input>;
  pk_columns: Entity_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Files_ManyArgs = {
  updates: Array<Entity_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_QuestionArgs = {
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Set_Input>;
  where: Entity_Fixed_Custom_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_Question_AnswerArgs = {
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Set_Input>;
  where: Entity_Fixed_Custom_Question_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_Question_Answer_By_PkArgs = {
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Set_Input>;
  pk_columns: Entity_Fixed_Custom_Question_Answer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_Question_Answer_ManyArgs = {
  updates: Array<Entity_Fixed_Custom_Question_Answer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_Question_By_PkArgs = {
  _set?: InputMaybe<Entity_Fixed_Custom_Question_Set_Input>;
  pk_columns: Entity_Fixed_Custom_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Entity_Fixed_Custom_Question_ManyArgs = {
  updates: Array<Entity_Fixed_Custom_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FacilityArgs = {
  _append?: InputMaybe<Facility_Append_Input>;
  _delete_at_path?: InputMaybe<Facility_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Facility_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Facility_Delete_Key_Input>;
  _prepend?: InputMaybe<Facility_Prepend_Input>;
  _set?: InputMaybe<Facility_Set_Input>;
  where: Facility_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_AttributeArgs = {
  _set?: InputMaybe<Facility_Attribute_Set_Input>;
  where: Facility_Attribute_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_Attribute_By_PkArgs = {
  _set?: InputMaybe<Facility_Attribute_Set_Input>;
  pk_columns: Facility_Attribute_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_Attribute_ManyArgs = {
  updates: Array<Facility_Attribute_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_By_PkArgs = {
  _append?: InputMaybe<Facility_Append_Input>;
  _delete_at_path?: InputMaybe<Facility_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Facility_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Facility_Delete_Key_Input>;
  _prepend?: InputMaybe<Facility_Prepend_Input>;
  _set?: InputMaybe<Facility_Set_Input>;
  pk_columns: Facility_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_ManyArgs = {
  updates: Array<Facility_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_TypeArgs = {
  _set?: InputMaybe<Facility_Type_Set_Input>;
  where: Facility_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_Type_By_PkArgs = {
  _set?: InputMaybe<Facility_Type_Set_Input>;
  pk_columns: Facility_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Facility_Type_ManyArgs = {
  updates: Array<Facility_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_AddressArgs = {
  _set?: InputMaybe<Rel_Address_Set_Input>;
  where: Rel_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_Address_By_PkArgs = {
  _set?: InputMaybe<Rel_Address_Set_Input>;
  pk_columns: Rel_Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_Address_ManyArgs = {
  updates: Array<Rel_Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_MetadataArgs = {
  _set?: InputMaybe<Rel_Metadata_Set_Input>;
  where: Rel_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_Metadata_By_PkArgs = {
  _set?: InputMaybe<Rel_Metadata_Set_Input>;
  pk_columns: Rel_Metadata_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Rel_Metadata_ManyArgs = {
  updates: Array<Rel_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TripArgs = {
  _set?: InputMaybe<Trip_Set_Input>;
  where: Trip_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_By_PkArgs = {
  _set?: InputMaybe<Trip_Set_Input>;
  pk_columns: Trip_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_EmployeeArgs = {
  _set?: InputMaybe<Trip_Employee_Set_Input>;
  where: Trip_Employee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Employee_By_PkArgs = {
  _set?: InputMaybe<Trip_Employee_Set_Input>;
  pk_columns: Trip_Employee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Employee_ManyArgs = {
  updates: Array<Trip_Employee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_GuestArgs = {
  _set?: InputMaybe<Trip_Guest_Set_Input>;
  where: Trip_Guest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Guest_By_PkArgs = {
  _set?: InputMaybe<Trip_Guest_Set_Input>;
  pk_columns: Trip_Guest_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Guest_InviteArgs = {
  _set?: InputMaybe<Trip_Guest_Invite_Set_Input>;
  where: Trip_Guest_Invite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Guest_Invite_By_PkArgs = {
  _set?: InputMaybe<Trip_Guest_Invite_Set_Input>;
  pk_columns: Trip_Guest_Invite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Guest_Invite_ManyArgs = {
  updates: Array<Trip_Guest_Invite_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Guest_ManyArgs = {
  updates: Array<Trip_Guest_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_ManyArgs = {
  updates: Array<Trip_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_TypeArgs = {
  _set?: InputMaybe<Trip_Type_Set_Input>;
  where: Trip_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Type_By_PkArgs = {
  _set?: InputMaybe<Trip_Type_Set_Input>;
  pk_columns: Trip_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trip_Type_ManyArgs = {
  updates: Array<Trip_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_PreferenceArgs = {
  _set?: InputMaybe<User_Preference_Set_Input>;
  where: User_Preference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Preference_By_PkArgs = {
  _set?: InputMaybe<User_Preference_Set_Input>;
  pk_columns: User_Preference_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Preference_ManyArgs = {
  updates: Array<User_Preference_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "entity_dynamic_question" */
  entity_dynamic_question: Array<Entity_Dynamic_Question>;
  /** fetch aggregated fields from the table: "entity_dynamic_question" */
  entity_dynamic_question_aggregate: Entity_Dynamic_Question_Aggregate;
  /** fetch data from the table: "entity_dynamic_question_answer" */
  entity_dynamic_question_answer: Array<Entity_Dynamic_Question_Answer>;
  /** fetch aggregated fields from the table: "entity_dynamic_question_answer" */
  entity_dynamic_question_answer_aggregate: Entity_Dynamic_Question_Answer_Aggregate;
  /** fetch data from the table: "entity_dynamic_question_answer" using primary key columns */
  entity_dynamic_question_answer_by_pk?: Maybe<Entity_Dynamic_Question_Answer>;
  /** fetch data from the table: "entity_dynamic_question" using primary key columns */
  entity_dynamic_question_by_pk?: Maybe<Entity_Dynamic_Question>;
  /** fetch data from the table: "entity_files" */
  entity_files: Array<Entity_Files>;
  /** fetch aggregated fields from the table: "entity_files" */
  entity_files_aggregate: Entity_Files_Aggregate;
  /** fetch data from the table: "entity_files" using primary key columns */
  entity_files_by_pk?: Maybe<Entity_Files>;
  /** fetch data from the table: "entity_fixed_custom_question" */
  entity_fixed_custom_question: Array<Entity_Fixed_Custom_Question>;
  /** fetch aggregated fields from the table: "entity_fixed_custom_question" */
  entity_fixed_custom_question_aggregate: Entity_Fixed_Custom_Question_Aggregate;
  /** fetch data from the table: "entity_fixed_custom_question_answer" */
  entity_fixed_custom_question_answer: Array<Entity_Fixed_Custom_Question_Answer>;
  /** fetch aggregated fields from the table: "entity_fixed_custom_question_answer" */
  entity_fixed_custom_question_answer_aggregate: Entity_Fixed_Custom_Question_Answer_Aggregate;
  /** fetch data from the table: "entity_fixed_custom_question_answer" using primary key columns */
  entity_fixed_custom_question_answer_by_pk?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  /** fetch data from the table: "entity_fixed_custom_question" using primary key columns */
  entity_fixed_custom_question_by_pk?: Maybe<Entity_Fixed_Custom_Question>;
  /** fetch data from the table: "facility" */
  facility: Array<Facility>;
  /** fetch aggregated fields from the table: "facility" */
  facility_aggregate: Facility_Aggregate;
  /** fetch data from the table: "facility_attribute" */
  facility_attribute: Array<Facility_Attribute>;
  /** fetch aggregated fields from the table: "facility_attribute" */
  facility_attribute_aggregate: Facility_Attribute_Aggregate;
  /** fetch data from the table: "facility_attribute" using primary key columns */
  facility_attribute_by_pk?: Maybe<Facility_Attribute>;
  /** fetch data from the table: "facility" using primary key columns */
  facility_by_pk?: Maybe<Facility>;
  /** fetch data from the table: "facility_type" */
  facility_type: Array<Facility_Type>;
  /** fetch aggregated fields from the table: "facility_type" */
  facility_type_aggregate: Facility_Type_Aggregate;
  /** fetch data from the table: "facility_type" using primary key columns */
  facility_type_by_pk?: Maybe<Facility_Type>;
  getUserProfile?: Maybe<SampleOutput>;
  /** fetch data from the table: "rel_address" */
  rel_address: Array<Rel_Address>;
  /** fetch aggregated fields from the table: "rel_address" */
  rel_address_aggregate: Rel_Address_Aggregate;
  /** fetch data from the table: "rel_address" using primary key columns */
  rel_address_by_pk?: Maybe<Rel_Address>;
  /** An array relationship */
  rel_metadata: Array<Rel_Metadata>;
  /** An aggregate relationship */
  rel_metadata_aggregate: Rel_Metadata_Aggregate;
  /** fetch data from the table: "rel_metadata" using primary key columns */
  rel_metadata_by_pk?: Maybe<Rel_Metadata>;
  /** fetch data from the table: "trip" */
  trip: Array<Trip>;
  /** fetch aggregated fields from the table: "trip" */
  trip_aggregate: Trip_Aggregate;
  /** fetch data from the table: "trip" using primary key columns */
  trip_by_pk?: Maybe<Trip>;
  /** fetch data from the table: "trip_employee" */
  trip_employee: Array<Trip_Employee>;
  /** fetch aggregated fields from the table: "trip_employee" */
  trip_employee_aggregate: Trip_Employee_Aggregate;
  /** fetch data from the table: "trip_employee" using primary key columns */
  trip_employee_by_pk?: Maybe<Trip_Employee>;
  /** fetch data from the table: "trip_guest" */
  trip_guest: Array<Trip_Guest>;
  /** fetch aggregated fields from the table: "trip_guest" */
  trip_guest_aggregate: Trip_Guest_Aggregate;
  /** fetch data from the table: "trip_guest" using primary key columns */
  trip_guest_by_pk?: Maybe<Trip_Guest>;
  /** fetch data from the table: "trip_guest_invite" */
  trip_guest_invite: Array<Trip_Guest_Invite>;
  /** fetch aggregated fields from the table: "trip_guest_invite" */
  trip_guest_invite_aggregate: Trip_Guest_Invite_Aggregate;
  /** fetch data from the table: "trip_guest_invite" using primary key columns */
  trip_guest_invite_by_pk?: Maybe<Trip_Guest_Invite>;
  /** fetch data from the table: "trip_type" */
  trip_type: Array<Trip_Type>;
  /** fetch aggregated fields from the table: "trip_type" */
  trip_type_aggregate: Trip_Type_Aggregate;
  /** fetch data from the table: "trip_type" using primary key columns */
  trip_type_by_pk?: Maybe<Trip_Type>;
  /** fetch data from the table: "user_preference" */
  user_preference: Array<User_Preference>;
  /** fetch aggregated fields from the table: "user_preference" */
  user_preference_aggregate: User_Preference_Aggregate;
  /** fetch data from the table: "user_preference" using primary key columns */
  user_preference_by_pk?: Maybe<User_Preference>;
};

export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEntity_Dynamic_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

export type Query_RootEntity_Dynamic_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

export type Query_RootEntity_Dynamic_Question_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

export type Query_RootEntity_Dynamic_Question_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

export type Query_RootEntity_Dynamic_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEntity_Dynamic_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEntity_FilesArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

export type Query_RootEntity_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

export type Query_RootEntity_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEntity_Fixed_Custom_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

export type Query_RootEntity_Fixed_Custom_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

export type Query_RootEntity_Fixed_Custom_Question_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

export type Query_RootEntity_Fixed_Custom_Question_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

export type Query_RootEntity_Fixed_Custom_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEntity_Fixed_Custom_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFacilityArgs = {
  distinct_on?: InputMaybe<Array<Facility_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Order_By>>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

export type Query_RootFacility_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Order_By>>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

export type Query_RootFacility_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

export type Query_RootFacility_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

export type Query_RootFacility_Attribute_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFacility_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFacility_TypeArgs = {
  distinct_on?: InputMaybe<Array<Facility_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Type_Order_By>>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

export type Query_RootFacility_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Type_Order_By>>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

export type Query_RootFacility_Type_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Query_RootGetUserProfileArgs = {
  user_id: Scalars['uuid']['input'];
};

export type Query_RootRel_AddressArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

export type Query_RootRel_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

export type Query_RootRel_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRel_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

export type Query_RootRel_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

export type Query_RootRel_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTripArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type Query_RootTrip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type Query_RootTrip_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTrip_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

export type Query_RootTrip_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

export type Query_RootTrip_Employee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTrip_GuestArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

export type Query_RootTrip_Guest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

export type Query_RootTrip_Guest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTrip_Guest_InviteArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Invite_Order_By>>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

export type Query_RootTrip_Guest_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Invite_Order_By>>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

export type Query_RootTrip_Guest_Invite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTrip_TypeArgs = {
  distinct_on?: InputMaybe<Array<Trip_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Type_Order_By>>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

export type Query_RootTrip_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Type_Order_By>>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

export type Query_RootTrip_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};

export type Query_RootUser_PreferenceArgs = {
  distinct_on?: InputMaybe<Array<User_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preference_Order_By>>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

export type Query_RootUser_Preference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preference_Order_By>>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

export type Query_RootUser_Preference_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "rel_address" */
export type Rel_Address = {
  __typename?: 'rel_address';
  /** An object relationship */
  address: Address;
  address_id: Scalars['uuid']['output'];
  /** An object relationship */
  facility?: Maybe<Facility>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "rel_address" */
export type Rel_Address_Aggregate = {
  __typename?: 'rel_address_aggregate';
  aggregate?: Maybe<Rel_Address_Aggregate_Fields>;
  nodes: Array<Rel_Address>;
};

export type Rel_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rel_Address_Aggregate_Bool_Exp_Count>;
};

export type Rel_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rel_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rel_Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rel_address" */
export type Rel_Address_Aggregate_Fields = {
  __typename?: 'rel_address_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rel_Address_Max_Fields>;
  min?: Maybe<Rel_Address_Min_Fields>;
};

/** aggregate fields of "rel_address" */
export type Rel_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rel_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rel_address" */
export type Rel_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rel_Address_Max_Order_By>;
  min?: InputMaybe<Rel_Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rel_address" */
export type Rel_Address_Arr_Rel_Insert_Input = {
  data: Array<Rel_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rel_Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rel_address". All fields are combined with a logical 'AND'. */
export type Rel_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Rel_Address_Bool_Exp>>;
  _not?: InputMaybe<Rel_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Rel_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  facility?: InputMaybe<Facility_Bool_Exp>;
  facility_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rel_address" */
export enum Rel_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  RelAddressPkey = 'rel_address_pkey',
}

/** input type for inserting data into table "rel_address" */
export type Rel_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']['input']>;
  facility?: InputMaybe<Facility_Obj_Rel_Insert_Input>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Rel_Address_Max_Fields = {
  __typename?: 'rel_address_max_fields';
  address_id?: Maybe<Scalars['uuid']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "rel_address" */
export type Rel_Address_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rel_Address_Min_Fields = {
  __typename?: 'rel_address_min_fields';
  address_id?: Maybe<Scalars['uuid']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "rel_address" */
export type Rel_Address_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rel_address" */
export type Rel_Address_Mutation_Response = {
  __typename?: 'rel_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rel_Address>;
};

/** on_conflict condition type for table "rel_address" */
export type Rel_Address_On_Conflict = {
  constraint: Rel_Address_Constraint;
  update_columns?: Array<Rel_Address_Update_Column>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "rel_address". */
export type Rel_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  facility?: InputMaybe<Facility_Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rel_address */
export type Rel_Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rel_address" */
export enum Rel_Address_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "rel_address" */
export type Rel_Address_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "rel_address" */
export type Rel_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rel_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rel_Address_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['uuid']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "rel_address" */
export enum Rel_Address_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
}

export type Rel_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rel_Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rel_Address_Bool_Exp;
};

/** columns and relationships of "rel_metadata" */
export type Rel_Metadata = {
  __typename?: 'rel_metadata';
  /** An object relationship */
  facility?: Maybe<Facility>;
  /** An object relationship */
  facility_attribute?: Maybe<Facility_Attribute>;
  facility_attribute_id?: Maybe<Scalars['uuid']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  organisation_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  trip?: Maybe<Trip>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "rel_metadata" */
export type Rel_Metadata_Aggregate = {
  __typename?: 'rel_metadata_aggregate';
  aggregate?: Maybe<Rel_Metadata_Aggregate_Fields>;
  nodes: Array<Rel_Metadata>;
};

export type Rel_Metadata_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rel_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Rel_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rel_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rel_metadata" */
export type Rel_Metadata_Aggregate_Fields = {
  __typename?: 'rel_metadata_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rel_Metadata_Max_Fields>;
  min?: Maybe<Rel_Metadata_Min_Fields>;
};

/** aggregate fields of "rel_metadata" */
export type Rel_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rel_metadata" */
export type Rel_Metadata_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rel_Metadata_Max_Order_By>;
  min?: InputMaybe<Rel_Metadata_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rel_metadata" */
export type Rel_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Rel_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rel_Metadata_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rel_metadata". All fields are combined with a logical 'AND'. */
export type Rel_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Rel_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Rel_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Rel_Metadata_Bool_Exp>>;
  facility?: InputMaybe<Facility_Bool_Exp>;
  facility_attribute?: InputMaybe<Facility_Attribute_Bool_Exp>;
  facility_attribute_id?: InputMaybe<Uuid_Comparison_Exp>;
  facility_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organisation_id?: InputMaybe<Uuid_Comparison_Exp>;
  trip?: InputMaybe<Trip_Bool_Exp>;
  trip_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "rel_metadata" */
export enum Rel_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  RelMetadataPkey = 'rel_metadata_pkey',
}

/** input type for inserting data into table "rel_metadata" */
export type Rel_Metadata_Insert_Input = {
  facility?: InputMaybe<Facility_Obj_Rel_Insert_Input>;
  facility_attribute?: InputMaybe<Facility_Attribute_Obj_Rel_Insert_Input>;
  facility_attribute_id?: InputMaybe<Scalars['uuid']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organisation_id?: InputMaybe<Scalars['uuid']['input']>;
  trip?: InputMaybe<Trip_Obj_Rel_Insert_Input>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Rel_Metadata_Max_Fields = {
  __typename?: 'rel_metadata_max_fields';
  facility_attribute_id?: Maybe<Scalars['uuid']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organisation_id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "rel_metadata" */
export type Rel_Metadata_Max_Order_By = {
  facility_attribute_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rel_Metadata_Min_Fields = {
  __typename?: 'rel_metadata_min_fields';
  facility_attribute_id?: Maybe<Scalars['uuid']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organisation_id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "rel_metadata" */
export type Rel_Metadata_Min_Order_By = {
  facility_attribute_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rel_metadata" */
export type Rel_Metadata_Mutation_Response = {
  __typename?: 'rel_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rel_Metadata>;
};

/** on_conflict condition type for table "rel_metadata" */
export type Rel_Metadata_On_Conflict = {
  constraint: Rel_Metadata_Constraint;
  update_columns?: Array<Rel_Metadata_Update_Column>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "rel_metadata". */
export type Rel_Metadata_Order_By = {
  facility?: InputMaybe<Facility_Order_By>;
  facility_attribute?: InputMaybe<Facility_Attribute_Order_By>;
  facility_attribute_id?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organisation_id?: InputMaybe<Order_By>;
  trip?: InputMaybe<Trip_Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rel_metadata */
export type Rel_Metadata_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rel_metadata" */
export enum Rel_Metadata_Select_Column {
  /** column name */
  FacilityAttributeId = 'facility_attribute_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "rel_metadata" */
export type Rel_Metadata_Set_Input = {
  facility_attribute_id?: InputMaybe<Scalars['uuid']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organisation_id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "rel_metadata" */
export type Rel_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rel_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rel_Metadata_Stream_Cursor_Value_Input = {
  facility_attribute_id?: InputMaybe<Scalars['uuid']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organisation_id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "rel_metadata" */
export enum Rel_Metadata_Update_Column {
  /** column name */
  FacilityAttributeId = 'facility_attribute_id',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

export type Rel_Metadata_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rel_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rel_Metadata_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "entity_dynamic_question" */
  entity_dynamic_question: Array<Entity_Dynamic_Question>;
  /** fetch aggregated fields from the table: "entity_dynamic_question" */
  entity_dynamic_question_aggregate: Entity_Dynamic_Question_Aggregate;
  /** fetch data from the table: "entity_dynamic_question_answer" */
  entity_dynamic_question_answer: Array<Entity_Dynamic_Question_Answer>;
  /** fetch aggregated fields from the table: "entity_dynamic_question_answer" */
  entity_dynamic_question_answer_aggregate: Entity_Dynamic_Question_Answer_Aggregate;
  /** fetch data from the table: "entity_dynamic_question_answer" using primary key columns */
  entity_dynamic_question_answer_by_pk?: Maybe<Entity_Dynamic_Question_Answer>;
  /** fetch data from the table in a streaming manner: "entity_dynamic_question_answer" */
  entity_dynamic_question_answer_stream: Array<Entity_Dynamic_Question_Answer>;
  /** fetch data from the table: "entity_dynamic_question" using primary key columns */
  entity_dynamic_question_by_pk?: Maybe<Entity_Dynamic_Question>;
  /** fetch data from the table in a streaming manner: "entity_dynamic_question" */
  entity_dynamic_question_stream: Array<Entity_Dynamic_Question>;
  /** fetch data from the table: "entity_files" */
  entity_files: Array<Entity_Files>;
  /** fetch aggregated fields from the table: "entity_files" */
  entity_files_aggregate: Entity_Files_Aggregate;
  /** fetch data from the table: "entity_files" using primary key columns */
  entity_files_by_pk?: Maybe<Entity_Files>;
  /** fetch data from the table in a streaming manner: "entity_files" */
  entity_files_stream: Array<Entity_Files>;
  /** fetch data from the table: "entity_fixed_custom_question" */
  entity_fixed_custom_question: Array<Entity_Fixed_Custom_Question>;
  /** fetch aggregated fields from the table: "entity_fixed_custom_question" */
  entity_fixed_custom_question_aggregate: Entity_Fixed_Custom_Question_Aggregate;
  /** fetch data from the table: "entity_fixed_custom_question_answer" */
  entity_fixed_custom_question_answer: Array<Entity_Fixed_Custom_Question_Answer>;
  /** fetch aggregated fields from the table: "entity_fixed_custom_question_answer" */
  entity_fixed_custom_question_answer_aggregate: Entity_Fixed_Custom_Question_Answer_Aggregate;
  /** fetch data from the table: "entity_fixed_custom_question_answer" using primary key columns */
  entity_fixed_custom_question_answer_by_pk?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  /** fetch data from the table in a streaming manner: "entity_fixed_custom_question_answer" */
  entity_fixed_custom_question_answer_stream: Array<Entity_Fixed_Custom_Question_Answer>;
  /** fetch data from the table: "entity_fixed_custom_question" using primary key columns */
  entity_fixed_custom_question_by_pk?: Maybe<Entity_Fixed_Custom_Question>;
  /** fetch data from the table in a streaming manner: "entity_fixed_custom_question" */
  entity_fixed_custom_question_stream: Array<Entity_Fixed_Custom_Question>;
  /** fetch data from the table: "facility" */
  facility: Array<Facility>;
  /** fetch aggregated fields from the table: "facility" */
  facility_aggregate: Facility_Aggregate;
  /** fetch data from the table: "facility_attribute" */
  facility_attribute: Array<Facility_Attribute>;
  /** fetch aggregated fields from the table: "facility_attribute" */
  facility_attribute_aggregate: Facility_Attribute_Aggregate;
  /** fetch data from the table: "facility_attribute" using primary key columns */
  facility_attribute_by_pk?: Maybe<Facility_Attribute>;
  /** fetch data from the table in a streaming manner: "facility_attribute" */
  facility_attribute_stream: Array<Facility_Attribute>;
  /** fetch data from the table: "facility" using primary key columns */
  facility_by_pk?: Maybe<Facility>;
  /** fetch data from the table in a streaming manner: "facility" */
  facility_stream: Array<Facility>;
  /** fetch data from the table: "facility_type" */
  facility_type: Array<Facility_Type>;
  /** fetch aggregated fields from the table: "facility_type" */
  facility_type_aggregate: Facility_Type_Aggregate;
  /** fetch data from the table: "facility_type" using primary key columns */
  facility_type_by_pk?: Maybe<Facility_Type>;
  /** fetch data from the table in a streaming manner: "facility_type" */
  facility_type_stream: Array<Facility_Type>;
  /** fetch data from the table: "rel_address" */
  rel_address: Array<Rel_Address>;
  /** fetch aggregated fields from the table: "rel_address" */
  rel_address_aggregate: Rel_Address_Aggregate;
  /** fetch data from the table: "rel_address" using primary key columns */
  rel_address_by_pk?: Maybe<Rel_Address>;
  /** fetch data from the table in a streaming manner: "rel_address" */
  rel_address_stream: Array<Rel_Address>;
  /** An array relationship */
  rel_metadata: Array<Rel_Metadata>;
  /** An aggregate relationship */
  rel_metadata_aggregate: Rel_Metadata_Aggregate;
  /** fetch data from the table: "rel_metadata" using primary key columns */
  rel_metadata_by_pk?: Maybe<Rel_Metadata>;
  /** fetch data from the table in a streaming manner: "rel_metadata" */
  rel_metadata_stream: Array<Rel_Metadata>;
  /** fetch data from the table: "trip" */
  trip: Array<Trip>;
  /** fetch aggregated fields from the table: "trip" */
  trip_aggregate: Trip_Aggregate;
  /** fetch data from the table: "trip" using primary key columns */
  trip_by_pk?: Maybe<Trip>;
  /** fetch data from the table: "trip_employee" */
  trip_employee: Array<Trip_Employee>;
  /** fetch aggregated fields from the table: "trip_employee" */
  trip_employee_aggregate: Trip_Employee_Aggregate;
  /** fetch data from the table: "trip_employee" using primary key columns */
  trip_employee_by_pk?: Maybe<Trip_Employee>;
  /** fetch data from the table in a streaming manner: "trip_employee" */
  trip_employee_stream: Array<Trip_Employee>;
  /** fetch data from the table: "trip_guest" */
  trip_guest: Array<Trip_Guest>;
  /** fetch aggregated fields from the table: "trip_guest" */
  trip_guest_aggregate: Trip_Guest_Aggregate;
  /** fetch data from the table: "trip_guest" using primary key columns */
  trip_guest_by_pk?: Maybe<Trip_Guest>;
  /** fetch data from the table: "trip_guest_invite" */
  trip_guest_invite: Array<Trip_Guest_Invite>;
  /** fetch aggregated fields from the table: "trip_guest_invite" */
  trip_guest_invite_aggregate: Trip_Guest_Invite_Aggregate;
  /** fetch data from the table: "trip_guest_invite" using primary key columns */
  trip_guest_invite_by_pk?: Maybe<Trip_Guest_Invite>;
  /** fetch data from the table in a streaming manner: "trip_guest_invite" */
  trip_guest_invite_stream: Array<Trip_Guest_Invite>;
  /** fetch data from the table in a streaming manner: "trip_guest" */
  trip_guest_stream: Array<Trip_Guest>;
  /** fetch data from the table in a streaming manner: "trip" */
  trip_stream: Array<Trip>;
  /** fetch data from the table: "trip_type" */
  trip_type: Array<Trip_Type>;
  /** fetch aggregated fields from the table: "trip_type" */
  trip_type_aggregate: Trip_Type_Aggregate;
  /** fetch data from the table: "trip_type" using primary key columns */
  trip_type_by_pk?: Maybe<Trip_Type>;
  /** fetch data from the table in a streaming manner: "trip_type" */
  trip_type_stream: Array<Trip_Type>;
  /** fetch data from the table: "user_preference" */
  user_preference: Array<User_Preference>;
  /** fetch aggregated fields from the table: "user_preference" */
  user_preference_aggregate: User_Preference_Aggregate;
  /** fetch data from the table: "user_preference" using primary key columns */
  user_preference_by_pk?: Maybe<User_Preference>;
  /** fetch data from the table in a streaming manner: "user_preference" */
  user_preference_stream: Array<User_Preference>;
};

export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Address_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_Question_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_Question_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Dynamic_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEntity_Dynamic_Question_Answer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Dynamic_Question_Answer_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Dynamic_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Dynamic_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEntity_Dynamic_Question_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Dynamic_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Dynamic_Question_Bool_Exp>;
};

export type Subscription_RootEntity_FilesArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

export type Subscription_RootEntity_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Files_Order_By>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

export type Subscription_RootEntity_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEntity_Files_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Files_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_Question_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_Question_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Entity_Fixed_Custom_Question_Answer_Order_By>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_Question_Answer_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEntity_Fixed_Custom_Question_Answer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Fixed_Custom_Question_Answer_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
};

export type Subscription_RootEntity_Fixed_Custom_Question_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEntity_Fixed_Custom_Question_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Entity_Fixed_Custom_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
};

export type Subscription_RootFacilityArgs = {
  distinct_on?: InputMaybe<Array<Facility_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Order_By>>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

export type Subscription_RootFacility_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Order_By>>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

export type Subscription_RootFacility_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

export type Subscription_RootFacility_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Attribute_Order_By>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

export type Subscription_RootFacility_Attribute_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFacility_Attribute_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Facility_Attribute_Stream_Cursor_Input>>;
  where?: InputMaybe<Facility_Attribute_Bool_Exp>;
};

export type Subscription_RootFacility_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFacility_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Facility_Stream_Cursor_Input>>;
  where?: InputMaybe<Facility_Bool_Exp>;
};

export type Subscription_RootFacility_TypeArgs = {
  distinct_on?: InputMaybe<Array<Facility_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Type_Order_By>>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

export type Subscription_RootFacility_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Facility_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Facility_Type_Order_By>>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

export type Subscription_RootFacility_Type_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Subscription_RootFacility_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Facility_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Facility_Type_Bool_Exp>;
};

export type Subscription_RootRel_AddressArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

export type Subscription_RootRel_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Address_Order_By>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

export type Subscription_RootRel_Address_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRel_Address_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rel_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Rel_Address_Bool_Exp>;
};

export type Subscription_RootRel_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

export type Subscription_RootRel_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

export type Subscription_RootRel_Metadata_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRel_Metadata_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Rel_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

export type Subscription_RootTripArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type Subscription_RootTrip_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type Subscription_RootTrip_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTrip_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

export type Subscription_RootTrip_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

export type Subscription_RootTrip_Employee_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTrip_Employee_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trip_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

export type Subscription_RootTrip_GuestArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

export type Subscription_RootTrip_Guest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

export type Subscription_RootTrip_Guest_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTrip_Guest_InviteArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Invite_Order_By>>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

export type Subscription_RootTrip_Guest_Invite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Invite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Invite_Order_By>>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

export type Subscription_RootTrip_Guest_Invite_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTrip_Guest_Invite_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trip_Guest_Invite_Stream_Cursor_Input>>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

export type Subscription_RootTrip_Guest_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trip_Guest_Stream_Cursor_Input>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

export type Subscription_RootTrip_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trip_Stream_Cursor_Input>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

export type Subscription_RootTrip_TypeArgs = {
  distinct_on?: InputMaybe<Array<Trip_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Type_Order_By>>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

export type Subscription_RootTrip_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Type_Order_By>>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

export type Subscription_RootTrip_Type_By_PkArgs = {
  type: Scalars['String']['input'];
};

export type Subscription_RootTrip_Type_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Trip_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

export type Subscription_RootUser_PreferenceArgs = {
  distinct_on?: InputMaybe<Array<User_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preference_Order_By>>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

export type Subscription_RootUser_Preference_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Preference_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Preference_Order_By>>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

export type Subscription_RootUser_Preference_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUser_Preference_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Preference_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "trip" */
export type Trip = {
  __typename?: 'trip';
  budgetAmount?: Maybe<Scalars['String']['output']>;
  disembarkLocation?: Maybe<Scalars['String']['output']>;
  disembarkLocation_2?: Maybe<Scalars['String']['output']>;
  disembarkTime?: Maybe<Scalars['timestamptz']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  embarkLocation?: Maybe<Scalars['String']['output']>;
  embarkLocation_2?: Maybe<Scalars['String']['output']>;
  embarkTime?: Maybe<Scalars['timestamptz']['output']>;
  end: Scalars['timestamptz']['output'];
  /** An object relationship */
  facility?: Maybe<Facility>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  guestNumbers?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  rel_metadata: Array<Rel_Metadata>;
  /** An aggregate relationship */
  rel_metadata_aggregate: Rel_Metadata_Aggregate;
  start: Scalars['timestamptz']['output'];
  /** An object relationship */
  tripTypeByTripType?: Maybe<Trip_Type>;
  /** An array relationship */
  trip_employees: Array<Trip_Employee>;
  /** An aggregate relationship */
  trip_employees_aggregate: Trip_Employee_Aggregate;
  /** An array relationship */
  trip_guests: Array<Trip_Guest>;
  /** An aggregate relationship */
  trip_guests_aggregate: Trip_Guest_Aggregate;
  trip_type: Trip_Type_Enum;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "trip" */
export type TripRel_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** columns and relationships of "trip" */
export type TripRel_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rel_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rel_Metadata_Order_By>>;
  where?: InputMaybe<Rel_Metadata_Bool_Exp>;
};

/** columns and relationships of "trip" */
export type TripTrip_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

/** columns and relationships of "trip" */
export type TripTrip_Employees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Employee_Order_By>>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

/** columns and relationships of "trip" */
export type TripTrip_GuestsArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

/** columns and relationships of "trip" */
export type TripTrip_Guests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

/** aggregated selection of "trip" */
export type Trip_Aggregate = {
  __typename?: 'trip_aggregate';
  aggregate?: Maybe<Trip_Aggregate_Fields>;
  nodes: Array<Trip>;
};

export type Trip_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trip_Aggregate_Bool_Exp_Count>;
};

export type Trip_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trip_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trip_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trip" */
export type Trip_Aggregate_Fields = {
  __typename?: 'trip_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trip_Max_Fields>;
  min?: Maybe<Trip_Min_Fields>;
};

/** aggregate fields of "trip" */
export type Trip_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trip_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "trip" */
export type Trip_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trip_Max_Order_By>;
  min?: InputMaybe<Trip_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trip" */
export type Trip_Arr_Rel_Insert_Input = {
  data: Array<Trip_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trip". All fields are combined with a logical 'AND'. */
export type Trip_Bool_Exp = {
  _and?: InputMaybe<Array<Trip_Bool_Exp>>;
  _not?: InputMaybe<Trip_Bool_Exp>;
  _or?: InputMaybe<Array<Trip_Bool_Exp>>;
  budgetAmount?: InputMaybe<String_Comparison_Exp>;
  disembarkLocation?: InputMaybe<String_Comparison_Exp>;
  disembarkLocation_2?: InputMaybe<String_Comparison_Exp>;
  disembarkTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  emailAddress?: InputMaybe<String_Comparison_Exp>;
  embarkLocation?: InputMaybe<String_Comparison_Exp>;
  embarkLocation_2?: InputMaybe<String_Comparison_Exp>;
  embarkTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  facility?: InputMaybe<Facility_Bool_Exp>;
  facility_id?: InputMaybe<Uuid_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  guestNumbers?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  middleName?: InputMaybe<String_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rel_metadata?: InputMaybe<Rel_Metadata_Bool_Exp>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Bool_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  tripTypeByTripType?: InputMaybe<Trip_Type_Bool_Exp>;
  trip_employees?: InputMaybe<Trip_Employee_Bool_Exp>;
  trip_employees_aggregate?: InputMaybe<Trip_Employee_Aggregate_Bool_Exp>;
  trip_guests?: InputMaybe<Trip_Guest_Bool_Exp>;
  trip_guests_aggregate?: InputMaybe<Trip_Guest_Aggregate_Bool_Exp>;
  trip_type?: InputMaybe<Trip_Type_Enum_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "trip" */
export enum Trip_Constraint {
  /** unique or primary key constraint on columns "id" */
  TripPkey = 'trip_pkey',
}

/** columns and relationships of "trip_employee" */
export type Trip_Employee = {
  __typename?: 'trip_employee';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  trip: Trip;
  trip_id: Scalars['uuid']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "trip_employee" */
export type Trip_Employee_Aggregate = {
  __typename?: 'trip_employee_aggregate';
  aggregate?: Maybe<Trip_Employee_Aggregate_Fields>;
  nodes: Array<Trip_Employee>;
};

export type Trip_Employee_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trip_Employee_Aggregate_Bool_Exp_Count>;
};

export type Trip_Employee_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trip_Employee_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trip_employee" */
export type Trip_Employee_Aggregate_Fields = {
  __typename?: 'trip_employee_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trip_Employee_Max_Fields>;
  min?: Maybe<Trip_Employee_Min_Fields>;
};

/** aggregate fields of "trip_employee" */
export type Trip_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trip_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "trip_employee" */
export type Trip_Employee_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trip_Employee_Max_Order_By>;
  min?: InputMaybe<Trip_Employee_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trip_employee" */
export type Trip_Employee_Arr_Rel_Insert_Input = {
  data: Array<Trip_Employee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_Employee_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trip_employee". All fields are combined with a logical 'AND'. */
export type Trip_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Trip_Employee_Bool_Exp>>;
  _not?: InputMaybe<Trip_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Trip_Employee_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  trip?: InputMaybe<Trip_Bool_Exp>;
  trip_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "trip_employee" */
export enum Trip_Employee_Constraint {
  /** unique or primary key constraint on columns "id" */
  TripEmployeePkey = 'trip_employee_pkey',
}

/** input type for inserting data into table "trip_employee" */
export type Trip_Employee_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  trip?: InputMaybe<Trip_Obj_Rel_Insert_Input>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Trip_Employee_Max_Fields = {
  __typename?: 'trip_employee_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "trip_employee" */
export type Trip_Employee_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trip_Employee_Min_Fields = {
  __typename?: 'trip_employee_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "trip_employee" */
export type Trip_Employee_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trip_employee" */
export type Trip_Employee_Mutation_Response = {
  __typename?: 'trip_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip_Employee>;
};

/** on_conflict condition type for table "trip_employee" */
export type Trip_Employee_On_Conflict = {
  constraint: Trip_Employee_Constraint;
  update_columns?: Array<Trip_Employee_Update_Column>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "trip_employee". */
export type Trip_Employee_Order_By = {
  id?: InputMaybe<Order_By>;
  trip?: InputMaybe<Trip_Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trip_employee */
export type Trip_Employee_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trip_employee" */
export enum Trip_Employee_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "trip_employee" */
export type Trip_Employee_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "trip_employee" */
export type Trip_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trip_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trip_Employee_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "trip_employee" */
export enum Trip_Employee_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

export type Trip_Employee_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trip_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trip_Employee_Bool_Exp;
};

/** columns and relationships of "trip_guest" */
export type Trip_Guest = {
  __typename?: 'trip_guest';
  id: Scalars['uuid']['output'];
  is_primary: Scalars['Boolean']['output'];
  /** An object relationship */
  trip: Trip;
  /** An object relationship */
  trip_guest?: Maybe<Trip_Guest>;
  /** An object relationship */
  trip_guest_fixed_custom_question_answers?: Maybe<Entity_Fixed_Custom_Question_Answer>;
  trip_guest_parent_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  trip_guests: Array<Trip_Guest>;
  /** An aggregate relationship */
  trip_guests_aggregate: Trip_Guest_Aggregate;
  trip_id: Scalars['uuid']['output'];
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "trip_guest" */
export type Trip_GuestTrip_GuestsArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

/** columns and relationships of "trip_guest" */
export type Trip_GuestTrip_Guests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Guest_Order_By>>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

/** aggregated selection of "trip_guest" */
export type Trip_Guest_Aggregate = {
  __typename?: 'trip_guest_aggregate';
  aggregate?: Maybe<Trip_Guest_Aggregate_Fields>;
  nodes: Array<Trip_Guest>;
};

export type Trip_Guest_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Trip_Guest_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Trip_Guest_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Trip_Guest_Aggregate_Bool_Exp_Count>;
};

export type Trip_Guest_Aggregate_Bool_Exp_Bool_And = {
  arguments: Trip_Guest_Select_Column_Trip_Guest_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trip_Guest_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trip_Guest_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Trip_Guest_Select_Column_Trip_Guest_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trip_Guest_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trip_Guest_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trip_Guest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trip_guest" */
export type Trip_Guest_Aggregate_Fields = {
  __typename?: 'trip_guest_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trip_Guest_Max_Fields>;
  min?: Maybe<Trip_Guest_Min_Fields>;
};

/** aggregate fields of "trip_guest" */
export type Trip_Guest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trip_Guest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "trip_guest" */
export type Trip_Guest_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trip_Guest_Max_Order_By>;
  min?: InputMaybe<Trip_Guest_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trip_guest" */
export type Trip_Guest_Arr_Rel_Insert_Input = {
  data: Array<Trip_Guest_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_Guest_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trip_guest". All fields are combined with a logical 'AND'. */
export type Trip_Guest_Bool_Exp = {
  _and?: InputMaybe<Array<Trip_Guest_Bool_Exp>>;
  _not?: InputMaybe<Trip_Guest_Bool_Exp>;
  _or?: InputMaybe<Array<Trip_Guest_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_primary?: InputMaybe<Boolean_Comparison_Exp>;
  trip?: InputMaybe<Trip_Bool_Exp>;
  trip_guest?: InputMaybe<Trip_Guest_Bool_Exp>;
  trip_guest_fixed_custom_question_answers?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Bool_Exp>;
  trip_guest_parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  trip_guests?: InputMaybe<Trip_Guest_Bool_Exp>;
  trip_guests_aggregate?: InputMaybe<Trip_Guest_Aggregate_Bool_Exp>;
  trip_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "trip_guest" */
export enum Trip_Guest_Constraint {
  /** unique or primary key constraint on columns "id" */
  TripGuestPkey = 'trip_guest_pkey',
}

/** input type for inserting data into table "trip_guest" */
export type Trip_Guest_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_primary?: InputMaybe<Scalars['Boolean']['input']>;
  trip?: InputMaybe<Trip_Obj_Rel_Insert_Input>;
  trip_guest?: InputMaybe<Trip_Guest_Obj_Rel_Insert_Input>;
  trip_guest_fixed_custom_question_answers?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Obj_Rel_Insert_Input>;
  trip_guest_parent_id?: InputMaybe<Scalars['uuid']['input']>;
  trip_guests?: InputMaybe<Trip_Guest_Arr_Rel_Insert_Input>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "trip_guest_invite" */
export type Trip_Guest_Invite = {
  __typename?: 'trip_guest_invite';
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  status: Scalars['Boolean']['output'];
  trip_id: Scalars['uuid']['output'];
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "trip_guest_invite" */
export type Trip_Guest_Invite_Aggregate = {
  __typename?: 'trip_guest_invite_aggregate';
  aggregate?: Maybe<Trip_Guest_Invite_Aggregate_Fields>;
  nodes: Array<Trip_Guest_Invite>;
};

/** aggregate fields of "trip_guest_invite" */
export type Trip_Guest_Invite_Aggregate_Fields = {
  __typename?: 'trip_guest_invite_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trip_Guest_Invite_Max_Fields>;
  min?: Maybe<Trip_Guest_Invite_Min_Fields>;
};

/** aggregate fields of "trip_guest_invite" */
export type Trip_Guest_Invite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trip_Guest_Invite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "trip_guest_invite". All fields are combined with a logical 'AND'. */
export type Trip_Guest_Invite_Bool_Exp = {
  _and?: InputMaybe<Array<Trip_Guest_Invite_Bool_Exp>>;
  _not?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
  _or?: InputMaybe<Array<Trip_Guest_Invite_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  trip_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "trip_guest_invite" */
export enum Trip_Guest_Invite_Constraint {
  /** unique or primary key constraint on columns "id" */
  TripGuestInvitePkey = 'trip_guest_invite_pkey',
}

/** input type for inserting data into table "trip_guest_invite" */
export type Trip_Guest_Invite_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Trip_Guest_Invite_Max_Fields = {
  __typename?: 'trip_guest_invite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Trip_Guest_Invite_Min_Fields = {
  __typename?: 'trip_guest_invite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "trip_guest_invite" */
export type Trip_Guest_Invite_Mutation_Response = {
  __typename?: 'trip_guest_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip_Guest_Invite>;
};

/** on_conflict condition type for table "trip_guest_invite" */
export type Trip_Guest_Invite_On_Conflict = {
  constraint: Trip_Guest_Invite_Constraint;
  update_columns?: Array<Trip_Guest_Invite_Update_Column>;
  where?: InputMaybe<Trip_Guest_Invite_Bool_Exp>;
};

/** Ordering options when selecting data from "trip_guest_invite". */
export type Trip_Guest_Invite_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trip_guest_invite */
export type Trip_Guest_Invite_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trip_guest_invite" */
export enum Trip_Guest_Invite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "trip_guest_invite" */
export type Trip_Guest_Invite_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "trip_guest_invite" */
export type Trip_Guest_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trip_Guest_Invite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trip_Guest_Invite_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "trip_guest_invite" */
export enum Trip_Guest_Invite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

export type Trip_Guest_Invite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trip_Guest_Invite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trip_Guest_Invite_Bool_Exp;
};

/** aggregate max on columns */
export type Trip_Guest_Max_Fields = {
  __typename?: 'trip_guest_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  trip_guest_parent_id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "trip_guest" */
export type Trip_Guest_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  trip_guest_parent_id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trip_Guest_Min_Fields = {
  __typename?: 'trip_guest_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  trip_guest_parent_id?: Maybe<Scalars['uuid']['output']>;
  trip_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "trip_guest" */
export type Trip_Guest_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  trip_guest_parent_id?: InputMaybe<Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trip_guest" */
export type Trip_Guest_Mutation_Response = {
  __typename?: 'trip_guest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip_Guest>;
};

/** input type for inserting object relation for remote table "trip_guest" */
export type Trip_Guest_Obj_Rel_Insert_Input = {
  data: Trip_Guest_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_Guest_On_Conflict>;
};

/** on_conflict condition type for table "trip_guest" */
export type Trip_Guest_On_Conflict = {
  constraint: Trip_Guest_Constraint;
  update_columns?: Array<Trip_Guest_Update_Column>;
  where?: InputMaybe<Trip_Guest_Bool_Exp>;
};

/** Ordering options when selecting data from "trip_guest". */
export type Trip_Guest_Order_By = {
  id?: InputMaybe<Order_By>;
  is_primary?: InputMaybe<Order_By>;
  trip?: InputMaybe<Trip_Order_By>;
  trip_guest?: InputMaybe<Trip_Guest_Order_By>;
  trip_guest_fixed_custom_question_answers?: InputMaybe<Entity_Fixed_Custom_Question_Answer_Order_By>;
  trip_guest_parent_id?: InputMaybe<Order_By>;
  trip_guests_aggregate?: InputMaybe<Trip_Guest_Aggregate_Order_By>;
  trip_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trip_guest */
export type Trip_Guest_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trip_guest" */
export enum Trip_Guest_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  TripGuestParentId = 'trip_guest_parent_id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

/** select "trip_guest_aggregate_bool_exp_bool_and_arguments_columns" columns of table "trip_guest" */
export enum Trip_Guest_Select_Column_Trip_Guest_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary',
}

/** select "trip_guest_aggregate_bool_exp_bool_or_arguments_columns" columns of table "trip_guest" */
export enum Trip_Guest_Select_Column_Trip_Guest_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPrimary = 'is_primary',
}

/** input type for updating data in table "trip_guest" */
export type Trip_Guest_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_primary?: InputMaybe<Scalars['Boolean']['input']>;
  trip_guest_parent_id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "trip_guest" */
export type Trip_Guest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trip_Guest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trip_Guest_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_primary?: InputMaybe<Scalars['Boolean']['input']>;
  trip_guest_parent_id?: InputMaybe<Scalars['uuid']['input']>;
  trip_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "trip_guest" */
export enum Trip_Guest_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsPrimary = 'is_primary',
  /** column name */
  TripGuestParentId = 'trip_guest_parent_id',
  /** column name */
  TripId = 'trip_id',
  /** column name */
  UserId = 'user_id',
}

export type Trip_Guest_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trip_Guest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trip_Guest_Bool_Exp;
};

/** input type for inserting data into table "trip" */
export type Trip_Insert_Input = {
  budgetAmount?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  disembarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  embarkLocation?: InputMaybe<Scalars['String']['input']>;
  embarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  embarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  facility?: InputMaybe<Facility_Obj_Rel_Insert_Input>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guestNumbers?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rel_metadata?: InputMaybe<Rel_Metadata_Arr_Rel_Insert_Input>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  tripTypeByTripType?: InputMaybe<Trip_Type_Obj_Rel_Insert_Input>;
  trip_employees?: InputMaybe<Trip_Employee_Arr_Rel_Insert_Input>;
  trip_guests?: InputMaybe<Trip_Guest_Arr_Rel_Insert_Input>;
  trip_type?: InputMaybe<Trip_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Trip_Max_Fields = {
  __typename?: 'trip_max_fields';
  budgetAmount?: Maybe<Scalars['String']['output']>;
  disembarkLocation?: Maybe<Scalars['String']['output']>;
  disembarkLocation_2?: Maybe<Scalars['String']['output']>;
  disembarkTime?: Maybe<Scalars['timestamptz']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  embarkLocation?: Maybe<Scalars['String']['output']>;
  embarkLocation_2?: Maybe<Scalars['String']['output']>;
  embarkTime?: Maybe<Scalars['timestamptz']['output']>;
  end?: Maybe<Scalars['timestamptz']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  guestNumbers?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "trip" */
export type Trip_Max_Order_By = {
  budgetAmount?: InputMaybe<Order_By>;
  disembarkLocation?: InputMaybe<Order_By>;
  disembarkLocation_2?: InputMaybe<Order_By>;
  disembarkTime?: InputMaybe<Order_By>;
  emailAddress?: InputMaybe<Order_By>;
  embarkLocation?: InputMaybe<Order_By>;
  embarkLocation_2?: InputMaybe<Order_By>;
  embarkTime?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  guestNumbers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trip_Min_Fields = {
  __typename?: 'trip_min_fields';
  budgetAmount?: Maybe<Scalars['String']['output']>;
  disembarkLocation?: Maybe<Scalars['String']['output']>;
  disembarkLocation_2?: Maybe<Scalars['String']['output']>;
  disembarkTime?: Maybe<Scalars['timestamptz']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  embarkLocation?: Maybe<Scalars['String']['output']>;
  embarkLocation_2?: Maybe<Scalars['String']['output']>;
  embarkTime?: Maybe<Scalars['timestamptz']['output']>;
  end?: Maybe<Scalars['timestamptz']['output']>;
  facility_id?: Maybe<Scalars['uuid']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  guestNumbers?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "trip" */
export type Trip_Min_Order_By = {
  budgetAmount?: InputMaybe<Order_By>;
  disembarkLocation?: InputMaybe<Order_By>;
  disembarkLocation_2?: InputMaybe<Order_By>;
  disembarkTime?: InputMaybe<Order_By>;
  emailAddress?: InputMaybe<Order_By>;
  embarkLocation?: InputMaybe<Order_By>;
  embarkLocation_2?: InputMaybe<Order_By>;
  embarkTime?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  facility_id?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  guestNumbers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trip" */
export type Trip_Mutation_Response = {
  __typename?: 'trip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip>;
};

/** input type for inserting object relation for remote table "trip" */
export type Trip_Obj_Rel_Insert_Input = {
  data: Trip_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_On_Conflict>;
};

/** on_conflict condition type for table "trip" */
export type Trip_On_Conflict = {
  constraint: Trip_Constraint;
  update_columns?: Array<Trip_Update_Column>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** Ordering options when selecting data from "trip". */
export type Trip_Order_By = {
  budgetAmount?: InputMaybe<Order_By>;
  disembarkLocation?: InputMaybe<Order_By>;
  disembarkLocation_2?: InputMaybe<Order_By>;
  disembarkTime?: InputMaybe<Order_By>;
  emailAddress?: InputMaybe<Order_By>;
  embarkLocation?: InputMaybe<Order_By>;
  embarkLocation_2?: InputMaybe<Order_By>;
  embarkTime?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  facility?: InputMaybe<Facility_Order_By>;
  facility_id?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  guestNumbers?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rel_metadata_aggregate?: InputMaybe<Rel_Metadata_Aggregate_Order_By>;
  start?: InputMaybe<Order_By>;
  tripTypeByTripType?: InputMaybe<Trip_Type_Order_By>;
  trip_employees_aggregate?: InputMaybe<Trip_Employee_Aggregate_Order_By>;
  trip_guests_aggregate?: InputMaybe<Trip_Guest_Aggregate_Order_By>;
  trip_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trip */
export type Trip_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trip" */
export enum Trip_Select_Column {
  /** column name */
  BudgetAmount = 'budgetAmount',
  /** column name */
  DisembarkLocation = 'disembarkLocation',
  /** column name */
  DisembarkLocation_2 = 'disembarkLocation_2',
  /** column name */
  DisembarkTime = 'disembarkTime',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  EmbarkLocation = 'embarkLocation',
  /** column name */
  EmbarkLocation_2 = 'embarkLocation_2',
  /** column name */
  EmbarkTime = 'embarkTime',
  /** column name */
  End = 'end',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GuestNumbers = 'guestNumbers',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  Start = 'start',
  /** column name */
  TripType = 'trip_type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "trip" */
export type Trip_Set_Input = {
  budgetAmount?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  disembarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  embarkLocation?: InputMaybe<Scalars['String']['input']>;
  embarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  embarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guestNumbers?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  trip_type?: InputMaybe<Trip_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "trip" */
export type Trip_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trip_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trip_Stream_Cursor_Value_Input = {
  budgetAmount?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  disembarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  embarkLocation?: InputMaybe<Scalars['String']['input']>;
  embarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  embarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  facility_id?: InputMaybe<Scalars['uuid']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guestNumbers?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  trip_type?: InputMaybe<Trip_Type_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "trip_type" */
export type Trip_Type = {
  __typename?: 'trip_type';
  /** An array relationship */
  trips: Array<Trip>;
  /** An aggregate relationship */
  trips_aggregate: Trip_Aggregate;
  type: Scalars['String']['output'];
};

/** columns and relationships of "trip_type" */
export type Trip_TypeTripsArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** columns and relationships of "trip_type" */
export type Trip_TypeTrips_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trip_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trip_Order_By>>;
  where?: InputMaybe<Trip_Bool_Exp>;
};

/** aggregated selection of "trip_type" */
export type Trip_Type_Aggregate = {
  __typename?: 'trip_type_aggregate';
  aggregate?: Maybe<Trip_Type_Aggregate_Fields>;
  nodes: Array<Trip_Type>;
};

/** aggregate fields of "trip_type" */
export type Trip_Type_Aggregate_Fields = {
  __typename?: 'trip_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Trip_Type_Max_Fields>;
  min?: Maybe<Trip_Type_Min_Fields>;
};

/** aggregate fields of "trip_type" */
export type Trip_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trip_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "trip_type". All fields are combined with a logical 'AND'. */
export type Trip_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Trip_Type_Bool_Exp>>;
  _not?: InputMaybe<Trip_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Trip_Type_Bool_Exp>>;
  trips?: InputMaybe<Trip_Bool_Exp>;
  trips_aggregate?: InputMaybe<Trip_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trip_type" */
export enum Trip_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  TripTypePkey = 'trip_type_pkey',
}

export enum Trip_Type_Enum {
  MotorYacht = 'motor_yacht',
  Yacht = 'yacht',
}

/** Boolean expression to compare columns of type "trip_type_enum". All fields are combined with logical 'AND'. */
export type Trip_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Trip_Type_Enum>;
  _in?: InputMaybe<Array<Trip_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Trip_Type_Enum>;
  _nin?: InputMaybe<Array<Trip_Type_Enum>>;
};

/** input type for inserting data into table "trip_type" */
export type Trip_Type_Insert_Input = {
  trips?: InputMaybe<Trip_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Trip_Type_Max_Fields = {
  __typename?: 'trip_type_max_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Trip_Type_Min_Fields = {
  __typename?: 'trip_type_min_fields';
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "trip_type" */
export type Trip_Type_Mutation_Response = {
  __typename?: 'trip_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip_Type>;
};

/** input type for inserting object relation for remote table "trip_type" */
export type Trip_Type_Obj_Rel_Insert_Input = {
  data: Trip_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trip_Type_On_Conflict>;
};

/** on_conflict condition type for table "trip_type" */
export type Trip_Type_On_Conflict = {
  constraint: Trip_Type_Constraint;
  update_columns?: Array<Trip_Type_Update_Column>;
  where?: InputMaybe<Trip_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "trip_type". */
export type Trip_Type_Order_By = {
  trips_aggregate?: InputMaybe<Trip_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trip_type */
export type Trip_Type_Pk_Columns_Input = {
  type: Scalars['String']['input'];
};

/** select columns of table "trip_type" */
export enum Trip_Type_Select_Column {
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "trip_type" */
export type Trip_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "trip_type" */
export type Trip_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trip_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trip_Type_Stream_Cursor_Value_Input = {
  type?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "trip_type" */
export enum Trip_Type_Update_Column {
  /** column name */
  Type = 'type',
}

export type Trip_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trip_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trip_Type_Bool_Exp;
};

/** update columns of table "trip" */
export enum Trip_Update_Column {
  /** column name */
  BudgetAmount = 'budgetAmount',
  /** column name */
  DisembarkLocation = 'disembarkLocation',
  /** column name */
  DisembarkLocation_2 = 'disembarkLocation_2',
  /** column name */
  DisembarkTime = 'disembarkTime',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  EmbarkLocation = 'embarkLocation',
  /** column name */
  EmbarkLocation_2 = 'embarkLocation_2',
  /** column name */
  EmbarkTime = 'embarkTime',
  /** column name */
  End = 'end',
  /** column name */
  FacilityId = 'facility_id',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GuestNumbers = 'guestNumbers',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  Start = 'start',
  /** column name */
  TripType = 'trip_type',
  /** column name */
  UserId = 'user_id',
}

export type Trip_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trip_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trip_Bool_Exp;
};

/** columns and relationships of "user_preference" */
export type User_Preference = {
  __typename?: 'user_preference';
  id: Scalars['uuid']['output'];
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_preference" */
export type User_Preference_Aggregate = {
  __typename?: 'user_preference_aggregate';
  aggregate?: Maybe<User_Preference_Aggregate_Fields>;
  nodes: Array<User_Preference>;
};

/** aggregate fields of "user_preference" */
export type User_Preference_Aggregate_Fields = {
  __typename?: 'user_preference_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Preference_Max_Fields>;
  min?: Maybe<User_Preference_Min_Fields>;
};

/** aggregate fields of "user_preference" */
export type User_Preference_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Preference_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "user_preference". All fields are combined with a logical 'AND'. */
export type User_Preference_Bool_Exp = {
  _and?: InputMaybe<Array<User_Preference_Bool_Exp>>;
  _not?: InputMaybe<User_Preference_Bool_Exp>;
  _or?: InputMaybe<Array<User_Preference_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_preference" */
export enum User_Preference_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPreferencePkey = 'user_preference_pkey',
}

/** input type for inserting data into table "user_preference" */
export type User_Preference_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Preference_Max_Fields = {
  __typename?: 'user_preference_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type User_Preference_Min_Fields = {
  __typename?: 'user_preference_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "user_preference" */
export type User_Preference_Mutation_Response = {
  __typename?: 'user_preference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Preference>;
};

/** on_conflict condition type for table "user_preference" */
export type User_Preference_On_Conflict = {
  constraint: User_Preference_Constraint;
  update_columns?: Array<User_Preference_Update_Column>;
  where?: InputMaybe<User_Preference_Bool_Exp>;
};

/** Ordering options when selecting data from "user_preference". */
export type User_Preference_Order_By = {
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_preference */
export type User_Preference_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_preference" */
export enum User_Preference_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_preference" */
export type User_Preference_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_preference" */
export type User_Preference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Preference_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Preference_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_preference" */
export enum User_Preference_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

export type User_Preference_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Preference_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Preference_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

export type GetTripByIdQueryQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type GetTripByIdQueryQuery = {
  __typename?: 'query_root';
  trip_by_pk?: {
    __typename?: 'trip';
    start: any;
    name: string;
    id: any;
    end: any;
    embarkLocation?: string | null;
    disembarkLocation?: string | null;
    embarkTime?: any | null;
    trip_type: Trip_Type_Enum;
    embarkLocation_2?: string | null;
    disembarkLocation_2?: string | null;
    disembarkTime?: any | null;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
    emailAddress?: string | null;
    mobile?: string | null;
    guestNumbers?: string | null;
    budgetAmount?: string | null;
    trip_guests_aggregate: {
      __typename?: 'trip_guest_aggregate';
      aggregate?: {
        __typename?: 'trip_guest_aggregate_fields';
        count: number;
      } | null;
    };
    trip_employees_aggregate: {
      __typename?: 'trip_employee_aggregate';
      aggregate?: {
        __typename?: 'trip_employee_aggregate_fields';
        count: number;
      } | null;
    };
    facility?: {
      __typename?: 'facility';
      attributes: any;
      id: any;
      is_mobile: boolean;
      name: string;
      files: Array<{
        __typename?: 'entity_files';
        id: any;
        file_type: string;
        file_id: any;
        entity_type: string;
        entity_id: any;
      }>;
    } | null;
  } | null;
};

export type GetTripsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Trip_Bool_Exp>;
}>;

export type GetTripsQueryQuery = {
  __typename?: 'query_root';
  trip: Array<{
    __typename?: 'trip';
    start: any;
    name: string;
    id: any;
    end: any;
    embarkLocation?: string | null;
    disembarkLocation?: string | null;
    facility?: {
      __typename?: 'facility';
      attributes: any;
      id: any;
      is_mobile: boolean;
      name: string;
    } | null;
  }>;
};

export type GetFacilityByIdQueryQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type GetFacilityByIdQueryQuery = {
  __typename?: 'query_root';
  facility_by_pk?: {
    __typename?: 'facility';
    id: any;
    name: string;
    attributes: any;
  } | null;
};

export type GetTripEmployeesQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
}>;

export type GetTripEmployeesQueryQuery = {
  __typename?: 'query_root';
  trip_employee: Array<{
    __typename?: 'trip_employee';
    id: any;
    user_id: any;
    trip_id: any;
    trip: {
      __typename?: 'trip';
      start: any;
      name: string;
      id: any;
      end: any;
      embarkLocation?: string | null;
      disembarkLocation?: string | null;
      facility?: {
        __typename?: 'facility';
        attributes: any;
        id: any;
        is_mobile: boolean;
        name: string;
      } | null;
    };
  }>;
};

export type GetCustomQuestionAnswerQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Entity_Fixed_Custom_Question_Bool_Exp>;
}>;

export type GetCustomQuestionAnswerQueryQuery = {
  __typename?: 'query_root';
  entity_fixed_custom_question: Array<{
    __typename?: 'entity_fixed_custom_question';
    entity: string;
    id: any;
    question_type: string;
    unique_id?: string | null;
    answers: Array<{
      __typename?: 'entity_fixed_custom_question_answer';
      entity_custom_question_id: any;
      id: any;
      parent_id?: string | null;
      value: string;
      user_id: any;
    }>;
  }>;
};

export type GetCustomQuestionDetailsQueryVariables = Exact<{
  _customQuestionUniqueId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCustomQuestionDetailsQuery = {
  __typename?: 'query_root';
  entity_fixed_custom_question: Array<{
    __typename?: 'entity_fixed_custom_question';
    id: any;
    question_type: string;
    unique_id?: string | null;
  }>;
};

export type GetCustomQuestionAnswersDetailsQueryVariables = Exact<{
  _entity_custom_question_id: Scalars['uuid']['input'];
  _user_id: Scalars['uuid']['input'];
}>;

export type GetCustomQuestionAnswersDetailsQuery = {
  __typename?: 'query_root';
  entity_fixed_custom_question_answer: Array<{
    __typename?: 'entity_fixed_custom_question_answer';
    id: any;
    value: string;
  }>;
};

export type AddCustomQuestionAnswersMutationVariables = Exact<{
  entity_custom_question_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddCustomQuestionAnswersMutation = {
  __typename?: 'mutation_root';
  insert_entity_fixed_custom_question_answer?: {
    __typename?: 'entity_fixed_custom_question_answer_mutation_response';
    returning: Array<{
      __typename?: 'entity_fixed_custom_question_answer';
      id: any;
    }>;
  } | null;
};

export type UpdateCustomQuestionAnswersMutationVariables = Exact<{
  _id: Scalars['uuid']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCustomQuestionAnswersMutation = {
  __typename?: 'mutation_root';
  update_entity_fixed_custom_question_answer?: {
    __typename?: 'entity_fixed_custom_question_answer_mutation_response';
    returning: Array<{
      __typename?: 'entity_fixed_custom_question_answer';
      id: any;
    }>;
  } | null;
};

export type DeleteCustomQuestionAnswerMutationVariables = Exact<{
  _id: Scalars['uuid']['input'];
}>;

export type DeleteCustomQuestionAnswerMutation = {
  __typename?: 'mutation_root';
  delete_entity_fixed_custom_question_answer?: {
    __typename?: 'entity_fixed_custom_question_answer_mutation_response';
    returning: Array<{
      __typename?: 'entity_fixed_custom_question_answer';
      id: any;
      value: string;
    }>;
  } | null;
};

export type GetFacilityTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFacilityTypesQuery = {
  __typename?: 'query_root';
  facility_type: Array<{
    __typename?: 'facility_type';
    id: any;
    key: string;
    value: string;
  }>;
};

export type AddFacilityMutationVariables = Exact<{
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
  is_mobile?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddFacilityMutation = {
  __typename?: 'mutation_root';
  insert_facility?: {
    __typename?: 'facility_mutation_response';
    returning: Array<{
      __typename?: 'facility';
      id: any;
      is_mobile: boolean;
      name: string;
    }>;
  } | null;
};

export type UpdateFacilityMutationVariables = Exact<{
  _id: Scalars['uuid']['input'];
  attributes?: InputMaybe<Scalars['jsonb']['input']>;
  is_mobile?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateFacilityMutation = {
  __typename?: 'mutation_root';
  update_facility?: {
    __typename?: 'facility_mutation_response';
    returning: Array<{
      __typename?: 'facility';
      id: any;
      name: string;
      is_mobile: boolean;
    }>;
  } | null;
};

export type AddEntityFileMutationVariables = Exact<{
  entity_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_type?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_type?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddEntityFileMutation = {
  __typename?: 'mutation_root';
  insert_entity_files?: {
    __typename?: 'entity_files_mutation_response';
    returning: Array<{
      __typename?: 'entity_files';
      id: any;
      file_id: any;
      entity_id: any;
    }>;
  } | null;
};

export type GetFacilityQueryVariables = Exact<{ [key: string]: never }>;

export type GetFacilityQuery = {
  __typename?: 'query_root';
  facility: Array<{
    __typename?: 'facility';
    id: any;
    name: string;
    is_mobile: boolean;
    attributes: any;
  }>;
};

export type AddEventMutationVariables = Exact<{
  disembarkLocation?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  embarkLocation?: InputMaybe<Scalars['String']['input']>;
  embarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  embarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  disembarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  facility_id: Scalars['uuid']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  trip_type?: InputMaybe<Trip_Type_Enum>;
  user_id: Scalars['uuid']['input'];
  budgetAmount?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guestNumbers?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddEventMutation = {
  __typename?: 'mutation_root';
  insert_trip?: {
    __typename?: 'trip_mutation_response';
    returning: Array<{
      __typename?: 'trip';
      id: any;
      facility_id?: any | null;
      name: string;
    }>;
  } | null;
};

export type UpdateEventMutationVariables = Exact<{
  embarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  disembarkTime?: InputMaybe<Scalars['timestamptz']['input']>;
  embarkLocation?: InputMaybe<Scalars['String']['input']>;
  embarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation?: InputMaybe<Scalars['String']['input']>;
  disembarkLocation_2?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  _id: Scalars['uuid']['input'];
  budgetAmount?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guestNumbers?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateEventMutation = {
  __typename?: 'mutation_root';
  update_trip?: {
    __typename?: 'trip_mutation_response';
    returning: Array<{ __typename?: 'trip'; id: any }>;
  } | null;
};

export type GetFacilitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Facility_Bool_Exp>;
}>;

export type GetFacilitiesQuery = {
  __typename?: 'query_root';
  facility: Array<{
    __typename?: 'facility';
    attributes: any;
    id: any;
    is_mobile: boolean;
    name: string;
    trips: Array<{
      __typename?: 'trip';
      disembarkLocation?: string | null;
      embarkLocation?: string | null;
      name: string;
      id: any;
      embarkTime?: any | null;
      end: any;
    }>;
  }>;
};

export type GetActiveEventsQueryVariables = Exact<{
  where?: InputMaybe<Trip_Bool_Exp>;
}>;

export type GetActiveEventsQuery = {
  __typename?: 'query_root';
  trip_aggregate: {
    __typename?: 'trip_aggregate';
    aggregate?: { __typename?: 'trip_aggregate_fields'; count: number } | null;
  };
};

export type GetTotalEventsQueryVariables = Exact<{
  where?: InputMaybe<Trip_Bool_Exp>;
}>;

export type GetTotalEventsQuery = {
  __typename?: 'query_root';
  trip_aggregate: {
    __typename?: 'trip_aggregate';
    aggregate?: { __typename?: 'trip_aggregate_fields'; count: number } | null;
  };
};

export type GetTotalFacilitiesQueryVariables = Exact<{
  where?: InputMaybe<Facility_Bool_Exp>;
}>;

export type GetTotalFacilitiesQuery = {
  __typename?: 'query_root';
  facility_aggregate: {
    __typename?: 'facility_aggregate';
    aggregate?: {
      __typename?: 'facility_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetTotalEmployeesQueryVariables = Exact<{
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
}>;

export type GetTotalEmployeesQuery = {
  __typename?: 'query_root';
  trip_employee_aggregate: {
    __typename?: 'trip_employee_aggregate';
    aggregate?: {
      __typename?: 'trip_employee_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetEventByEmployeeIdQueryVariables = Exact<{
  where?: InputMaybe<Trip_Employee_Bool_Exp>;
}>;

export type GetEventByEmployeeIdQuery = {
  __typename?: 'query_root';
  trip_employee_aggregate: {
    __typename?: 'trip_employee_aggregate';
    aggregate?: {
      __typename?: 'trip_employee_aggregate_fields';
      count: number;
    } | null;
    nodes: Array<{
      __typename?: 'trip_employee';
      id: any;
      trip: { __typename?: 'trip'; name: string };
    }>;
  };
};

export type RemoveGuestFromEventMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
  tripId?: InputMaybe<Scalars['uuid']['input']>;
}>;

export type RemoveGuestFromEventMutation = {
  __typename?: 'mutation_root';
  delete_trip_guest?: {
    __typename?: 'trip_guest_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveEmployeeFromTripEmployeeMutationVariables = Exact<{
  _eq: Scalars['uuid']['input'];
}>;

export type RemoveEmployeeFromEventMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']['input']>;
  tripId?: InputMaybe<Scalars['uuid']['input']>;
}>;

export type RemoveEmployeeFromEventMutation = {
  __typename?: 'mutation_root';
  delete_trip_employee?: {
    __typename?: 'trip_employee_mutation_response';
    affected_rows: number;
  } | null;
};
export type RemoveEmployeeFromTripEmployeeMutation = {
  __typename?: 'mutation_root';
  delete_trip_employee?: {
    __typename?: 'trip_employee_mutation_response';
    returning: Array<{ __typename?: 'trip_employee'; id: any }>;
  } | null;
};

export type GetTripWithoutQueryQueryVariables = Exact<{ [key: string]: never }>;

export type GetTripWithoutQueryQuery = {
  __typename?: 'query_root';
  trip: Array<{ __typename?: 'trip'; id: any; name: string }>;
};

export type AddEmployeeToTripMutationVariables = Exact<{
  trip_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
}>;

export type AddEmployeeToTripMutation = {
  __typename?: 'mutation_root';
  insert_trip_employee?: {
    __typename?: 'trip_employee_mutation_response';
    returning: Array<{ __typename?: 'trip_employee'; id: any }>;
  } | null;
};

export const GetTripByIdQueryDocument = gql`
  query GetTripByIdQuery($id: uuid!) {
    trip_by_pk(id: $id) {
      trip_guests_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      trip_employees_aggregate {
        aggregate {
          count(distinct: true)
        }
      }
      start
      name
      id
      end
      embarkLocation
      disembarkLocation
      embarkTime
      trip_type
      embarkLocation_2
      disembarkLocation_2
      disembarkTime
      firstName
      lastName
      middleName
      emailAddress
      mobile
      guestNumbers
      budgetAmount
      facility {
        attributes
        id
        is_mobile
        name
        files {
          id
          file_type
          file_id
          entity_type
          entity_id
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTripByIdQueryGQL extends Apollo.Query<GetTripByIdQueryQuery, GetTripByIdQueryQueryVariables> {
  override document = GetTripByIdQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTripsQueryDocument = gql`
  query GetTripsQuery($limit: Int = 10, $offset: Int = 0, $where: trip_bool_exp = {}) {
    trip(limit: $limit, offset: $offset, where: $where) {
      start
      name
      id
      end
      embarkLocation
      disembarkLocation
      facility {
        attributes
        id
        is_mobile
        name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTripsQueryGQL extends Apollo.Query<GetTripsQueryQuery, GetTripsQueryQueryVariables> {
  override document = GetTripsQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetFacilityByIdQueryDocument = gql`
  query GetFacilityByIdQuery($id: uuid!) {
    facility_by_pk(id: $id) {
      id
      name
      attributes
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetFacilityByIdQueryGQL extends Apollo.Query<
  GetFacilityByIdQueryQuery,
  GetFacilityByIdQueryQueryVariables
> {
  override document = GetFacilityByIdQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTripEmployeesQueryDocument = gql`
  query GetTripEmployeesQuery($limit: Int = 10, $offset: Int = 0, $where: trip_employee_bool_exp = {}) {
    trip_employee(limit: $limit, offset: $offset, where: $where) {
      id
      user_id
      trip_id
      trip {
        start
        name
        id
        end
        embarkLocation
        disembarkLocation
        facility {
          attributes
          id
          is_mobile
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTripEmployeesQueryGQL extends Apollo.Query<
  GetTripEmployeesQueryQuery,
  GetTripEmployeesQueryQueryVariables
> {
  override document = GetTripEmployeesQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCustomQuestionAnswerQueryDocument = gql`
  query GetCustomQuestionAnswerQuery(
    $limit: Int = 10
    $offset: Int = 0
    $where: entity_fixed_custom_question_bool_exp = {}
  ) {
    entity_fixed_custom_question(limit: $limit, offset: $offset, where: $where) {
      entity
      id
      question_type
      unique_id
      answers {
        entity_custom_question_id
        id
        parent_id
        value
        user_id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCustomQuestionAnswerQueryGQL extends Apollo.Query<
  GetCustomQuestionAnswerQueryQuery,
  GetCustomQuestionAnswerQueryQueryVariables
> {
  override document = GetCustomQuestionAnswerQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCustomQuestionDetailsDocument = gql`
  query GetCustomQuestionDetails($_customQuestionUniqueId: String = "") {
    entity_fixed_custom_question(where: { unique_id: { _eq: $_customQuestionUniqueId } }) {
      id
      question_type
      unique_id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCustomQuestionDetailsGQL extends Apollo.Query<
  GetCustomQuestionDetailsQuery,
  GetCustomQuestionDetailsQueryVariables
> {
  override document = GetCustomQuestionDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetCustomQuestionAnswersDetailsDocument = gql`
  query GetCustomQuestionAnswersDetails($_entity_custom_question_id: uuid!, $_user_id: uuid!) {
    entity_fixed_custom_question_answer(
      where: { entity_custom_question_id: { _eq: $_entity_custom_question_id }, user_id: { _eq: $_user_id } }
      order_by: { createdDate: asc }
    ) {
      id
      value
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCustomQuestionAnswersDetailsGQL extends Apollo.Query<
  GetCustomQuestionAnswersDetailsQuery,
  GetCustomQuestionAnswersDetailsQueryVariables
> {
  override document = GetCustomQuestionAnswersDetailsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddCustomQuestionAnswersDocument = gql`
  mutation AddCustomQuestionAnswers($entity_custom_question_id: uuid!, $user_id: uuid!, $value: String = "") {
    insert_entity_fixed_custom_question_answer(
      objects: { value: $value, user_id: $user_id, entity_custom_question_id: $entity_custom_question_id }
    ) {
      returning {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddCustomQuestionAnswersGQL extends Apollo.Mutation<
  AddCustomQuestionAnswersMutation,
  AddCustomQuestionAnswersMutationVariables
> {
  override document = AddCustomQuestionAnswersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateCustomQuestionAnswersDocument = gql`
  mutation UpdateCustomQuestionAnswers($_id: uuid!, $value: String = "") {
    update_entity_fixed_custom_question_answer(where: { id: { _eq: $_id } }, _set: { value: $value }) {
      returning {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateCustomQuestionAnswersGQL extends Apollo.Mutation<
  UpdateCustomQuestionAnswersMutation,
  UpdateCustomQuestionAnswersMutationVariables
> {
  override document = UpdateCustomQuestionAnswersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteCustomQuestionAnswerDocument = gql`
  mutation DeleteCustomQuestionAnswer($_id: uuid!) {
    delete_entity_fixed_custom_question_answer(where: { id: { _eq: $_id } }) {
      returning {
        id
        value
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteCustomQuestionAnswerGQL extends Apollo.Mutation<
  DeleteCustomQuestionAnswerMutation,
  DeleteCustomQuestionAnswerMutationVariables
> {
  override document = DeleteCustomQuestionAnswerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetFacilityTypesDocument = gql`
  query GetFacilityTypes {
    facility_type {
      id
      key
      value
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetFacilityTypesGQL extends Apollo.Query<GetFacilityTypesQuery, GetFacilityTypesQueryVariables> {
  override document = GetFacilityTypesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddFacilityDocument = gql`
  mutation AddFacility($attributes: jsonb = "", $is_mobile: Boolean = false, $name: String = "") {
    insert_facility(objects: { is_mobile: $is_mobile, name: $name, attributes: $attributes }) {
      returning {
        id
        is_mobile
        name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddFacilityGQL extends Apollo.Mutation<AddFacilityMutation, AddFacilityMutationVariables> {
  override document = AddFacilityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateFacilityDocument = gql`
  mutation UpdateFacility(
    $_id: uuid!
    $attributes: jsonb = ""
    $is_mobile: Boolean = false
    $name: String = ""
  ) {
    update_facility(
      where: { id: { _eq: $_id } }
      _set: { attributes: $attributes, is_mobile: $is_mobile, name: $name }
    ) {
      returning {
        id
        name
        is_mobile
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateFacilityGQL extends Apollo.Mutation<
  UpdateFacilityMutation,
  UpdateFacilityMutationVariables
> {
  override document = UpdateFacilityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddEntityFileDocument = gql`
  mutation AddEntityFile(
    $entity_id: uuid = ""
    $entity_type: String = ""
    $file_id: uuid = ""
    $file_type: String = ""
  ) {
    insert_entity_files(
      objects: { file_id: $file_id, file_type: $file_type, entity_type: $entity_type, entity_id: $entity_id }
    ) {
      returning {
        id
        file_id
        entity_id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddEntityFileGQL extends Apollo.Mutation<AddEntityFileMutation, AddEntityFileMutationVariables> {
  override document = AddEntityFileDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetFacilityDocument = gql`
  query GetFacility {
    facility {
      id
      name
      is_mobile
      attributes
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetFacilityGQL extends Apollo.Query<GetFacilityQuery, GetFacilityQueryVariables> {
  override document = GetFacilityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddEventDocument = gql`
  mutation AddEvent(
    $disembarkLocation: String = ""
    $disembarkLocation_2: String = ""
    $embarkLocation: String = ""
    $embarkLocation_2: String = ""
    $embarkTime: timestamptz = ""
    $disembarkTime: timestamptz = ""
    $end: timestamptz = ""
    $facility_id: uuid!
    $name: String = ""
    $start: timestamptz = ""
    $trip_type: trip_type_enum = yacht
    $user_id: uuid!
    $budgetAmount: String = ""
    $emailAddress: String = ""
    $firstName: String = ""
    $guestNumbers: String = ""
    $lastName: String = ""
    $middleName: String = ""
    $mobile: String = ""
  ) {
    insert_trip(
      objects: {
        disembarkLocation: $disembarkLocation
        disembarkLocation_2: $disembarkLocation_2
        embarkTime: $embarkTime
        disembarkTime: $disembarkTime
        facility_id: $facility_id
        name: $name
        user_id: $user_id
        trip_type: $trip_type
        start: $start
        end: $end
        embarkLocation: $embarkLocation
        embarkLocation_2: $embarkLocation_2
        budgetAmount: $budgetAmount
        emailAddress: $emailAddress
        firstName: $firstName
        guestNumbers: $guestNumbers
        lastName: $lastName
        middleName: $middleName
        mobile: $mobile
      }
    ) {
      returning {
        id
        facility_id
        name
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddEventGQL extends Apollo.Mutation<AddEventMutation, AddEventMutationVariables> {
  override document = AddEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateEventDocument = gql`
  mutation UpdateEvent(
    $embarkTime: timestamptz = ""
    $disembarkTime: timestamptz = ""
    $embarkLocation: String = ""
    $embarkLocation_2: String = ""
    $disembarkLocation: String = ""
    $disembarkLocation_2: String = ""
    $end: timestamptz = ""
    $name: String = ""
    $start: timestamptz = ""
    $_id: uuid!
    $budgetAmount: String = ""
    $emailAddress: String = ""
    $firstName: String = ""
    $guestNumbers: String = ""
    $lastName: String = ""
    $middleName: String = ""
    $mobile: String = ""
  ) {
    update_trip(
      _set: {
        embarkTime: $embarkTime
        disembarkTime: $disembarkTime
        embarkLocation: $embarkLocation
        embarkLocation_2: $embarkLocation_2
        disembarkLocation: $disembarkLocation
        disembarkLocation_2: $disembarkLocation_2
        end: $end
        name: $name
        start: $start
        budgetAmount: $budgetAmount
        emailAddress: $emailAddress
        firstName: $firstName
        guestNumbers: $guestNumbers
        lastName: $lastName
        middleName: $middleName
        mobile: $mobile
      }
      where: { id: { _eq: $_id } }
    ) {
      returning {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
  override document = UpdateEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetFacilitiesDocument = gql`
  query GetFacilities($limit: Int = 10, $offset: Int = 0, $where: facility_bool_exp = {}) {
    facility(limit: $limit, offset: $offset, where: $where) {
      attributes
      id
      is_mobile
      name
      trips {
        disembarkLocation
        embarkLocation
        name
        id
        embarkTime
        end
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetFacilitiesGQL extends Apollo.Query<GetFacilitiesQuery, GetFacilitiesQueryVariables> {
  override document = GetFacilitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetActiveEventsDocument = gql`
  query GetActiveEvents($where: trip_bool_exp = {}) {
    trip_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetActiveEventsGQL extends Apollo.Query<GetActiveEventsQuery, GetActiveEventsQueryVariables> {
  override document = GetActiveEventsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTotalEventsDocument = gql`
  query GetTotalEvents($where: trip_bool_exp = {}) {
    trip_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTotalEventsGQL extends Apollo.Query<GetTotalEventsQuery, GetTotalEventsQueryVariables> {
  override document = GetTotalEventsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTotalFacilitiesDocument = gql`
  query GetTotalFacilities($where: facility_bool_exp = {}) {
    facility_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTotalFacilitiesGQL extends Apollo.Query<
  GetTotalFacilitiesQuery,
  GetTotalFacilitiesQueryVariables
> {
  override document = GetTotalFacilitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTotalEmployeesDocument = gql`
  query GetTotalEmployees($where: trip_employee_bool_exp = {}) {
    trip_employee_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTotalEmployeesGQL extends Apollo.Query<
  GetTotalEmployeesQuery,
  GetTotalEmployeesQueryVariables
> {
  override document = GetTotalEmployeesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetEventByEmployeeIdDocument = gql`
  query GetEventByEmployeeId($where: trip_employee_bool_exp = {}) {
    trip_employee_aggregate(where: $where) {
      aggregate {
        count(distinct: true)
      }
      nodes {
        id
        trip {
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetEventByEmployeeIdGQL extends Apollo.Query<
  GetEventByEmployeeIdQuery,
  GetEventByEmployeeIdQueryVariables
> {
  override document = GetEventByEmployeeIdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveGuestFromEventDocument = gql`
  mutation RemoveGuestFromEvent($id: uuid, $tripId: uuid) {
    delete_trip_guest(where: { user_id: { _eq: $id }, trip_id: { _eq: $tripId } }) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveGuestFromEventGQL extends Apollo.Mutation<
  RemoveGuestFromEventMutation,
  RemoveGuestFromEventMutationVariables
> {
  override document = RemoveGuestFromEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RemoveEmployeeFromTripEmployeeDocument = gql`
  mutation RemoveEmployeeFromTripEmployee($_eq: uuid!) {
    delete_trip_employee(where: { id: { _eq: $_eq } }) {
      returning {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveEmployeeFromTripEmployeeGQL extends Apollo.Mutation<
  RemoveEmployeeFromTripEmployeeMutation,
  RemoveEmployeeFromTripEmployeeMutationVariables
> {
  override document = RemoveEmployeeFromTripEmployeeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetTripWithoutQueryDocument = gql`
  query GetTripWithoutQuery {
    trip {
      id
      name
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetTripWithoutQueryGQL extends Apollo.Query<
  GetTripWithoutQueryQuery,
  GetTripWithoutQueryQueryVariables
> {
  override document = GetTripWithoutQueryDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddEmployeeToTripDocument = gql`
  mutation AddEmployeeToTrip($trip_id: uuid!, $user_id: uuid!) {
    insert_trip_employee(objects: { trip_id: $trip_id, user_id: $user_id }) {
      returning {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddEmployeeToTripGQL extends Apollo.Mutation<
  AddEmployeeToTripMutation,
  AddEmployeeToTripMutationVariables
> {
  override document = AddEmployeeToTripDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const RemoveEmployeeFromEventDocument = gql`
  mutation RemoveEmployeeFromEvent($id: uuid, $tripId: uuid) {
    delete_trip_employee(where: { user_id: { _eq: $id }, trip_id: { _eq: $tripId } }) {
      affected_rows
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RemoveEmployeeFromEventGQL extends Apollo.Mutation<
  RemoveEmployeeFromEventMutation,
  RemoveEmployeeFromEventMutationVariables
> {
  override document = RemoveEmployeeFromEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
