import { CardInfoType } from '@finxone-platform/shared/sys-config-types';

export class RefreshEntityList {
  static readonly type = '[Entity] RefreshEntityList';
  constructor(public accountId: string) {}
}

export class RefreshCurrentEntity {
  static readonly type = '[Entity] RefreshCurrentEntity';
  constructor(public entity: string, public type: string) {}
}

export class ListCurrentEntity {
  static readonly type = '[Entity] ListCurrentEntity';
  constructor(
    public entity: string,
    public offset: number = 0,
    public limit: number = 10,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public where: any = null,
    public listType: any = null,
  ) {}
}

export class UpdateCurrentCardDetail {
  static readonly type = '[Card] UpdateCurrentCardDetail';
  constructor(public cardItemInfo: CardInfoType) {}
}

export class FlipCard {
  static readonly type = '[Card] FlipCard';
}
