import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanLoad,
  CanActivateChild,
  Route,
  UrlSegment,
} from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppAuthGuard implements CanActivate, CanLoad, CanActivateChild {
  isLoggedIn: Observable<boolean>;
  constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
    this.isLoggedIn = new Observable((subscriber) => {
      this.keycloakAngular.keycloakEvents$.subscribe((event) => {
        if (event.type === KeycloakEventType.OnAuthSuccess) {
          subscriber.next(true);
        }
      });
    });
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isLoggedIn.pipe(take(1));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoggedIn.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          this.keycloakAngular.login({
            redirectUri: window.location.origin + state.url,
          });
        }
      }),
    );
  }
}
