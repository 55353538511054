import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  APP_ZONES,
  CardManagementAdditionalConfig,
  CardManagementType,
  ProviderClass,
  UiZoneWidgetAttributeConfig,
  WidgetLayoutAttributes,
  WidgetNames,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../../../../../../lib/services/config-service/config-service.service';
import {
  CardManagementState,
  CardManagementStateModel,
} from '../../../../../../lib/state/card-management.state';
import { RoleState, RoleStateModel } from '../../../../../../lib/state/role.state';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'finxone-web-frontend-card-activation',
  templateUrl: './card-activation.component.html',
  styleUrls: ['./card-activation.component.scss'],
})
export class CardActivationComponent extends BaseComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @Input() cardManagementConfig: CardManagementAdditionalConfig;
  @Input() sectionName: WidgetNames;
  @Input() override attri: CardManagementType;
  @Input() parentAttribute: UiZoneWidgetAttributeConfig;
  @Input() borderStyleCardActivationStripe: baseCssConfigStyle;
  @Select(RoleState.getRole)
  roles!: Observable<RoleStateModel>;
  roleInfo: RoleStateModel;
  override contentStyle: baseCssConfigStyle;
  currentZone: APP_ZONES;
  isFreezeCard = true;

  cardLabel = 'Freeze';

  @Select(CardManagementState)
  cardManagementState$: Observable<CardManagementStateModel>;

  constructor(
    private configService: ConfigService,
    public dataIn: ProviderClass<WidgetLayoutAttributes>,
    private _router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCardActivationConfigData();
  }

  getCardActivationConfigData() {
    this.configService.getCardActivationConfigData().subscribe((response) => {
      this.contentStyle = {
        ...this.configService.onGetBaseWidgetStyle(response.widgetConfig.widgetConfiguration.attributes),
        ...this.borderStyleCardActivationStripe,
      };
    });
  }

  goToActivateCardPage(): void {
    this._router.navigateByUrl('/zones/card-management/activate-card');
  }
}
