import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { baseCssConfigStyle } from '@finxone-platform/shared/sys-config-types';

@Component({
  selector: 'finxone-web-frontend-activate-card-strip',
  templateUrl: './activate-card-strip.component.html',
  styleUrls: ['./activate-card-strip.component.scss'],
})
export class ActivateCardStripComponent {
  @Input() placeholderText = '';
  @Input() contentStyle: baseCssConfigStyle;

  constructor(private _router: Router) {}

  goToActivateCardPage(): void {
    this._router.navigateByUrl('/zones/card-management/activate-card');
  }
}
