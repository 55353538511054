import { Router } from '@angular/router';
import { ACCOUNTTYPE, BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';
export function editBeneficiary(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
) {
  const beneficiaryType = store.selectSnapshot(FormActionState.getFormActionState)?.response?.formData
    ?.accountType;
  if (beneficiaryType === ACCOUNTTYPE.PERSONAL) {
    redirectToPage(router, widgetProperties);
  } else {
    widgetProperties['urlToNavigate'] = 'edit-beneficiary-business';
    redirectToPage(router, widgetProperties);
  }
}
