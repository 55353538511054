import { ActiveDevice, AlertStyleConfigurationType } from '../../config.type';
import { AlertTypes, DismissalTypeDesktop, DismissalTypeMobile } from '../toaster-alerts.type';
import { DEFAULT_ALERT_CONFIGURATION } from './default-configurations-constant';

export const ALERT_STYLE_CONFIGURATION_CONSTANTS: AlertStyleConfigurationType = {
  [ActiveDevice.MOBILE]: {
    [AlertTypes.SUCCESS]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeMobile.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#E4F8F0',
        minWidth: 335,
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-1',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.ERROR]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeMobile.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#FFE7E6',
        minWidth: 335,
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-0',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.INFO]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeMobile.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#E9E9FF',
        minWidth: 335,
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-2',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.WARNING]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeMobile.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#FFF2E2',
        minWidth: 335,
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-3',
            unit: 'px',
          },
        },
      },
    },
  },
  [ActiveDevice.DESKTOP]: {
    [AlertTypes.SUCCESS]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeDesktop.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#E4F8F0',
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-1',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.ERROR]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeDesktop.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#FFE7E6',
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-0',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.INFO]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeDesktop.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#E9E9FF',
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-2',
            unit: 'px',
          },
        },
      },
    },
    [AlertTypes.WARNING]: {
      ...DEFAULT_ALERT_CONFIGURATION,
      behaviourSettings: {
        ...DEFAULT_ALERT_CONFIGURATION.behaviourSettings,
        dismissalType: DismissalTypeDesktop.CLOSE_AFTER,
      },
      styles: {
        ...DEFAULT_ALERT_CONFIGURATION.styles,
        backGroundColor: '#FFF2E2',
        iconConfiguration: {
          rightIcon: {
            name: 'times',
            size: '14',
            color: '--alerts-color-3',
            unit: 'px',
          },
        },
      },
    },
  },
};
