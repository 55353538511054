import {
  TripReclaimStatusEnum,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { filter, tap } from 'rxjs';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { RevenirState } from '../../../state/revenir.state';

export function handleBarcodeRedirection(
  store: Store,
  ctaButtonSignalService: CtaButtonSignalService,
  widgetProperties: UiZoneWidgetAttributeConfig,
) {
  const key = widgetProperties?.['buttonActionType'] as string;
  store
    .select(RevenirState.getSelectedRevenirTripDetails)
    .pipe(
      filter((data) => !!data),
      tap((data) => {
        const isProcessing = data?.vat_reclaim_status === TripReclaimStatusEnum.PROCESSING;
        ctaButtonSignalService.setSignal({
          [key]: isProcessing,
        });
      }),
    )
    .subscribe();
}
