import { Component, OnInit } from '@angular/core';
import {
  GetAccount,
  GetTotalWealth,
  SetLoadingStatus,
  SetTotalWealth,
  UpdateCurrentAccountId,
} from '@app/finxone-web-frontend/app/lib/actions/account.action';
import { Country } from '@app/finxone-web-frontend/app/lib/dtos/countries.dto';
import { Currency } from '@app/finxone-web-frontend/app/lib/dtos/currencies.dto';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { CountriesCurrenciesService } from '@app/finxone-web-frontend/app/lib/services/countries-currencies-service/countries-currencies.service';
import { AccountState, AccountStateModel } from '@app/finxone-web-frontend/app/lib/state/account.state';
import { currencyToCircleFlag } from '@app/finxone-web-frontend/app/lib/utils/template/handlebar-helpers/currency-to-flag.utils';
import {
  buildHandleBarModel,
  filterIfHandlebarNotRendered,
} from '@app/finxone-web-frontend/app/lib/utils/template/model-builder.utils';
import {
  removeDuplicateCurrencies,
  removeInvalidCurrencies,
} from '@app/finxone-web-frontend/app/lib/utils/utils';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  accountSwitchDropdownTemplate,
  accountSwitchWidgetVisualTemplate,
} from '@finxone-platform/shared/default-templates';
import {
  AccountInfoType,
  BaseCssProperties,
  BaseWidgetProperties,
  NotificationWidgetConfig,
  ProviderClass,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Select, Store } from '@ngxs/store';
import Handlebars from 'handlebars';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { BaseComponent } from '../base/base.component';

export interface DropdownListingInterface {
  currencyCode: string;
  currency: string;
  accountId: string;
  totalBalance: string;
  availableBalance: string;
  accountBalance: string;
  sortCode: string;
  accountCreatedDate: string;
  currencyFullName: string;
  alias: string;
  accountNumber: string | undefined;
  bic: string | undefined;
  ledgerNumber: string | undefined;
  iban: string | undefined;
  providerExtraInfo: unknown;
  achAccountNumber: string;
  achNumber: string;
  abaAccountNumber: string;
  abaNumber: string;
  accountProviderExtraInfo: object;
  indicativeBalance: string;
  ledgerBalance: string;
  ledgerBalanceIncludingPendingExchange: string;
  pendingExchangeNetTotal: number;
  pendingExchangeCount: number;
  pendingTransactionNetTotal: number;
  pendingTransactionCount: number;
  defaultMarker: string;
  countryCode: string;
  currencyName: string | undefined;
}

@Component({
  selector: 'finxone-web-frontend-accounts-context-dropdown-widget',
  templateUrl: './accounts-context-dropdown-widget.component.html',
  styleUrl: './accounts-context-dropdown-widget.component.scss',
})
export class AccountsContextDropdownWidgetComponent extends BaseComponent implements OnInit {
  @AutoDestroy destroy$: Subject<void> = new Subject<void>();
  @Select(AccountState.getAccounts)
  private accountState$!: Observable<AccountStateModel>;
  public textContentStyle: baseCssConfigStyle;
  public currentActiveStyle: baseCssConfigStyle;
  public currentInactiveStyle: baseCssConfigStyle;
  public widgetConfig: BaseWidgetProperties;
  public currentInputStyle: baseCssConfigStyle;
  public accountsInfo: AccountStateModel;
  public accountsData: AccountInfoType[];
  public dropdownListing: DropdownListingInterface[] = [];
  public contextSwitch: boolean = true;
  public textContent: string;
  public activeInputCss: any = {};
  public inactiveInputCss: any = {};
  public inactiveBgColor: string;
  public activeBorderColor: string;
  public dropdownOpened = false;
  public selectedAccount: DropdownListingInterface | null = null;
  currencies: Currency[];
  countries: Country[];
  pageNumber = 1;
  totalPages = 1;
  public widgetVisualTemplate = accountSwitchWidgetVisualTemplate;
  public dropdownTemplate = accountSwitchDropdownTemplate;
  public enableTotalWealth: boolean = false;
  public showFlag = true;
  public renderedContent: string;
  public leftIconStyle: baseCssConfigStyle;
  public rightIconStyle: baseCssConfigStyle;
  public rightIcon: string;
  public leftIcon: string;

  constructor(
    private configService: ConfigService,
    private countriesCurrenciesService: CountriesCurrenciesService,
    public dataIn: ProviderClass<NotificationWidgetConfig>,
    private store: Store,
  ) {
    super();
    if (this.dataIn?.attri) {
      this.textContent = this.dataIn.attri['textContent'];
      this.widgetConfig = this.dataIn.attri.widgetProperties;

      this.contextSwitch = this.dataIn.attri?.['enableAccountContextSwitch'] ?? true;
    }
    this.getWidgetCssConfig();

    this.configService
      .getCurrencies()
      .pipe(
        takeUntil(this.destroy$),
        map((response) => {
          const validCurrencies = removeInvalidCurrencies(response);
          this.currencies = removeDuplicateCurrencies(validCurrencies, 'currency_code');
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAccount(this.pageNumber, this.pageSize));
    this.accountState$
      .pipe(
        map((response) => {
          this.accountsInfo = response;
          this.accountsData = response.accounts;

          const currentAccountDetails = this.store.selectSnapshot(
            AccountState.getCurrentAccountDetails(this.accountsInfo.currentAccountId),
          );

          if (currentAccountDetails) {
            this.selectedAccount = this.getAccountData(currentAccountDetails, 0);
          }

          const listOfAccounts: DropdownListingInterface[] = [];
          response.accounts.forEach((element: AccountInfoType, index: number) => {
            const account = this.getAccountData(element, index);
            listOfAccounts.push(account);
          });

          this.dropdownListing = listOfAccounts;

          this.totalPages = response.accountTotalPages;
        }),
      )
      .subscribe();
    this.configService.getCountries().subscribe({
      next: (data) => {
        this.countries = data;
      },
    });

    this.getWidgetConfig();

    this.buildVisualTemplate();
  }

  private getWidgetConfig() {
    this.enableTotalWealth = this.dataIn.attri?.['enableTotalWealth'] ?? false;
    this.showFlag = this.dataIn.attri?.['showFlag'] ?? true;
    this.dropdownTemplate = this.dataIn.attri?.['widgetDropdownTemplate'] ?? accountSwitchDropdownTemplate;
    this.leftIcon = this.dataIn?.attri?.widgetProperties?.lefticon ?? '';
    this.rightIcon = this.dataIn?.attri?.widgetProperties?.righticon ?? '';

    if (this.enableTotalWealth && this.selectedAccount) {
      this.store.dispatch(new GetTotalWealth(this.selectedAccount?.currencyCode));
    }
    this.store.dispatch(new SetTotalWealth(this.enableTotalWealth));
  }

  onAccountSelected(account: DropdownListingInterface) {
    if (this.contextSwitch) {
      this.selectedAccount = account;
      this.store.dispatch(new UpdateCurrentAccountId(account.accountId));
    }
    if (this.enableTotalWealth && this.selectedAccount) {
      this.store.dispatch(new GetTotalWealth(this.selectedAccount?.currencyCode));
    }
  }

  setInactiveStyle(isOpen: boolean) {
    this.currentInputStyle = isOpen ? this.currentActiveStyle : this.currentInactiveStyle;
  }

  getCountryFlag(currency_code: string | undefined): string {
    if (currency_code) {
      return currencyToCircleFlag(currency_code, '26p6');
    }
    return '';
  }

  onToggleDropdown(isOpen: boolean) {
    this.dropdownOpened = isOpen;
    this.setInactiveStyle(isOpen);
  }

  public get pagingEnabled(): boolean {
    if (this.dataIn.attri['pagingEnabled'] != null) return this.dataIn.attri['pagingEnabled'];
    else return true;
  }

  public get pageSize(): number {
    if (this.dataIn.attri['pageSize'] != null) return this.dataIn.attri['pageSize'];
    else return 10;
  }

  private getWidgetCssConfig() {
    this.contentStyle = this.configService.getSpecificBaseCssStyle(this.dataIn?.attri, [
      BaseCssProperties.MARGIN,
      BaseCssProperties.PADDING,
      BaseCssProperties.TEXTALIGN,
      BaseCssProperties.V_ALIGN,
      BaseCssProperties.BOX_SHADOW,
    ]);

    this.textContentStyle = this.configService.getSpecificBaseCssStyle(this.dataIn?.attri, [
      BaseCssProperties.FONT_SIZE,
      BaseCssProperties.TEXT_FONT_STYLE,
    ]);

    const { baseStyle, activeStyle, inactiveStyle } = this.configService.getInputFieldCssStyles(
      this.dataIn.attri?.widgetProperties,
    );

    this.currentInactiveStyle = {
      ...inactiveStyle,
      ...activeStyle,
      ...this.textContentStyle,
      ...this.contentStyle,
    };

    this.currentActiveStyle = {
      ...activeStyle,
      ...baseStyle,
      ...this.textContentStyle,
      ...this.contentStyle,
    };

    this.currentInputStyle = this.currentInactiveStyle;

    this.leftIconStyle = this.configService.getSpecificBaseCssStyle(this.dataIn?.attri, [
      BaseCssProperties.LEFT_ICON_SIZE,
      BaseCssProperties.LEFT_ICON_COLOR,
    ]);
    this.rightIconStyle = this.configService.getSpecificBaseCssStyle(this.dataIn?.attri, [
      BaseCssProperties.RIGHT_ICON_SIZE,
      BaseCssProperties.RIGHT_ICON_COLOR,
    ]);
  }

  getCountryNameByCountryCode(countryCode: string) {
    const currencyObject = this.countries?.find((v) => v.code === countryCode);
    return currencyObject?.['name'] ?? '';
  }

  getAccountData(accountInfo: AccountInfoType, index: number): DropdownListingInterface {
    return {
      currencyCode: accountInfo.currency,
      currency: accountInfo.currency,
      accountId: accountInfo.accountId,
      totalBalance: accountInfo.indicativeBalance,
      indicativeBalance: accountInfo.indicativeBalance,
      availableBalance: accountInfo?.availableBalance ?? '',
      accountBalance: accountInfo?.balance ?? '',
      sortCode: accountInfo.accountIdentifier[0].sortCode ?? '',
      accountCreatedDate: accountInfo?.accountCreatedDate ?? '',
      currencyFullName:
        this.currencies?.find((currency: Currency) => currency.currency_code === accountInfo?.currency)
          ?.currency_name ?? 'Unknown Currency',
      ledgerBalance: accountInfo?.ledgerBalance ?? '',
      ledgerBalanceIncludingPendingExchange: accountInfo?.ledgerBalanceIncludingPendingExchange ?? '',
      alias: accountInfo?.alias ?? '',
      accountNumber: accountInfo.accountIdentifier[0].accountNumber,
      bic: accountInfo.accountIdentifier[0].bic,
      ledgerNumber: accountInfo.accountIdentifier[0].ledgerNumber,
      iban: accountInfo.accountIdentifier[0].iban,
      providerExtraInfo: accountInfo.accountIdentifier[0].providerExtraInfo,
      achAccountNumber:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accountInfo?.accountIdentifier[0]?.providerExtraInfo as any)?.ach?.accountNumber ?? '',
      achNumber:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accountInfo?.accountIdentifier[0]?.providerExtraInfo as any)?.ach?.routingCodes?.aba ?? '',
      abaAccountNumber:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accountInfo?.accountIdentifier[0]?.providerExtraInfo as any)?.wire?.accountNumber ?? '',
      abaNumber:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accountInfo?.accountIdentifier[0]?.providerExtraInfo as any)?.wire?.routingCodes?.aba ?? '',
      accountProviderExtraInfo: accountInfo?.accountIdentifier[0]?.providerExtraInfo ?? {},
      pendingExchangeNetTotal: accountInfo?.scheduledExchanges?.netTotal ?? '',
      pendingExchangeCount: accountInfo?.scheduledExchanges?.totalBooked ?? '',
      pendingTransactionNetTotal: accountInfo?.scheduledPayments?.netTotal ?? '',
      pendingTransactionCount: accountInfo?.scheduledPayments?.totalBooked ?? '',
      defaultMarker: index === 0 ? '(Default)' : '',
      currencyName: this.getCurrencyDataByKey('currency_name', accountInfo.currency),
      countryCode: accountInfo.country,
    };
  }

  getCurrencyDataByKey(valueKey: string, currency: string) {
    const currencyObject = this.currencies?.find((v) => v.currency_code === currency);
    return currencyObject?.[valueKey as keyof Currency];
  }

  public handleChildScroll() {
    if (this.pageNumber != this.totalPages) {
      this.store.dispatch(new SetLoadingStatus(true));
      this.pageNumber = this.pageNumber + 1;
      this.store.dispatch(new GetAccount(this.pageNumber, this.pageSize));
    }
  }

  private buildVisualTemplate() {
    this.widgetVisualTemplate =
      this.dataIn.attri?.['widgetVisualTemplate'] ?? accountSwitchWidgetVisualTemplate;
    if (this.widgetVisualTemplate) {
      const template = Handlebars.compile(this.widgetVisualTemplate);
      buildHandleBarModel(this.store, this.widgetVisualTemplate, this.countriesCurrenciesService)
        .pipe(takeUntil(this.destroy$))
        .subscribe((model) => {
          this.renderedContent = filterIfHandlebarNotRendered(template(model));
        });
    }
  }
}
