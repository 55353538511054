import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImportUsers, VerificationFlag } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable, Subject, catchError, map } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  private baseUrl = '';

  verificationFlag: Subject<VerificationFlag> = new Subject();
  // import user observable
  importselecteduser: Subject<ImportUsers> = new Subject();

  constructor(private http: HttpClient, private configService: ConfigService, private store: Store) {
    this.configService.getApi('metadata_service').subscribe((api) => {
      this.baseUrl = api;
    });
  }

  public resetPassword(
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
  ): Observable<boolean> {
    return this.http
      .post(
        this.baseUrl + '/users/reset-password',
        {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        { responseType: 'text' },
      )
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error verifying otp: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  public resetPasswordEmailOnly(email: string): Observable<boolean> {
    return this.http
      .post(
        this.baseUrl + '/users/reset-password-email',
        {
          email,
        },
        { responseType: 'text' },
      )
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error with sending email: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  public updatePassword(token: string, newPassword: string, confirmPassword: string): Observable<boolean> {
    return this.http
      .post(
        this.baseUrl + '/users/change-credential',
        {
          token,
          newPassword,
          confirmPassword,
        },
        { responseType: 'text' },
      )
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error changing password: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  public forgotPasswordEmail(): Observable<boolean> {
    return this.http.put(this.baseUrl + '/users/reset-password-email', '').pipe(
      map((v) => {
        return true;
      }),
      catchError<boolean, Observable<boolean>>((_err) => {
        console.error(`Error with sending email: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }
}
