import { Injectable } from '@angular/core';
import {
  AllTransactionType,
  CurrencyExchangeTransactionType,
  ExchangeCurrencyTransactionsResponse,
  PaginatedResponse,
  TransactionType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { ClearTransactionsCache, GetTransactions, SetLoadingStatus } from '../actions/transactions.action';
import { AccountService } from '../services/account-service/account-service.service';
import { TransactionInfoType, TransactionStateType } from '../services/account-service/account.type';
import { ApiActions, UpdateApiIsLoadingAction } from './api-loading.state';

@State<TransactionStateType>({
  name: 'transactions',
  defaults: {
    isLoading: false,
    accounts: {},
  },
})
@Injectable()
export class TransactionState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getTransactions(state: TransactionStateType) {
    return state;
  }

  @Selector()
  static getLoadingStatus(state: TransactionStateType) {
    return state.isLoading;
  }

  @Action(SetLoadingStatus)
  setLoadingStatus(ctx: StateContext<TransactionStateType>, action: SetLoadingStatus) {
    try {
      ctx.patchState({
        ...ctx.getState(),
        isLoading: action.isLoading,
      });
      return true;
    } catch (err) {
      return throwError(() => err);
    }
  }
  @Action(GetTransactions)
  fetchTransactions(ctx: StateContext<TransactionStateType>, action: GetTransactions) {
    try {
      if (action.isLoaderShown) {
        ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetTransactions' }));
        ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getTransactions, true));
      }

      return this.accountService
        .getTransactionSummary(
          action.accountId,
          action.dateFrom,
          action.dateTo,
          action.page,
          action.limit,
          action.transactionType,
          action.currency,
        )
        .pipe(
          tap(
            (
              transactionList: PaginatedResponse<TransactionInfoType | ExchangeCurrencyTransactionsResponse>,
            ) => {
              ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetTransactions' }));
              const transactionType = action.transactionType;

              const state = ctx.getState();
              const accounts = state.accounts ?? {};
              const account = accounts[action.accountId] ?? structuredClone(defaultAccount);

              // Initialize or update the transaction type data
              account[transactionType] = account[transactionType] ?? {
                result: [],
                totalPages: 1,
                limit: 10,
                page: 1,
              };

              const prevData = account[transactionType].result;
              let transResult;

              if (action.page === 1) {
                transResult = transactionList.result;
              } else {
                transResult = prevData.concat(transactionList.result);
              }

              const response = {
                result: transResult,
                totalPages: transactionList.totalPages,
                limit: action.limit,
                page: transactionList.page,
              };

              ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getTransactions, false));
              ctx.patchState({
                accounts: {
                  ...accounts,
                  [action.accountId]: {
                    ...account,
                    [transactionType]: response,
                  },
                },
                isLoading: false,
              });
            },
          ),
          catchError<unknown, Observable<boolean>>((_err) => {
            ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getTransactions, false));
            ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetTransactions' }));
            throw _err;
          }),
        );
    } catch (err) {
      ctx.dispatch(new UpdateApiIsLoadingAction(ApiActions.getTransactions, false));
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetTransactions' }));
      return throwError(() => err);
    }
  }

  @Action(ClearTransactionsCache)
  clearAccountCache(ctx: StateContext<TransactionStateType>) {
    ctx.patchState({
      isLoading: false,
      accounts: {},
    });
  }
}

const defaultAccount: {
  [txType in AllTransactionType]: {
    result: TransactionInfoType[];
    totalPages: number;
    limit: number;
    page: number;
  };
} = {
  [TransactionType.INCOMING]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
  [TransactionType.OUTGOING]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
  [TransactionType.ALL]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
  [CurrencyExchangeTransactionType.CURRENCYINCOMING]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
  [CurrencyExchangeTransactionType.CURRENCYOUTGOING]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
  [CurrencyExchangeTransactionType.CURRENCYALL]: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
};
