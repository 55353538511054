import { Injectable } from '@angular/core';
import { Currency } from '@app/finxone-web-frontend/app/lib/dtos/currencies.dto';
import { Observable, from, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Country } from '../../dtos/countries.dto';

@Injectable({
  providedIn: 'root',
})
export class CountriesCurrenciesService {
  private currencies$: Observable<Currency[]> | undefined;
  private countries$: Observable<Country[]> | undefined;

  constructor() {}

  getCurrencies(): Observable<Currency[]> {
    if (!this.currencies$) {
      this.currencies$ = this.refreshCurrencies().pipe(
        catchError((err) => {
          this.currencies$ = undefined; // Reset cached observable on error
          return throwError(() => err);
        }),
        shareReplay(1), // Cache the result and replay it for subsequent subscribers
      );
    }
    return this.currencies$;
  }

  private refreshCurrencies(): Observable<Currency[]> {
    const apiUrl = './assets/JSON/countries-currencies.json';
    return from(
      fetch(apiUrl).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      }),
    );
  }

  getCountries(): Observable<Country[]> {
    if (!this.countries$) {
      this.countries$ = this.refreshCountries().pipe(
        catchError((err) => {
          this.countries$ = undefined; // Reset cached observable on error
          return throwError(() => err);
        }),
        shareReplay(1), // Cache the result and replay it for subsequent subscribers
      );
    }
    return this.countries$;
  }

  private refreshCountries(): Observable<Country[]> {
    const apiUrl = './assets/JSON/countries.json';
    return from(
      fetch(apiUrl).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      }),
    );
  }
}
