import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardAdapterChildComponent } from '@app/finxone-web-frontend/app/lib/services/card-adapter-service/card-adapter-child-component';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AccountState } from '@app/finxone-web-frontend/app/lib/state/account.state';
import { CardState } from '@app/finxone-web-frontend/app/lib/state/card.state';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  CardTokenPurpose,
  PaymentProviderType,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject, map, take, takeUntil } from 'rxjs';
import {
  GetCVVDetails,
  GetCVVDetailsSuccess,
  GetCardSecureToken,
  GetCardSecureTokenSuccess,
} from '../../../../lib/actions/card-management.action';
import { AccountService } from '../../../../lib/services/account-service/account-service.service';
import { AdapterCardComponent } from '../adapter-card-component/adapter-card.component';

@Component({
  selector: 'finxone-web-frontend-virtual-card',
  templateUrl: './virtual-card.component.html',
  styleUrls: ['./virtual-card.component.scss'],
})
export class VirtualCardComponent extends CardAdapterChildComponent<PaymentProviderType> implements OnInit {
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();

  public borderStyleCardActivationStripe: baseCssConfigStyle;
  public isShowCardDetails: boolean;

  public secureCVVDetails = {
    cvv2: '',
    pan: '',
  };

  public platformName = '';

  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private store: Store,
    private actions: Actions,
    private accountService: AccountService,
    private ngxSmartModalService: NgxSmartModalService,
    private configService: ConfigService,
    @Inject(AdapterCardComponent) parent: AdapterCardComponent,
  ) {
    super();
    this.parent = parent;

    if (this.parent.isCardFlipped) {
      this.resetSecureProperties();
    }

    this.configService
      .getSystemName()
      .pipe(
        takeUntil(this.destroy$),
        map((systemName) => {
          this.platformName = systemName;
        }),
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.borderStyleCardActivationStripe = {
      'border-bottom-left-radius': this.borderStyle['border-bottom-left-radius'],
      'border-bottom-right-radius': this.borderStyle['border-bottom-right-radius'],
    };

    this.borderStyle = {
      ...this.borderStyle,
      'background-color':
        this.virtualCardData.type === 'virtual'
          ? this.parent.attri['virtualCardColor']
          : this.parent.attri['physicalCardColor'],
    };
  }

  private currentAccountId = () => this.store.selectSnapshot(AccountState.getCurrentAccountId);

  private currentCardId = () => this.store.selectSnapshot(CardState.getCurrentCardId);

  private resetSecureProperties() {
    if (!this.isCardFlipped) {
      this.isShowCardDetails = false;
      this.secureCVVDetails = {
        cvv2: '',
        pan: '',
      };
    }
  }

  public goToCardManagement(): void {
    this.router.navigateByUrl('/zones/card-management/management');
  }

  public copyToClipBoard(copyText: string) {
    this.clipboard.copy(copyText);
  }

  public async showCardBackSideDetails() {
    this.isShowCardDetails = !this.isShowCardDetails;
    if (!this.isShowCardDetails) {
      this.secureCVVDetails = {
        cvv2: '',
        pan: '',
      };
      return;
    }
    const keyPairs = await this.accountService.getRSAKeys();

    this.store.dispatch(
      new GetCardSecureToken({
        accountId: this.currentAccountId(),
        cardId: this.currentCardId(),
        publicKey: keyPairs.publicKey,
        tokenPurpose: CardTokenPurpose.READ,
      }),
    );

    this.actions
      .pipe(
        ofActionDispatched(GetCardSecureTokenSuccess),
        take(1),
        map(async (response) => {
          const payload = response.payload;

          const decryptedToken = await this.accountService.decryptAccessToken(
            payload.encryptedToken,
            payload.encryptedSymmetricKey,
            payload.encodedIv,
            keyPairs.keyPair.privateKey,
          );

          this.store.dispatch(
            new GetCVVDetails({
              secureToken: decryptedToken,
              accountId: this.currentAccountId(),
              cardId: this.currentCardId(),
            }),
          );
        }),
      )
      .subscribe();

    this.actions
      .pipe(
        ofActionDispatched(GetCVVDetailsSuccess),
        take(1),
        map((data) => {
          if (this.isShowCardDetails) {
            this.secureCVVDetails = { ...data.response };
          }
        }),
      )
      .subscribe();
  }

  public formatMaskedPan(maskedPan: string): string {
    return this.parent.prefixLast4PanText + maskedPan.slice(maskedPan.length - 4, maskedPan.length);
  }

  public getExpiryDateWithFormat(expiry_date: string) {
    let newExpiryDate = '--/--';
    if (expiry_date && expiry_date.split('-')[0].length == 4) {
      newExpiryDate = expiry_date.split('-')[1] + '/' + expiry_date.split('-')[0].slice(2);
    }
    return newExpiryDate;
  }

  public openAddCardBottomPopup(): void {
    this.ngxSmartModalService.getModal('add-card').open();
  }
}
