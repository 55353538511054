import { Injectable } from '@angular/core';
import { AlertDetails, AlertResponse } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ReplaySubject, first, map } from 'rxjs';
import { UpdateProjectAlertsSubject } from '../actions/project-alerts.action';

export interface ProjectAlertsStateModel {}

@State<ProjectAlertsStateModel>({
  name: 'alerts',
  defaults: {},
})
@Injectable()
export class ProjectAlertsState {
  private static _systemAlertsConfig$ = new ReplaySubject<AlertResponse>(1);
  constructor() {}
  @Selector()
  static getProjectAlerts(state: ProjectAlertsStateModel) {
    return state;
  }
  @Selector()
  static getSystemAlertsResponseData() {
    return this._systemAlertsConfig$.asObservable();
  }

  @Selector()
  static getAlertDataFromSubject() {
    let value: { [errorCode: string]: AlertDetails } = {};
    ProjectAlertsState.getSystemAlertsResponseData()
      .pipe(
        first(),
        map((res) => {
          value = res.value;
        }),
      )
      .subscribe();
    return value;
  }

  @Selector()
  static getSystemAlerts(): { [errorCode: string]: AlertDetails } {
    const value = ProjectAlertsState.getAlertDataFromSubject();
    return value;
  }

  @Action(UpdateProjectAlertsSubject)
  updateProjectAlertsSubject(ctx: StateContext<ProjectAlertsStateModel>, action: UpdateProjectAlertsSubject) {
    ProjectAlertsState._systemAlertsConfig$.next(action.alertData);
  }
}
