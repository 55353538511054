export const titleHeaderTemplate = `<h2>{{header}}</h2>`;
export const titleSubHeaderTemplate = `<h3>{{subHeader}}</h3>`;
export const choicesResposeHeaderTemplate = `<div class="item-header">Choice</div>
<div class="item-header">Responses</div>`;

export const choiceItemsAnswersTemplate = `<div class="choice-answer text-capitalize">{{answer}}</div>
<div class="choice-count">{{responses}}</div>`;

export const choiceItemsSubAnswersTemplate = `
<div class="choice-answer text-capitalize">{{value}}</div>
<div class="choice-count">{{count}}</div>`;
