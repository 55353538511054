import {
  AdHocFeeRequest,
  AddEditFeeRequestPayload,
  ListingTypeEnum,
} from '@finxone-platform/shared/sys-config-types';

export class GetFeeListing {
  static readonly type = '[FeeManagementState] GetFeeListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetClientFeeListing {
  static readonly type = '[FeeManagementState] GetClientFeeListing';
  constructor(
    public clientId: string,
    public page: number = 1,
    public size: number = 100,
    public searchValue?: string,
  ) {}
}

export class GetFeeGroupsListing {
  static readonly type = '[FeeManagementState] GetFeeGroupsListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetFeeGroupByIdFeeListing {
  static readonly type = '[FeeManagementState] GetFeeGroupByIdFeeListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetSelectedFeeGroup {
  static readonly type = '[FeeManagementState] GetSelectedFeeGroup';
  constructor(public id: string) {}
}
export class GetAllFeeGroups {
  static readonly type = '[FeeManagementState] GetAllFeeGroups';
}

export class FetchSelectedClientDetails {
  static readonly type = '[FeeManagementState] FetchSelectedClientDetails';
  constructor(public clientId: string) {}
}

export class SetSelectedFee {
  static readonly type = '[FeeManagementState] SetSelectedFee';
  constructor(public id: string) {}
}

export class SetSelectedClientFee {
  static readonly type = '[FeeManagementState] SetSelectedClientFee';
  constructor(public id: string) {}
}

export class SetSelectedFeeIds {
  static readonly type = '[FeeManagementState] SetSelectedFeeIds';
  constructor(public ids: string[]) {}
}

export class UpdateSelectedFeeIds {
  static readonly type = '[FeeManagementState] SetSelectedFeeIds';
  constructor(public id: string) {}
}

export class ResetSelectedFeeIds {
  static readonly type = '[FeeManagementState] ResetSelectedFeeIds';
  constructor() {}
}

export class SetSelectedFeeHistoryItem {
  static readonly type = '[FeeManagementState] SetSelectedFeeHistoryItem';
  constructor(public id: string, public listingType: ListingTypeEnum) {}
}

export class ResetSelectedFeeHistoryItem {
  static readonly type = '[FeeManagementState] ResetSelectedFeeHistoryItem';
  constructor() {}
}

export class ValidationFeeDetails {
  static readonly type = '[FeeManagementState] ValidationFeeDetails';
  constructor(public res: AddEditFeeRequestPayload | AdHocFeeRequest) {}
}

export class ResetSelectedFeeGroup {
  static readonly type = '[FeeManagementState] ResetSelectedFeeGroup';
}

export class ResetSelectedFee {
  static readonly type = '[FeeManagementState] ResetSelectedFee';
}

export class ResetSelectedClient {
  static readonly type = '[FeeManagementState] ResetSelectedClient';
}

export class GetAllClientListing {
  static readonly type = '[FeeManagementState] GetAllClientListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetFeeGroupByIdClientListing {
  static readonly type = '[FeeManagementState] GetFeeGroupByIdClientListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetFeeChargeHistoryListing {
  static readonly type = '[FeeManagementState] GetFeeChargeHistoryListing';
  constructor(public page: number = 1, public size: number = 100, public searchValue?: string) {}
}

export class GetClientFeeChargeHistoryListing {
  static readonly type = '[FeeManagementState] GetFeeListing';
  constructor(
    public page: number = 1,
    public size: number = 100,
    public clientId: string,
    public searchValue?: string,
  ) {}
}
